import {Button, Card, Col, Popconfirm, Row, Switch, Tag, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {faFlag} from "@fortawesome/free-solid-svg-icons";
import GeneralTaskSequenceWidget from "./GeneralTaskSequenceWidget";
import {GetIconByTaskType} from "../TaskWidgets";
import {ENV_API_HOST} from "../../config";
import {useState} from "react";
import {useGlobalState} from "../../utils";
import "../../App.css";


const subTypeMapForLinkedin = {
    "ADD_TO_FRIEND": "Send Connection Request",
    "SEND_MESSAGE": "Send Message",
    "VIEW_PROFILE": "View Profile",
    "LIKE_POST": "Like Post",
    "COMMENT_POST": "Write Comment to Post",
    "INTERACT_WITH_POST": "Interact with Post",
    "INTERACT_WITH_PERSON": "Interact with Person"
}


const getSequenceTitle = (stepName) => {
    if (stepName == "CALL") {
        return "Phone Call"
    }

    if (stepName == "LINKEDIN") {
        return "LinkedIn"
    }

    if (stepName == "MANUAL_EMAIL") {
        return "Email"
    }

    if (stepName == "MESSENGER") {
        return "Messenger"
    }

    if (stepName == "ACTION") {
        return "Action"
    }

}

const getActionName = (stepName) => {
    if (stepName == "CALL") {
        return "Make phone call"
    }

    if (stepName == "LINKEDIN") {
        return "Linkedin action"
    }

}


function SequenceCardListWidget(props) {
    const {stepData} = props;
    const [token] = useGlobalState('token');
    const [isLoadingTurn, setIsLoadingTurn] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const handleUpdate = () => {
      props.onUpdate();
    }

    const handleChangeTurn = async (value) => {
        setIsLoadingTurn(true);

        const postData = {
            id: stepData["id"],
            turn: value
        }

        const requestUrl = `${ENV_API_HOST}/api/turnSequenceStep`;

        const resqt = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });
        await resqt.json();
        setIsLoadingTurn(false);

        props.onUpdate();
    }


    const handleDelete = async () => {
        setIsLoadingDelete(true);

        const postData = {
            id: stepData["id"]
        }

        const requestUrl = `${ENV_API_HOST}/api/deleteSequenceStep`;

        const resqt = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });
        await resqt.json();
        setIsLoadingDelete(false);

        props.onUpdate();
    }



    let taskRender = null;
    if (stepData["taskNoHTML"] != null) {
        const taskLines = stepData["taskNoHTML"].split('\n');
        taskRender =  <div className="step-sequence-line-clamp">{taskLines.map((line, index) => (
                <span key={index}>{line} <br /></span>
        ))}</div>;
    }


    return (<Card size={"small"} style={{marginBottom: "10px"}}>
        <Row style={{borderBottom: "1px solid #e3e3e5", paddingBottom: "10px", display: 'flex', alignItems: 'center'}}>
            <Col span={14} style={{}}>
                <Typography.Title level={4} style={{margin: "0px", display: "flex", alignItems: "center"}}>
                    <Tag color="processing">{GetIconByTaskType(stepData["step"].toLowerCase())}</Tag>
                    <span>Day {stepData["day"]}:&nbsp;
                        {getSequenceTitle(stepData["step"])}</span>
                </Typography.Title>
            </Col>
            <Col span={10} style={{display: 'flex', justifyContent: "flex-end"}}>
                <Typography.Text
                    type={"secondary"}>{getActionName(stepData["step"])} in {stepData["ruleRunDelay"]} {stepData["ruleRunDelayType"]}</Typography.Text>
                &nbsp;&nbsp;<GeneralTaskSequenceWidget onUpdate={handleUpdate} id={stepData["id"]} type={stepData["step"]} isEdit={true} stepData={stepData} />&nbsp;
                <Popconfirm
                    placement={"left"}
                    title="Delete this step"
                    description="Are you sure to delete this step?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={handleDelete}
                >
                    <Button loading={isLoadingDelete} size={"small"}>Delete</Button>
                </Popconfirm>
            </Col>
        </Row>
        <Row style={{marginTop: "10px", minHeight: "60px", display: "flex", alignItems: "center"}}>
            <Col xs={{ span: 2}} md={{span: 2}} lg={{ span: 1}}>
                <Switch loading={isLoadingTurn} checked={stepData["IsActive"]} onChange={handleChangeTurn} />
            </Col>
            <Col span={1}>
                <Tag color={stepData["taskPriority"] == "MEDIUM" ? null : stepData["taskPriority"] == "HIGH" ? "red": null}><FontAwesomeIcon icon={faFlag}  /></Tag>
            </Col>
            <Col span={18}>
                {stepData["stepSubType"] != null ?  <Typography.Title level={5} style={{margin: "0px", marginBottom: "7px"}}>{subTypeMapForLinkedin[stepData["stepSubType"]]}</Typography.Title> : null }
                {stepData["taskName"] != null ?  <Typography.Title level={5} style={{margin: "0px"}}>{stepData["taskName"]}</Typography.Title> : null }
                {taskRender}
                {/*{stepData["step"] == "LINKEDIN" ? <Button type={"primary"}>Create a personalized welcome</Button>: null}*/}
                {/*{stepData["step"] == "MANUAL_EMAIL" ? <Button type={"primary"}>Generate AI Email</Button>: null}*/}
            </Col>


        </Row>
    </Card>);
}

export default SequenceCardListWidget;

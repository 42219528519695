import React, {Fragment, useEffect, useState} from "react";
import {Button, Col, Row, Card, Typography, Layout, Empty, Popconfirm} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {useGlobalState} from './utils';
import PurposeProductList from "./PurposeProductList";
import checkIsNeedInviteActionsFirst from "./utils/inviteChecker";
import {ENV_API_HOST} from "./config";

const {Title} = Typography;
const {Content} = Layout;

function ProductList(props) {
    const [token] = useGlobalState('token');
    const [productList, setProductList] = useState([]);
    const [isOpenSideProduct, setIsOpenSideProduct] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const nav = useNavigate();

    const handleChangeProduct = (product) => {
        console.log("handleChangeProduct: productId = ", product);
        setIsOpenSideProduct(true);
        setSelectedProduct(product)
    }


    const handleNewProduct = () => {
        nav(`/product/new`)
    }


    const getProductList = async () => {
        const requestUrl = `${ENV_API_HOST}/api/getCompanyProductList`;

        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const productList = await metadataResponse.json();
        console.log("productList = ", productList);
        setProductList(structuredClone(productList));
    };

    const handleConfirmArchive = async (productId) => {
        const requestData = {
            productId: productId
        }
        const requestUrl = `${ENV_API_HOST}/api/archiveProduct`;
        const metadataResponse = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestData)
        });
        await metadataResponse.json();
        console.log("handleConfirmArchive; try to load product list");
        await getProductList();
    }


    useEffect(() => {
        console.log("token = ", token);
        getProductList();
    }, []);

    let isInviteAction = checkIsNeedInviteActionsFirst();
    if (isInviteAction != null) {
        return isInviteAction
    }

    const handleBillingPage = () => {
        nav(`/billing`)
    }


    let renderProductList = [];

    for (let i = 0; i < productList.length; i++) {
        renderProductList.push(<Row style={{marginBottom: "10px"}}>
            <Col span={24}>
                <Card>
                    <Row>
                        <Col span={12}>
                            <Button onClick={() => handleChangeProduct(productList[i])} style={{padding: "0px"}}
                                    type={"link"}><Title style={{margin: "0px"}}
                                                         level={3}>{productList[i]["name"]}</Title></Button>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Button.Group>
                                <Button href={`/product/${productList[i]["id"]}/edit`} type="primary"
                                        ghost>Edit</Button>
                                <Popconfirm
                                    title="Archive the product"
                                    description="Are you sure to archive this product?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() => handleConfirmArchive(productList[i]["id"])}
                                >
                                    <Button type="primary" danger ghost>Archive</Button>
                                </Popconfirm>
                            </Button.Group>

                        </Col>
                    </Row>
                    <Row style={{marginTop: "5px"}}>
                        <Col span={24}>
                            <b>Description:</b> {productList[i]["description"]}
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>)
    }


    // console.log("props.accountData = ", props.accountData);
    //
    // if (props.accountData != null && props.accountData["billing"]["product"]["is_base"]) {
    //     return (<div style={{marginTop: "20px"}}>
    //         <Content style={{padding: 15, background: "white"}}>
    //             <Row>
    //                 <Col span={24} style={{paddingRight: "10px"}}>
    //                     <Empty
    //                         image="http://public.scope.money/em_locked.png"
    //                         imageStyle={{height: 60}}
    //                         description={
    //                             <span>Unlock the full potential of personalized messaging and offer creation with our exclusive features, available only to paid users. <div
    //                                 style={{marginTop: "10px"}}>Experience the benefits of crafting accurate, targeted messages and designing tailor-made offers to suit the unique needs of your prospects, elevating your LinkedIn sales success.</div></span>
    //                         }
    //                     >
    //                         <Button type="primary" onClick={handleBillingPage}>Pricing</Button>
    //                     </Empty>
    //                 </Col>
    //             </Row>
    //         </Content>
    //     </div>)
    // }


    return (
        <div>
            <Row>
                <Col span={12} style={{paddingRight: "10px"}}>
                    <Row style={{display: "flex", alignItems: "center"}}>
                        <Col span={20}>
                            <Title level={3}>Products</Title>
                        </Col>
                        <Col span={4} style={{textAlign: "right"}}>
                            <Button onClick={handleNewProduct}>New product</Button>
                        </Col>
                    </Row>
                    {renderProductList.length == 0 ? <Row>
                        <Col span={24}>
                            <Content style={{padding: 15, background: "white"}}>
                                <div style={{marginTop: "40%", marginBottom: "40%"}}>
                                    <Empty description={<span>No products.</span>}/>
                                </div>
                            </Content>
                        </Col>
                    </Row> : renderProductList}
                </Col>
                <Col span={12}>

                    {!isOpenSideProduct ? <div style={{paddingLeft: "10px"}}>
                        <Row>
                            <Col span={24}>
                                <Title level={3}>Purposes</Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Content style={{padding: 15, background: "white"}}>
                                    <div style={{marginTop: "40%", marginBottom: "40%"}}>
                                        <Empty description={<span>Choose a product to view and edit purposes.</span>}/>
                                    </div>
                                </Content>
                            </Col>
                        </Row>
                    </div> : <PurposeProductList product={selectedProduct}/>}

                </Col>

            </Row>


        </div>
    );
}


export default ProductList;

import React, {useState} from "react";
import {Popconfirm} from "antd";
import {ENV_API_HOST} from "./config";
import {useGlobalState} from "./utils";


function DeleteRule(props) {
    const [token] = useGlobalState('token');
    const [isLoading, setIsLoading] = useState(false);

    const deleteRow = async () => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/deleteCommunicationRule`;

        const resqt = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({id: props.fields["id"]})
        });
        await resqt.json();
        setIsLoading(false);
        props.onUpdate();
    }

    const confirm = (e) => {
        deleteRow();
    };

    return (<span><Popconfirm
        title="Delete the rule"
        description="Are you sure to delete this rule?"
        onConfirm={confirm}
        okText="Yes"
        cancelText="No"
    >
        {!isLoading ? <a key="list-loadmore-more">delete</a> : <span>Saving...</span>}
    </Popconfirm>
    </span>);
}

export default DeleteRule;

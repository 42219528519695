import React, {Fragment, useEffect, useState} from "react";
import {
    Button,
    Col, Input,
    Layout, Row, Radio,
    List, Alert
} from 'antd';
import NewPurposeItem from "./NewPurposeItem";
import {useGlobalState} from "./utils";
import {ENV_API_HOST} from "./config";
import {
    Link, useNavigate,
    useParams
} from "react-router-dom";

const {Content} = Layout;


function CustomNewPurpose() {
    const [token] = useGlobalState('token');
    const isDecisionMakerRegex = /c[a-z]o|chief\s[a-zA-Z]{0,20}\sofficer|.*director.*|.*founder.*|.*board.*|.*advisor.*|.*vp.*|.*investor.*|.*partner.*|.*president.*/g;
    const [data, setData] = useState([]);
    const [isDecisionMaker, setIsDecisionMaker] = useState(-1);
    const [positionTitle, setPositionTitle] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [generateMsgErr, setGenerateMessageErr] = useState(null);

    const nav = useNavigate();

    let { productId } = useParams();

    const generatePurposeList = async () => {
        setIsLoading(true);
        setGenerateMessageErr(null);

        const postData = {
            productId: parseInt(productId),
            positionName: positionTitle,
            isDecisionMaker: (isDecisionMaker ? true : false)
        }

        const userDetailsByIdUrl = `${ENV_API_HOST}/api/generatePurpose`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });

        if (metadataResponse.ok) {
            const response = await metadataResponse.json();
            console.log("CustomNewPurpose.generatePurposeList: response: ", response);
            setData(response);
        } else {
            const responseData = await metadataResponse.json();
            setGenerateMessageErr(responseData["err"]);
        }
        setIsLoading(false);

    }

    const onChangeDecision = (e) => {
        setIsDecisionMaker(e.target.value);
    }

    const updatePositionTitle = (e) => {
        let title = e.target.value;
        let isMatch = title.toLowerCase().match(isDecisionMakerRegex);
        let matchAnswer = isMatch ? 1 : 0;
        console.log("CustomNewPurpose:updatePositionTitle = ", matchAnswer, title, title.toLowerCase());
        setIsDecisionMaker(matchAnswer);
        setPositionTitle(title);

        if(title.length >= 2) {
            setIsDisabled(false);
        }
    }

    const handleBilling = () => {
        nav(`/billing`)
    }

    return (
        <div>

            <Row style={{marginTop: "20px"}}>
                <Col span={24}>
                    <div style={{marginBottom: "10px"}}>
                        <h3>Or generate via AI:</h3>
                    </div>
                    <Row>
                        <Col span={24} style={{marginBottom: "5px"}}>
                            Name of position with whom you are planning to contact:
                        </Col>
                    </Row>
                    <Row style={{marginTop: "3px"}}>
                        <Col span={24}>
                            <Input placeholder={"Example: CEO"} onChange={updatePositionTitle} value={positionTitle} />
                        </Col>
                    </Row>

                    <Row style={{marginTop: "20px"}}>
                        <Col span={24}>
                            Is this position a decision maker for your product?
                        </Col>
                    </Row>

                    <Row style={{marginTop: "5px"}}>
                        <Col span={24}>
                            <Radio.Group value={isDecisionMaker} onChange={onChangeDecision}>
                                <Radio.Button value={1}>Yes</Radio.Button>
                                <Radio.Button value={0}>No</Radio.Button>
                                <Radio.Button value={-1}>I don't know</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>


                    {generateMsgErr != null && generateMsgErr == "TOKEN_NOT_ENOUGH" ? <Row style={{marginTop: "10px", marginBottom: "10px"}}>
                        <Col span={24}>
                            <Alert message={<span>No tokens are available. Please <Link to={"/billing"}>add tokens</Link> on the billing page.</span>} type="error" showIcon />
                        </Col>
                    </Row> : generateMsgErr != null ? <Row style={{marginBottom: "10px"}}>
                        <Col span={24}>
                            <Alert message={generateMsgErr} type="error" showIcon />
                        </Col>
                    </Row>: null}

                    <div style={{marginTop: "15px"}}>
                        <Button onClick={generatePurposeList} loading={isLoading} disabled={isDisabled}>Generate purposes list</Button>
                    </div>
                </Col>
            </Row>



            {data.length > 0 ? <div>
                <Row>
                    <Col span={24}>
                        <h4>List of suggested purposes:</h4>
                    </Col>
                </Row>
                <Row style={{marginTop: "10px"}}>
                    <Col span={24}>
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={(item) => (
                                <NewPurposeItem text={item} productId={parseInt(productId)} />
                            )}
                        />
                    </Col>
                </Row>
            </div> : null }



        </div>
    );
}

export default CustomNewPurpose;

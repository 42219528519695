import {Button, Card, Col, Popconfirm, Row, Switch, Typography} from "antd";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {ENV_API_HOST} from "../../config";
import {useGlobalState} from "../../utils";
import {useState} from "react";

const middleAlignStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
}

function SequenceAssigmentItemWidget(props) {
    const {data, profileId} = props;
    const [token] = useGlobalState('token');

    const [isLoading, setIsLoading] = useState(false);


    const turnAssigment = async () => {
        setIsLoading(true);
        const requestData = {
            "assigmentId": data["id"],
            "state": !data["isActive"],
        }

        const requestUrl = `${ENV_API_HOST}/api/turnSequenceAssigment`;
        const metadataResponse = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestData)
        });
        await metadataResponse.json();
        props.onUpdate();
        setIsLoading(false);
    }


    console.log(" assigmentList[i] = ", data);
    const executionList = data["sequenceStepExecutionList"];
    let executionMap = {
        "WAITING": 0,
        "IN_PROGRESS": 0,
        "DONE": 0,
        "CANCELLED": 0,
        "REJECTED": 0
    };

    for (let j = 0; j < executionList.length; j++) {
        if (executionList[j]["state"] == "WAITING" || executionList[j]["state"] == "PROCESSING" ) {
            executionMap['WAITING'] = executionMap['WAITING']+1;
        } else if (executionList[j]["state"] == "IN_PROGRESS") {
            executionMap['IN_PROGRESS'] = executionMap['IN_PROGRESS']+1;
        } else if (executionList[j]["state"] == "DONE") {
            executionMap['DONE'] = executionMap['DONE']+1;
        }  else if (executionList[j]["state"] == "REJECTED") {
            executionMap['REJECTED'] = executionMap['REJECTED']+1;
        } else if (executionList[j]["state"] == "CANCELLED" || executionList[j]["state"] == "CANCELLED_BY_OFF_PARENT") {
            executionMap['CANCELLED'] = executionMap['CANCELLED']+1;
        }
    }

    return(<Card size={"small"} style={{marginBottom: "10px"}}>
        <Row style={{padding: "0px 5px"}}>
            <Col span={22}>
                <div>
                    <Typography.Title style={{marginTop: "0px", marginBottom: "3px"}} level={5}> <Link to={`/sequence/`}>{data["sequence"]["name"]}</Link></Typography.Title>
                </div>
                <div>
                    {/*<span><Avatar size={16} src={assigmentList[i]["sequence"][i]["createdBy"]["photo"]} /> {sequenceList[i]["createdBy"]["name"]}</span>*/}
                    {/*<span className={"sequenceStepAmount"}>{sequenceList[i]["stepsCount"]} steps</span>*/}
                </div>
            </Col>
            <Col span={2} style={middleAlignStyle}>
                <Popconfirm
                    title="Changing Assignment Sequence State"
                    description="Are you sure you want to change the assignment state?"
                    placement="left"
                    onConfirm={turnAssigment}
                    // onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                >
                    <Switch checked={data["isActive"]} loading={isLoading} />
                </Popconfirm>
            </Col>
            {/*<Col span={1} style={middleAlignStyle}>*/}
            {/*    <Button type="link" danger><FontAwesomeIcon icon={faTrash} /></Button>*/}
            {/*</Col>*/}
        </Row>
        <Row>
            <Col span={1} style={{textAlign: "center"}}>
                <Typography.Title style={{margin: "0px"}} level={2}>
                    {executionList.length}
                </Typography.Title>
                <div>
                    <Typography.Text  style={{fontSize: "bold"}} type={"secondary"}>steps</Typography.Text>
                </div>
            </Col>
            <Col span={2} style={{textAlign: "center"}} offset={1}>
                <Typography.Title style={{margin: "0px"}} level={2}>
                    {executionMap["WAITING"]}
                </Typography.Title>
                <div>
                    <Typography.Text  style={{fontSize: "bold"}} type={"secondary"}>scheduled</Typography.Text>
                </div>
            </Col>

            <Col span={2} style={{textAlign: "center"}} offset={1}>
                <Typography.Title style={{margin: "0px"}} level={2}>
                    {executionMap["IN_PROGRESS"]}
                </Typography.Title>
                <div>
                    <Typography.Text  style={{fontSize: "bold"}} type={"secondary"}>in progress</Typography.Text>
                </div>
            </Col>
            <Col span={1} style={{textAlign: "center"}} offset={1}>
                <Typography.Title style={{margin: "0px"}} level={2}>
                    {executionMap["DONE"]}
                </Typography.Title>
                <div>
                    <Typography.Text  style={{fontSize: "bold"}} type={"secondary"}>done</Typography.Text>
                </div>
            </Col>
            <Col span={2} style={{textAlign: "center"}} offset={1}>
                <Typography.Title style={{margin: "0px"}} level={2}>
                    {executionMap["CANCELLED"]}
                </Typography.Title>
                <div>
                    <Typography.Text  style={{fontSize: "bold"}} type={"secondary"}>cancelled</Typography.Text>
                </div>
            </Col>

            <Col span={3} style={{textAlign: "right", paddingTop: "20px"}} offset={8}>
                {/*<Button><FontAwesomeIcon icon={faChevronDown} />&nbsp; Show Steps</Button>*/}
            </Col>
        </Row>
    </Card>)
}

export default SequenceAssigmentItemWidget;

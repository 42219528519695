import {Alert, Avatar, Button, Modal, Tag} from "antd";
import {useState} from "react";
import ProfileFindResultWidget from "./matching/ProfileFindResultWidget";
import {ENV_API_HOST} from "../config";
import {useGlobalState} from "../utils";


function buildRedirectUrl(userId) {
    // Get current url and host
    var url = window.location.href;
    var host = window.location.host;

    // Extract protocol
    var protocol = url.split('/')[0];

    // Create own redirect url
    var redirectUrl = protocol + "//" + host + "/user/"+userId+"/activity";

    // Redirect to new url
    return redirectUrl
}


function MergeProfileWidget(props) {
    const [token] = useGlobalState('token');
    const {profileData} = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProfileId, setSelectedProfileId] = useState(null);
    const [isEq, setIsEq] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [updateTicket, setUpdateTicket] = useState(null);

    const showModal = () => {
        setIsModalOpen(true);
        setUpdateTicket(Math.random())
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const selectNewProfile = (profileId) => {
        setSelectedProfileId(profileId);

        if (profileId == profileData["id"]) {
            setIsEq(true);
        } else {
            setIsEq(false);
        }

    }

    const handleSubmit = async () => {
        setIsSaving(true);

        const postData = {
            originId: profileData["id"],
            mergeId: selectedProfileId
        }

        const requestUrl = `${ENV_API_HOST}/api/postMergeProfile`;
        const metadataResponse = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });

        const responseData = await metadataResponse.json();

        if (responseData["merge_status"] == "ORIGIN") {
            props.onUpdate();
            handleCancel();
        } else if (responseData["merge_status"] == "MERGED") {
            window.location.replace(buildRedirectUrl(responseData["merge_profile_id"]));
        }
        setIsSaving(false);
    }

    return(<span>
        <Modal title="Merge Profiles" open={isModalOpen} onCancel={handleCancel} footer={[
            <Button key="back" onClick={handleCancel}>
                Return
            </Button>,
            <Button key="submit" type="primary" loading={isSaving} disabled={isEq} onClick={handleSubmit}>
                Submit
            </Button>
        ]}>
            <p>Select the profile you want to merge with <Tag><Avatar size={16} src={profileData["person"]["photo"]} /> {profileData["person"]["name"]}</Tag>: </p>
            <div>
                <ProfileFindResultWidget onUpdate={selectNewProfile} updateTicket={updateTicket} />
            </div>
        </Modal>

        <Button onClick={showModal}>Merge Profiles</Button>
    </span>)
}

export default MergeProfileWidget;

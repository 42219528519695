// const ENV_API_HOST = "http://localhost:3010"
const ENV_API_HOST = "https://api.scope.money"
const ENV_AUTH_DOMAIN = "scopemoneyapp.eu.auth0.com"
const ENV_AUTH_CLIENT_ID = "2fgkcf1RpNeKcYic4YDns7VbAhT0gGdl"
const ENV_AUTH_AUDIENCE = "app-private-api"

const RULE_LIST = [
    {value: 'DONT_USE_STRICT', label: "Avoid using phrases like this or similar ones", color: 'red'},
    {value: 'DONT_USE', label: "Avoid using phrases such as", color: 'red'},
    {value: 'ME_FACT_FALSE', label: "This fact about me is false", color: 'magenta'},
    {value: 'ME_FACT_LIKE', label: "I enjoy when you share facts about me like this one or similar ones", color: 'green'},
    {value: 'USE_MORE', label: "Use more phrases similar to or equivalent to this one.", color: 'green'},
]

const temp = [
    {value: 'NOT_MENTION'},

]

export {
    ENV_API_HOST,
    ENV_AUTH_DOMAIN,
    ENV_AUTH_CLIENT_ID,
    ENV_AUTH_AUDIENCE,
    RULE_LIST
}

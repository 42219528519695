import React, {Fragment, useEffect, useState} from "react";
import {
    Button,
    Col, Input,
    Layout, Row, Radio,
    List, Alert
} from 'antd';
import NewPurposeItem from "./NewPurposeItem";
import CustomNewPurpose from "./CustomNewPurpose";
import {ENV_API_HOST} from "./config";
import {useGlobalState} from "./utils";
import {
    useParams
} from "react-router-dom";


const {Content} = Layout;


function NewPurpose() {
    const [token] = useGlobalState('token');
    let { productId } = useParams();

    const [purposeText, setPurposeText] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess , setIsSuccess] = useState(false);

    const handlePurposeChange = (e) => {
      setPurposeText(e.target.value);
    }


    const handlePurposeSave = async () => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/postNewPurpose`;

        const postData = {
            productId: parseInt(productId),
            purpose: purposeText
        };

        const resqt = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });

        await resqt.json();

        setIsLoading(false);
        setIsSuccess(true);
    }

    const handleCloseSuccess = () => {
      setIsSuccess(false);
    }


    return (
        <div>
            <Content style={{padding: '24px 24px', background: "white"}}>

                <Row>
                    <Col span={24}>
                        <h3>Create a new purpose from text:</h3>
                    </Col>
                </Row>

                {isSuccess ? <Row style={{marginBottom: "10px"}}>
                    <Col span={24}>
                        <Alert afterClose={handleCloseSuccess} message="You successfully create the new purpose." type="success" showIcon closable />
                    </Col>
                </Row>: null}


                <Row style={{marginBottom: "5px"}}>
                    <Col span={24}>
                        <span>New purpose:</span>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Input value={purposeText} onChange={handlePurposeChange} />
                    </Col>
                </Row>

                <Row style={{marginTop: "7px", textAlign: "right"}}>
                    <Col span={24}>
                        <Button type={"primary"} onClick={handlePurposeSave} loading={isLoading}>Create</Button>
                    </Col>
                </Row>

                <CustomNewPurpose />

            </Content>

        </div>
    );
}

export default NewPurpose;

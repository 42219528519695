import React, {Fragment, useEffect, useState} from "react";
import {Input, List, Spin} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
import {useGlobalState} from "./utils";
import {ENV_API_HOST} from "./config";

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);

function NewPurposeItem(props) {
    const [token] = useGlobalState('token');
    const [itemText, setItemText] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    useEffect(() => {
        setItemText(props.text);
    }, [props?.text]);

    const changeEditing = () => {
        if (isEditing) {
            setItemText(props.text);
        }
        setIsEditing(!isEditing);
    }

    const onSave = async () => {
        setIsEditing(false);
        setIsSaving(true);

        const requestUrl = `${ENV_API_HOST}/api/postNewPurpose`;

        const postData = {
            productId: props.productId,
            purpose: itemText
        };

        await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });

        setIsSaving(false);
        setIsSaved(true);

    }

    const changeValue = (e) => {
        setItemText(e.target.value)
    }

    let saveButton = <a key="list-loadmore-more" onClick={onSave}>save</a>;

    let actionsDom = [];
    if (!isEditing) {
        actionsDom = [<a key="list-loadmore-edit" onClick={changeEditing}>edit</a>, saveButton];
    } else {
        actionsDom = [<a key="list-loadmore-edit" onClick={changeEditing}>undo</a>, saveButton];
    }

    if (isSaving) {
        actionsDom = [<span><Spin indicator={antIcon}/> Saving ...</span>];
    }

    if (isSaved) {
        actionsDom = [<span>Saved </span>];
    }

    return (<List.Item actions={actionsDom}>
        {!isEditing ? <span>{itemText}</span> : <Input onChange={changeValue} value={itemText}/>}
    </List.Item>)
}

export default NewPurposeItem;

import React, {Fragment, useEffect, useState} from "react";
import {Button, Form, Input, Modal, Select} from "antd";
import {ENV_API_HOST, RULE_LIST} from "./config";
import {useGlobalState} from "./utils";

function NewRule(props) {
    const [form] = Form.useForm();
    const [token] = useGlobalState("token");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const postNew = async (values) => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/postCommunicationRule`;

        const resqt = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values)
        });
        await resqt.json();
        form.resetFields();
        setIsLoading(false);
        handleCancel();
        props.onUpdate();
    }


    const handleOk = async () => {
        // form.submit();

        form.validateFields()
            .then((values) => {
                console.log("value = ", values);
                postNew(values);
            })
            .catch((errorInfo) => {
                console.log("errorInfo = ", errorInfo);
            });

        // setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (<div>
        <Button type={"primary"} size={"large"} onClick={showModal}>New communication rule</Button>

        <Modal
            title="Create a new communication rule"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Return
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
                    Submit
                </Button>
            ]}
        >
            <Form
                form={form}
                name="basic"
                layout={"vertical"}
                labelCol={{span: 8}}
                wrapperCol={{span: 24}}
                style={{width: "100%"}}
                initialValues={{remember: true}}
                autoComplete="off"
            >
                <Form.Item
                    label="Rule:"
                    name="rule"
                    rules={[{required: true, message: 'Please choose rule'}]}
                >
                    <Select
                        style={{width: "100%"}}
                        options={RULE_LIST}
                    />
                </Form.Item>

                <Form.Item
                    label="Phrase:"
                    name="phrase"
                    rules={[{required: true, message: 'Please write phase that apply rule'}]}
                >
                    <Input/>
                </Form.Item>

            </Form>
        </Modal>
    </div>)
}

export default NewRule;

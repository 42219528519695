import {ENV_API_HOST} from "../config";
import {useGlobalState} from "../utils";
import {useEffect, useState} from "react";
import {Spin} from "antd";
import EmailCardForListWidget from "./EmailCardForListWidget";

function EmailCardForActivityLog(props) {
    const {emailId} = props;
    const [token] = useGlobalState('token');
    const [isLoading, setIsLoading] = useState(true);
    const [emailData, setEmailData] = useState(null);

    const getEmailData = async () => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getEmailById?id=${emailId}`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const locEmailData = await response.json();
        setEmailData(locEmailData);
        setIsLoading(false);
    }

    useEffect(() => {
        getEmailData();
    }, [emailId]);

    if (isLoading) {
        return (<div style={{textAlign: "center", paddingTop: "50px", paddingBottom: "50px"}}>
            <Spin tip="Loading ..." size="large">
                <div className="content"/>
            </Spin>
        </div>)
    }

    return(<span>
        <EmailCardForListWidget emailData={emailData} />
    </span>)

}

export default EmailCardForActivityLog;

import React, {Fragment, useEffect, useState} from "react";
import {useGlobalState} from "./utils";
import {Layout, Typography, Row, Col, List, Skeleton, Avatar, Button, Tag, Modal, Select, Input, Empty} from "antd";
import NewRule from "./NewRule";
import {ENV_API_HOST} from "./config";
import EditRule from "./EditRule";
import TurnRule from "./TurnRule";
import DeleteRule from "./DeleteRule";
import {RULE_LIST} from "./config";
import {useNavigate} from "react-router-dom";

const {Content} = Layout;
const { Text, Link } = Typography;

function RuleList(props) {
    const [token] = useGlobalState('token');

    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [list, setList] = useState([]);

    const nav = useNavigate();

    const getRuleList = async () => {
        setLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getCommunicationRule`;
        const resqt = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const responseData = await resqt.json();
        console.log("getRuleList = ", responseData);
        setList(responseData);
        setLoading(false);
    }


    const handleNewRow = () => {
        getRuleList();
    }


    useEffect(() => {
        getRuleList();
        // fetch(fakeDataUrl)
        //     .then((res) => res.json())
        //     .then((res) => {
        //         setInitLoading(false);
        //         setData(res.results);
        //         setList(res.results);
        //     });
    }, []);
    // const onLoadMore = () => {
    //     setLoading(true);
    //     setList(
    //         data.concat(
    //             [...new Array(count)].map(() => ({
    //                 loading: true,
    //                 name: {},
    //                 picture: {},
    //             })),
    //         ),
    //     );
    //     fetch(fakeDataUrl)
    //         .then((res) => res.json())
    //         .then((res) => {
    //             const newData = data.concat(res.results);
    //             setData(newData);
    //             setList(newData);
    //             setLoading(false);
    //             // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
    //             // In real scene, you can using public method of react-virtualized:
    //             // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
    //             window.dispatchEvent(new Event('resize'));
    //         });
    // };
    // const loadMore =
    //     !initLoading && !loading ? (
    //         <div
    //             style={{
    //                 textAlign: 'center',
    //                 marginTop: 12,
    //                 height: 32,
    //                 lineHeight: '32px',
    //             }}
    //         >
    //             <Button onClick={onLoadMore}>loading more</Button>
    //         </div>
    //     ) : null;
    //

    function renderRuleType(ruleType, isActive) {
        for (let i = 0; i < RULE_LIST.length; i++) {
            if(RULE_LIST[i].value == ruleType) {
                return <Tag color={isActive ? RULE_LIST[i].color : null}>{RULE_LIST[i].label}</Tag>
            }
        }
        return null;
    }

    // const handleBillingPage = () => {
    //     nav(`/billing`)
    // }
    //
    // if (props.accountData != null && props.accountData["billing"]["product"]["is_base"]) {
    //     return (<div style={{marginTop: "20px"}}>
    //         <Content style={{padding: 15, background: "white"}}>
    //             <Row>
    //                 <Col span={24} style={{paddingRight: "10px"}}>
    //                     <Empty
    //                         image="http://public.scope.money/em_locked.png"
    //                         imageStyle={{height: 60}}
    //                         description={
    //                             <span>Experience the benefits of teaching AI algorithms based on your preferences, allowing the creation of unique messages in your style.
    //                                 <div style={{marginTop: "10px"}}>Craft accurate, targeted messages and design tailor-made offers that cater to the specific needs of your prospects, enhancing your LinkedIn sales success.</div></span>
    //                         }
    //                     >
    //                         <Button type="primary" onClick={handleBillingPage}>Pricing</Button>
    //                     </Empty>
    //                 </Col>
    //             </Row>
    //         </Content>
    //     </div>)
    // }


    return (<div>
        <Content style={{padding: '24px 24px', background: "white"}}>
            <Row>
                <Col span={14}>
                    <Typography.Title level={3} style={{margin: 0}}>Communication Rules</Typography.Title>
                </Col>
                <Col span={10} style={{textAlign: "right"}}>
                    <NewRule onUpdate={handleNewRow}/>

                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <List
                        className="demo-loadmore-list"
                        loading={loading}
                        itemLayout="horizontal"
                        // loadMore={loadMore}
                        dataSource={list}
                        renderItem={(item) => (
                            <List.Item
                                actions={[<EditRule onUpdate={handleNewRow} fields={item} />,
                                    <TurnRule onUpdate={handleNewRow} fields={item} />,
                                    <DeleteRule onUpdate={handleNewRow} fields={item} />]}
                            >
                                <Skeleton title={false} loading={item.loading} active>
                                    <List.Item.Meta
                                        title={<span>{renderRuleType(item.rule, item.isActive)}<Text disabled={!item.isActive}>{item.phrase}</Text></span>}
                                        description={<span>{item.isActive ? <span>Enabled for all purposes</span> : <span>Disabled for all purposes</span>}</span>}
                                    />
                                    {/*<div>content</div>*/}
                                </Skeleton>
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>


        </Content>
    </div>)
}

export default RuleList;

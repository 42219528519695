import React, {Fragment, useEffect, useState} from "react";
import {Button, Col, Input, Layout, Popconfirm, Row} from "antd";
import TeamList from "./TeamList";
import {useGlobalState} from "./utils";
import {ENV_API_HOST} from "./config";
import RemoveTeamMember from "./RemoveTeamMember";

const {Header, Content, Sider} = Layout;



function TeamListTableUtils(props) {
    const [token] = useGlobalState('token');
    const [isResendInvite, setIsResendInvite] = useState(false);
    const [resendButtonText, setResendButtonText] = useState("Resend Invite")

    const resendInvite = async () => {
        setIsResendInvite(true);
        const requestUrl = `${ENV_API_HOST}/api/resendInviteForTeamMember`;

        const postData = {
            "userId": props.record.id
        }
        const metadataResponse = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });
        const teamDataJson = await metadataResponse.json();
        console.log("teamDataJson = ", teamDataJson);
        setResendButtonText("Resent");
        setIsResendInvite(false);

    }


    if (props.accountData["id"] == props.record["id"]) {
        return (<div></div>);
    }

    return (
        <span>
            {props.record.role == "INVITING" ? <span><Button onClick={resendInvite} disabled={resendButtonText == "Resent"} loading={isResendInvite}>{resendButtonText}</Button>&nbsp;</span>: null}
             <RemoveTeamMember record={props.record} onUpdate={props.onUpdate} /></span>
    );
}

export default TeamListTableUtils;

import {Button, Form, Input, Modal} from "antd";
import {useState} from "react";
import {ENV_API_HOST} from "../config";
import {useGlobalState} from "../utils";

function NewSequence(props) {
    const [token] = useGlobalState('token');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const createNewSequence = async (values) => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/postNewSequence`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values)
        });
        const dataResponse = await response.json();
        props.onUpdate();
        setIsLoading(false);
    }

    const handleOk = () => {
        setIsModalOpen(false);
        form.validateFields().then((values) => {
            createNewSequence(values);
            form.resetFields();
            handleCancel();
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return(<div>
        <Modal
            title="Create a sequence"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Return
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
                    Submit
                </Button>
            ]}
        >
            <Form
                layout={"vertical"}
                form={form}
            >
                <Form.Item
                    label="Sequence name:"
                    name="name"
                    rules={[{required: true, message: 'Please fill sequence name'}]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
        <Button type={"primary"} onClick={showModal}>New sequence</Button>
    </div>)
}

export default NewSequence;

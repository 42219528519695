import {Avatar, Tooltip} from "antd";
import {Link} from "react-router-dom";


function CompanyListWidget(props) {

    let apiCompanyList = props.person["company_list"]
    let renderCompanyList = [];

    for (let i = apiCompanyList.length - 1; i >= 0; i--) {
        renderCompanyList.push(<span style={{paddingRight: "3px"}}><Tooltip title={apiCompanyList[i]["name"]}><Link to={`/company/${apiCompanyList[i]["id"]}`}><Avatar shape="square" size={32} src={apiCompanyList[i]["img"]} /></Link></Tooltip></span>)
    }


    return (
        <div>
            {renderCompanyList}
        </div>);
}

export default CompanyListWidget;

import {Avatar, Button, Modal, Select, Tag, Divider, Input, Space, Spin} from "antd";
import React, {Fragment, useEffect, useState, useRef} from "react";
import {useGlobalState} from "./utils";
import {ENV_API_HOST} from "./config";


let index = 0;

function ContactListTagManagement(props) {
    const [token] = useGlobalState('token');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [items, setItems] = useState([]);
    const [localMapData, setLocalMapData] = useState({});
    const [name, setName] = useState('');
    const [isListLoading, setIsListLoading] = useState(false);
    const [isLoadingNewItem, setIsLoadingNewItem] = useState(false);
    const [selectedTagIdItems, setSelectedTagIdItems] = useState([]);
    const [appliedLabelIdList, setAppliedLabelIdList] = useState([]);
    const [isLoadingSaveLabelList, setLoadingSaveLabelList] = useState(false);


    useEffect(() => {
        // console.log("[ContactListTagManagement.js] useEffect", props.person, props.person.label_id_list);
        setSelectedTagIdItems(props.person.label_id_list);
        setAppliedLabelIdList(props.person.label_id_list);
        setLocalMapData(props.mapData);
    }, [props?.person?.label_id_list])


    const showModal = () => {
        setIsModalOpen(true);
        getLabelList();
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onNameChange = (event) => {
        console.log("onNameChange = ", event.target.value);
        setName(event.target.value);
    };
    const addItem = (e) => {
        console.log("addItem = ", e);
        e.preventDefault();
        setItems([...items, name || `New item ${index++}`]);
        setName('');
    };

    const onChange = (newValue) => {
        console.log("onChange:newValue = ", newValue);
        setSelectedTagIdItems(newValue);
    }

    const getLabelList = async () => {
        setIsListLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getCompanyLabelList`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        console.log("getLabelList = ", dataResponse);

        let labelList = [];
        let localMapData = {};
        for (let i = 0; i < dataResponse.length; i++) {
            labelList.push({
                "label": dataResponse[i].name,
                "value": dataResponse[i].id
            })
            localMapData[dataResponse[i].id] = dataResponse[i]
        }
        setItems(labelList);
        setLocalMapData(localMapData);
        setIsListLoading(false);
    }

    const postNewLabel = async () => {
        setIsLoadingNewItem(true);
        const postData = {
            "labelName": name
        };

        const requestUrl = `${ENV_API_HOST}/api/postNewLabel`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });

        await getLabelList();
        setName(null);
        setIsLoadingNewItem(false);
    }

    const handleSaveChanges = async () => {
        setLoadingSaveLabelList(true);
        const postData = {
            "person_id": props.person.id,
            "label_id_list": selectedTagIdItems
        }
        const requestUrl = `${ENV_API_HOST}/api/postPersonLabelList`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });

        setAppliedLabelIdList(selectedTagIdItems);
        setIsModalOpen(false);
        setLoadingSaveLabelList(false);
    }


    let renderLabelList = [];
    let userLabelIdList = appliedLabelIdList;
    for (let i = 0; i < userLabelIdList.length; i++) {
        console.log("userLabelIdList i ", i, userLabelIdList, props, localMapData);
        if (localMapData[userLabelIdList[i]] != undefined) {
            renderLabelList.push(<Tag>{localMapData[userLabelIdList[i]]["name"]}</Tag>);
        } else {
            console.error("label bug: undefined id",userLabelIdList[i] );
        }
    }

    return (<span><Button type={"link"} onClick={showModal}>
        {renderLabelList.length > 0 ? renderLabelList : <span>Add tags</span>}
        </Button>
        <Modal onCancel={handleCancel} title={<span>Edit labels for <Avatar src={props.person.photo}/> {props.person.name}</span>}
               open={isModalOpen}
               footer={[
                   <Button key="back" onClick={handleCancel}>
                       Return
                   </Button>,
                   <Button key="submit" type="primary" onClick={handleSaveChanges}>
                       Save changes
                   </Button>]
               }>

            <Select
                mode="multiple"
                allowClear
                style={{width: '100%'}}
                placeholder="Tags Mode"
                onChange={onChange}
                // fetchOptions={getLabelList}
                notFoundContent={isListLoading ? <Spin size="small"/> : <span>not found</span>}
                options={items}
                defaultValue={selectedTagIdItems}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <Divider
                            style={{
                                margin: '8px 0',
                            }}
                        />
                        <Space
                            style={{
                                padding: '0 8px 4px',
                            }}
                        >
                            <Input
                                placeholder="Please enter item"
                                value={name}
                                onChange={onNameChange}
                            />
                            <Button type="text" onClick={postNewLabel} loading={isLoadingNewItem}>
                                Add item
                            </Button>
                        </Space>
                    </>
                )}
            />
      </Modal>
    </span>);


}

export default ContactListTagManagement;

import {Checkbox} from "antd";
import {useEffect, useState} from "react";
import {ENV_API_HOST} from "../config";
import {useGlobalState} from "../utils";

//


function TaskDone(props) {
    const [token] = useGlobalState('token');
    const {currentState, taskId} = props;

    const [value, setValue] = useState(null);

    useEffect(() => {
        setValue(currentState);
    }, [taskId, currentState])

    const handleSendDone = (e) => {
        console.log("handleSendDone = value = ", e.target.checked);
        setValue(e.target.checked);
        doneRequest(e.target.checked);
    }


    const doneRequest = async (checked) => {
        const requestData = {
            "id": taskId,
            "isDone": checked,
        }

        const requestUrl = `${ENV_API_HOST}/api/doneTask`;
        const metadataResponse = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestData)
        });
        await metadataResponse.json();
        props.onUpdate();
    }

    return(<span><Checkbox checked={value} onChange={handleSendDone} /></span>)
}

export default TaskDone;

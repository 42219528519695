import {Button, Col, Row} from "antd";
import StepEditorWidget from "./StepEditorWidget";
import GeneralTaskSequenceWidget from "./sequenceStep/GeneralTaskSequenceWidget";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment, faEnvelope, faPhone, faSquareCheck} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import AutoEmailSequenceWidget from "./sequenceStep/AutoEmailSequenceWidget";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";

function CreateNewStepWidget(props) {


    const handleOpenWindow = () => {

    }

    return (<span>



        {/*<Button type={"primary"} onClick={handleOpenWindow}>Create a new step</Button>*/}
    </span>)
}

export default CreateNewStepWidget;

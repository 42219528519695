import {useGlobalState} from "../utils";
import {Alert, Button, Form, Input, message, Modal} from "antd";
import {ENV_API_HOST} from "../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";

function AttachEmailToProfile(props) {
    const [messageApi, contextHolder] = message.useMessage();
    const [token] = useGlobalState('token');
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [errorContent, setErrorContent] = useState(null);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const handleSubmit = () => {
        form.validateFields().then(async (values) => {
            setErrorContent(null);
            console.log("values = ", values);
            setIsSaving(true);
            values["profileId"] = props.profileId;
            values["profileName"] = props.profileName;
            const requestUrl = `${ENV_API_HOST}/api/postAttachEmailToProfile`;
            const metadataResponse = await fetch(requestUrl, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(values)
            });

            const responseData = await metadataResponse.json();
            if (metadataResponse.ok) {
                props.onUpdate();
                handleCancel();
                form.resetFields();
            } else {
                setErrorContent(responseData)
            }
            setIsSaving(false);
        } )
    }

    return(<span>
        <Modal title="Create New Profile" open={isModalOpen} onCancel={handleCancel} footer={[
            <Button key="back" onClick={handleCancel}>
                Return
            </Button>,
            <Button key="submit" type="primary" loading={isSaving} onClick={handleSubmit}>
                Submit
            </Button>
        ]}>
            {errorContent != null ?
                <div style={{marginBottom: "10px"}}><Alert message={errorContent} type="error" /></div>: null}


            <Form
                form={form}
                name="basic"
                layout={"vertical"}
                autoComplete="off"
            >

                <Form.Item
                    label="Email:"
                    name="email"
                    rules={[{type: 'email', required: true, message: "Email field is required"}]}
                >
                    <Input/>
                </Form.Item>

            </Form>
        </Modal>
        <Button onClick={showModal}>Attach Email</Button></span>)
}

export default AttachEmailToProfile;

import {useGlobalState} from "./utils";
import moment from "moment/moment";
import {Alert, Button, Card, Col, Row, Typography, List, Tooltip} from "antd";
import React, {Fragment, useEffect, useState} from "react";
import BillingPortal from "./BillingPortal";
import Billing from "./Billing";
import {ENV_API_HOST} from "./config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCheckCircle, faCircleQuestion} from "@fortawesome/free-solid-svg-icons";
import PersonalizationBillingWidget from "./widgets/billing/PersonalizationBillingWidget";
import PlatformBillingWidget from "./widgets/billing/PlatformBillingWidget";
import AffilateBillingWidget from "./widgets/billing/AffilateBillingWidget";


function ProductDisplay() {

    const [token] = useGlobalState("token");
    const [isLoadingBillingData, setIsLoadingBillingData] = useState(true);
    const [billingData, setBillingData] = useState(null);


    const getBillingData = async () => {
        setIsLoadingBillingData(true);
        const requestUrl = `${ENV_API_HOST}/api/getTeamBilling`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        const responseData = await metadataResponse.json();
        setBillingData(responseData);
        setIsLoadingBillingData(false);
    }

    useEffect( () => {
        getBillingData();
    }, []);


    const updateBillingData = () => {
        console.log("[ProductDisplay.js] from updateBillingData")
        getBillingData();
    }

    if (isLoadingBillingData) {
        return (<div>
            Loading ...
        </div>)
    }


    let personalizationBilling = null;
    let platformBilling = null;
    for (let i = 0; i < billingData.length; i++) {
        if (billingData[i]["product"]["plan_type"] == "PLATFORM") {
            platformBilling = billingData[i];
        } else if (billingData[i]["product"]["plan_type"] == "PERSONALIZATION") {
            personalizationBilling = billingData[i];
        }
    }


    return (<span>
        <PlatformBillingWidget billingData={platformBilling} onUpdate={updateBillingData} />
        <PersonalizationBillingWidget billingData={personalizationBilling} onUpdate={updateBillingData} />
        <AffilateBillingWidget />
    </span>)
}

export default ProductDisplay;

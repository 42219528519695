import React, {Fragment, useEffect, useState} from "react";
import {
    useNavigate,
    useParams
} from "react-router-dom";

import {useGlobalState} from "./utils";
import {Avatar, Button, Col, Divider, Empty, Layout, Row, Spin, Tabs, Tag, Typography, Radio, Alert} from "antd";
import "./chat.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ENV_API_HOST} from "./config";
import {
    faArrowsUpToLine, faArrowUp, faBusinessTime, faEnvelope, faExternalLink, faLocationDot, faPhone
} from "@fortawesome/free-solid-svg-icons";
import CreateTask from "./CreateTask";
import TaskList from "./TaskList";
import ActivityList from "./ActivityList";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import AssignToSequence from "./widgets/sequenceStep/AssignToSequence";
import SequenceAssignmentWidget from "./widgets/SequenceAssignmentWidget";
import MergeProfileWidget from "./widgets/MergeProfileWidget";
import AttachEmailToProfile from "./widgets/AttachEmailToProfile";
import NoteEditor from "./widgets/NoteEditor";

const {Text, Link, Title, Paragraph} = Typography;
const {Header, Content, Sider} = Layout;


function buildRedirectUrl(userId) {
    // Get current url and host
    var url = window.location.href;
    var host = window.location.host;

    // Extract protocol
    var protocol = url.split('/')[0];

    // Create own redirect url
    var redirectUrl = protocol + "//" + host + "/user/"+userId+"/activity";

    // Redirect to new url
    return redirectUrl
}


function PersonProfile(props) {
    const [token] = useGlobalState('token');
    const [messageList, setMessageList] = useState([])
    const [recipient, setRecipient] = useState(null)
    // const [isLoading, setIsLoading] = useState(true);
    const [ellipsis, setEllipsis] = useState(true);
    const [isLoadingProfile, setIsLoadingProfile] = useState(true);
    const [emailList, setEmailList] = useState([]);

    const [profileRawData, setProfileRawData] = useState(null);
    const [personProfile, setPersonProfile] = useState(null);
    const [experienceList, setExperienceList] = useState(null);
    const [updateTableTicket, setUpdateTableTicket] = useState(null);
    const [updateAssigneeTableTicket, setUpdateAssigneeTableTicket] = useState(null);

    const [profileId, setProfileId] = useState(null);


    let {userId, tabId} = useParams();
    const nav = useNavigate();

    const handleProfileUpdate = () => {
      getPersonData();
    }

    const getPersonData = async () => {
        setIsLoadingProfile(true);
        const requestUrl = `${ENV_API_HOST}/api/getPersonDataShort?id=${userId}`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();


        if (dataResponse["merge_status"] == "MERGED") {
            window.location.replace(buildRedirectUrl(dataResponse["merge_profile_id"]));
        }

        setProfileRawData(dataResponse);
        setProfileId(dataResponse["id"]);
        setPersonProfile(dataResponse["person"]);
        setEmailList(dataResponse["email_list"]);
        setIsLoadingProfile(false);
    }



    const handleChangeTab = (newTabId) => {
        console.log("tabId = ", newTabId, userId);
        nav(`/user/${userId}/${newTabId}`);
    }

    useEffect(() => {
        getPersonData();
    }, [props?.userId, props?.connectionId]);


    const hideAbout = () => {
        setEllipsis(true);
    }

    let renderMessageList = [];

    for (let i = 0; i < messageList.length; i++) {
        const msgText = messageList[i]["message"];
        const msgTime = messageList[i]["full_timestamp"];
        if (messageList[i]["is_account_owner"]) {
            renderMessageList.push(<div>
                <div className="message text-only">
                    <div className="response">
                        <p className="text">{msgText}</p>
                    </div>
                </div>
                <div style={{width: "100%", display: "flex"}}>
                    <div className="response-time time">{msgTime}</div>
                </div>
            </div>);
        } else {
            renderMessageList.push(<div>
                <div className="message text-only">
                    <p className="text">{msgText}</p>
                </div>
                <div style={{width: "100%", display: "flex"}}>
                    <div className="time">{msgTime}</div>

                </div>
            </div>);
        }

    }


    if (isLoadingProfile) {
        return <div style={{textAlign: "center", paddingTop: "100px", paddingBottom: "100px"}}>
            <Spin tip="Loading" size="large">
                <div className="content"/>
            </Spin>
        </div>
    }

    let experienceRenderList = null;
    let profileRender = null;
    if (isLoadingProfile) {
        profileRender = <div style={{textAlign: "center", paddingTop: "100px", paddingBottom: "100px"}}>
            <Spin tip="Loading profile ..." size="large">
                <div className="content"/>
            </Spin></div>;
    } else {


        if (personProfile["experience_list"].length > 0) {

            let localRenderList = [];
            for (let i = 0; i < personProfile["experience_list"].length; i++) {
                let localExperience = personProfile["experience_list"][i];
                let expRenderItem = <Row style={{marginBottom: "15px"}}>
                    <Col span={5}>
                        <Link href={`/company/${localExperience["Company"]["id"]}`}><Avatar shape="square" size={64}
                                                                                            src={localExperience["Company"]["image"]}/></Link>
                    </Col>
                    <Col span={19}>
                        <Title style={{marginTop: "0px", marginBottom: "2px"}}
                               level={4}>{localExperience["Position"]["Name"]}</Title>
                        <Link
                            href={`/company/${localExperience["Company"]["id"]}`}>{localExperience["Company"]["name"]}</Link>
                        <div style={{marginTop: "4px"}}><Text type={"secondary"}><FontAwesomeIcon
                            icon={faBusinessTime}/> {localExperience["StartDateOrigin"]} - {localExperience["EndDateOrigin"]} ({localExperience["DurationOrigin"]})</Text>
                        </div>
                        {localExperience["Location"] != null ? <div><Text type={"secondary"}><FontAwesomeIcon
                            icon={faLocationDot}/> {localExperience["Location"]}</Text></div> : null}

                    </Col>
                </Row>;

                localRenderList.push(expRenderItem);
            }

            experienceRenderList = <div>
                <Title level={5}>Experience:</Title>
                {localRenderList}
            </div>
        }


        let emailListRender = [];

        for (let i = 0; i < emailList.length; i++) {
            emailListRender.push(<div style={{marginTop: "10px"}}>
                <Text>
                    <Tag color={"processing"}><FontAwesomeIcon icon={faEnvelope} /></Tag>
                    {emailList[i]["email"]}
                </Text>
            </div>);
        }

        profileRender = <span>
        <h2><span><Avatar size={42} src={personProfile.photo}/>&nbsp; {personProfile.name} </span></h2>
                    <div style={{marginLeft: "5px"}}>
                        {/*<div><Text type="secondary"><FontAwesomeIcon icon={faLocationDot}/> {personProfile[""]}</Text></div>*/}

                        {personProfile["slug"] != null && personProfile["slug"].length > 1 ? <div style={{marginTop: "10px"}}><Text><Tag color={"processing"}><FontAwesomeIcon icon={faLinkedinIn} /></Tag>
                            <Link href={`https://linkedin.com/in/${personProfile["slug"]}`}
                                  target={"_blank"}>/in/{personProfile["slug"]}</Link></Text></div> : null }


                        {emailListRender}

                        <div>
                            <Title style={{marginTop: "20px"}} level={5}>Actions:</Title>
                            <div><MergeProfileWidget profileData={profileRawData} onUpdate={handleProfileUpdate} /> <AttachEmailToProfile profileId={profileId} profileName={personProfile.name} onUpdate={handleProfileUpdate} /></div>
                        </div>


                        <div>
                            {personProfile["about"] != null ?
                                <span><Title style={{marginTop: "20px"}} level={5}>About:</Title>
                            <Paragraph ellipsis={ellipsis ? {rows: 6, expandable: true, symbol: 'more'} : false}>
                                {/*{personProfile["about"]}*/}
                                <div dangerouslySetInnerHTML={{ __html: personProfile["about"].replace(/(?:\r\n|\r|\n)/g, '<br />') }} />
                                {ellipsis ? <Button type={"link"}><FontAwesomeIcon icon={faArrowUp}
                                                                                   onClick={hideAbout}/>&nbsp; Hide
                                    about</Button> : null}
                            </Paragraph></span> : null}


                            {experienceRenderList}

                        </div>


                            {personProfile["experience_list"].length == 0 ? <div style={{marginTop: "20px", marginBottom: "40px"}}>
                                <Title level={5}>Experience:</Title>
                                <Alert
                                message="No Experience data?"
                                description={<span>
                                    <div><a target={"_blank"} href={"https://chrome.google.com/webstore/detail/nbnlgamlhnjliakkcblimcinbnmichaf"}>Download the Chrome plugin</a>.</div>
                                    <div style={{marginTop: "10px"}}>Visit the prospect's profile on LinkedIn and click the "Save in scope.money" button after logging into the plugin.</div>
                                </span>}
                                 type="info"
                                showIcon
                            /></div> : null}


                    </div>
        </span>;
    }


    const tabMenuItems = [
        {
            key: 'activity',
            label: `Activity history`,
        },
        {
            key: 'tasks',
            label: `Tasks`,
        },
        {
            key: 'sequences',
            label: `Sequences`,
        },
    ];

    const handleCreateTaskUpdate = () => {
        setUpdateTableTicket(Math.random());
    }

    const handleNewSequenceAssignee = () => {
        setUpdateAssigneeTableTicket(Math.random())
    }

    let tabRender = null;
    let tabButtons = <span>
        {/*<Button>Create a note</Button> */}
        <CreateTask profileId={profileId} onUpdate={handleCreateTaskUpdate}/>
    </span>


    if (tabId == "activity") {
        tabRender =
            <div style={{padding: "0px 17px"}}>
                <ActivityList userId={userId} personProfile={personProfile} updateTicket={updateTableTicket}  />
            </div>
    } else if (tabId == "tasks") {
        tabRender = <div style={{padding: "0px 17px"}}>
            <TaskList profileId={userId} updateTicket={updateTableTicket} />
        </div>;
    } else if (tabId == "sequences") {
        tabButtons = <span>
            <AssignToSequence profileId={profileId} onUpdate={handleNewSequenceAssignee} />
        </span>
        tabRender = <div style={{padding: "0px 17px"}}>
            <SequenceAssignmentWidget profileId={profileId} updateTicket={updateAssigneeTableTicket} />
        </div>;
    }




    return (

        <Content
            style={{
                padding: 12, margin: 0, minHeight: 100, background: "white",
            }}
        >

            <Row>
                <Col span={7} style={{borderRight: "1px solid #f0f0f0"}}>
                    <div style={{paddingRight: "12%"}}>
                        {profileRender}
                    </div>
                </Col>
                <Col span={17}>
                    <div style={{textAlign: "right"}}>
                        <Row>
                            <Col span={24}>
                                {tabButtons}
                            </Col>
                        </Row>


                        <Tabs style={{paddingLeft: "20px"}} onChange={handleChangeTab} defaultActiveKey={tabId}
                              items={tabMenuItems}/>

                    </div>

                    {tabRender}

                </Col>
            </Row>


        </Content>);
}

export default PersonProfile;

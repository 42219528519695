import {Button, Col, Form, Input, Row, Segmented} from "antd";
import {useState} from "react";


function EmailListFilterWidget(props) {
    const [form] = Form.useForm();
    const {currentValues} = props;
    // const [nameFilter, setNameFilter] = useState(null);
    // const [emailStatus, setEmailStatus] = useState('Unrecognized emails');
    // const [emailType, setEmailType] = useState('Regular');

    const handleApply = () => {
        form.validateFields().then((values) => {
            props.onUpdate(values);
        })
    }

    return (<div style={{marginBottom: "20px"}}>
        <Form
            form={form}
            name="basic"
            layout={"vertical"}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            // style={{ maxWidth: 600 }}
            initialValues={currentValues}
            onFinish={handleApply}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

            <Row style={{marginBottom: "20px"}}>
                <Col span={24}
                     style={{
                         borderWidth: 2,
                         borderColor: '#d9d9d9',
                         borderRadius: 5,
                         padding: 15,
                         background: '#fafafa'
                     }}>

                    <Form.Item
                        label="Filter by Email Folder:"
                        name="mail_category"
                    >
                        <Segmented size="large"
                                   options={[
                                       { label: 'Inbox', value: 'INBOX'},
                                       { label: 'Sent', value: 'SENT'},
                                   ]}/>
                    </Form.Item>


                </Col>
            </Row>

            <Row style={{marginBottom: "20px"}}>
                <Col span={24}
                     style={{
                         borderWidth: 2,
                         borderColor: '#d9d9d9',
                         borderRadius: 5,
                         padding: 15,
                         background: '#fafafa'
                     }}>

                    <Form.Item
                        label="Filter by Email Type:"
                        name="mail_type"
                    >
                        <Segmented size="large"
                                   options={[
                                       { label: 'Regular', value: 'regular'},
                                       { label: 'Updates', value: 'promo'},
                                       { label: 'All mail', value: 'all'},
                                   ]}/>
                    </Form.Item>


                </Col>
            </Row>

            {/*<Row style={{marginBottom: "20px"}}>*/}


            {/*    <Col span={24}*/}
            {/*         style={{*/}
            {/*             borderWidth: 2,*/}
            {/*             borderColor: '#d9d9d9',*/}
            {/*             borderRadius: 5,*/}
            {/*             padding: 15,*/}
            {/*             background: '#fafafa'*/}
            {/*         }}>*/}


            {/*        <Form.Item*/}
            {/*            label="Filter by Mail Status:"*/}
            {/*            name="mail_by_state"*/}
            {/*        >*/}
            {/*            <Segmented size="large"*/}
            {/*                       options={[*/}
            {/*                           {label: 'Unrecognized emails', value: 'unrecognized'},*/}
            {/*                           {label: 'In process recognizing', value: 'in_process'},*/}
            {/*                           {label: 'Recognized emails', value: 'done'},*/}
            {/*                           {label: 'All mail', value: 'all'},*/}
            {/*                       ]}/>*/}
            {/*        </Form.Item>*/}

            {/*    </Col>*/}
            {/*</Row>*/}

            <Row style={{marginBottom: "20px"}}>
                <Col span={24}
                     style={{
                         borderWidth: 2,
                         borderColor: '#d9d9d9',
                         borderRadius: 5,
                         padding: 15,
                         background: '#fafafa'
                     }}>

                    <Form.Item
                        label="Search by Name or Email:"
                        name="nameFilter"
                    >
                        <Input  />
                    </Form.Item>

                </Col>
            </Row>

            <div style={{textAlign: "right"}}><Button type={"primary"} onClick={handleApply}>Apply filter</Button></div>
        </Form>
    </div>);
}

export default EmailListFilterWidget;

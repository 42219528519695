import {Col, Row, Segmented} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentDots, faEnvelope, faPhone, faSquareCheck} from "@fortawesome/free-solid-svg-icons";
import {faLinkedin, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {useState} from "react";
import AutoEmailSequenceWidget from "./sequenceStep/AutoEmailSequenceWidget";


function StepEditorWidget(props) {

    const [stepType, setStepType] = useState(null);

    const handleStepTypeChanges = (value) => {
        console.log("handle cahgne = ", value);
        setStepType(value);
    }


    return (
        <div>
            <Row>
                <Col span={24}>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Segmented
                            onChange={handleStepTypeChanges}
                            size="large"
                            value={null}
                            options={[
                                {
                                    label: (
                                        <div style={{padding: 4}}>
                                            <div>
                                                <FontAwesomeIcon icon={faEnvelope}/>
                                            </div>
                                            <div>Email</div>
                                        </div>
                                    ),
                                    value: 'email',
                                },
                                {
                                    label: (
                                        <div style={{padding: 4}}>
                                            <div>
                                                <FontAwesomeIcon icon={faLinkedin}/>
                                            </div>
                                            <div>LinkedIn</div>
                                        </div>
                                    ),
                                    value: 'linkedin',
                                },
                                {
                                    label: (
                                        <div style={{padding: 4}}>
                                            <div>
                                                <FontAwesomeIcon icon={faPhone}/>
                                            </div>
                                            <div>Call</div>
                                        </div>
                                    ),
                                    value: 'call',
                                },
                                {
                                    label: (
                                        <div style={{padding: 4}}>
                                            <div>
                                                <FontAwesomeIcon icon={faCommentDots}/>
                                            </div>
                                            <div>Messenger</div>
                                        </div>
                                    ),
                                    value: 'messenger',
                                },
                                {
                                    label: (
                                        <div style={{padding: 4}}>
                                            <div>
                                                <FontAwesomeIcon icon={faSquareCheck}/>
                                            </div>
                                            <div>Action</div>
                                        </div>
                                    ),
                                    value: 'action',
                                },
                            ]}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>

                </Col>
            </Row>
        </div>

)
    ;
}

export default StepEditorWidget;

import React, {Fragment, useEffect, useState} from "react";
import {
    Button,
    Col, Input,
    Layout, Row, Radio,
    List, Card, Typography
} from 'antd';
import {useGlobalState} from "./utils";
import {CheckCircleTwoTone, HeartTwoTone, SmileTwoTone} from '@ant-design/icons';
import moment from "moment";
import ProductDisplay from "./ProductDisplay";
import checkIsNeedInviteActionsFirst from "./utils/inviteChecker";
import {ENV_API_HOST} from "./config";


const {Content} = Layout;

const {Text, Link} = Typography;





function Billing() {
    const [token] = useGlobalState('token');
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const getAccountData = async () => {
            const url = `${ENV_API_HOST}/api/getAccountData`;
            const responseObj = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            const responseData = await responseObj.json();
            console.log("[Billing.js] responseData = ", responseData);
            setUserData(responseData);
        }

        getAccountData()
    }, []);

    let isInviteAction = checkIsNeedInviteActionsFirst();
    if (isInviteAction != null) {
        return isInviteAction
    }


    console.log("[Billing.js] userData =", userData);
    return (
        <div>
            <Content style={{padding: '24px 24px', background: "white"}}>

                {userData ? <div>

                    {/*<SubscriptionList />*/}
                    <ProductDisplay />
                </div> : null}

            </Content>

        </div>
    );
}

export default Billing;

import React, {useEffect, useState} from "react";
import {Button, Card, Col, Layout, Row, Spin, Switch, Tag, Typography} from "antd";
import {useGlobalState} from "./utils";
import ReactQuill from "react-quill";
import CreateNewStepWidget from "./widgets/CreateNewStepWidget";
import {useParams} from "react-router-dom";
import AutoEmailSequenceWidget from "./widgets/sequenceStep/AutoEmailSequenceWidget";
import GeneralTaskSequenceWidget from "./widgets/sequenceStep/GeneralTaskSequenceWidget";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment, faEnvelope, faFlag, faPhone, faSquareCheck} from "@fortawesome/free-solid-svg-icons";
import {faLinkedin, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {ENV_API_HOST} from "./config";
import SequenceCardListWidget from "./widgets/sequenceStep/SequenceCardListWidget";

const {Header, Content, Sider} = Layout;

const richTextModules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link'],
        ['clean']
    ],
};

const richTextFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link',
]
//const [textValue, setTextValue] = useState(true)
//
//
//     const handleNotesValue = (e) => {
//         setTextValue(e);
//     }
//
//
//     const handleCustomVariable = () => {
//         setTextValue(prevText => prevText + `{{first_name}}`)
//     }
//     <Button onClick={handleCustomVariable}>first name</Button>
// <ReactQuill value={textValue} onChange={handleNotesValue} theme="snow" modules={richTextModules}
//             formats={richTextFormats}/>

function EditSequencePage() {
    const [token] = useGlobalState('token');
    let {sequenceId} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [sequenceStepList, setSequenceStepList] = useState([]);


    const getSequenceStepList = async () => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getSequenceStepList?sequenceId=${sequenceId}`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        const responseData = await metadataResponse.json();
        setSequenceStepList(responseData);
        console.log("setSequenceStepList(true); = ", responseData);
        setIsLoading(false);
    }


    const handleUpdate = () => {
        getSequenceStepList();
    }


    useEffect(() => {
        getSequenceStepList();
    }, [sequenceId])


    let isLoadingRender = null;
    if (isLoading) {
        isLoadingRender = <div style={{textAlign: "center", paddingTop: "100px", paddingBottom: "100px"}}>
            <Spin tip="Loading sequence steps ..." size="large">
                <div className="content"/>
            </Spin>
        </div>;
    }


    let sequenceRenderList = [];
    if (sequenceStepList.length > 0) {

        for (let i = 0; i < sequenceStepList.length; i++) {
            const stepData = sequenceStepList[i];
            sequenceRenderList.push(<SequenceCardListWidget onUpdate={handleUpdate} stepData={stepData}/>)
        }
    }


    return (
        <Content style={{
            padding: 24, margin: 0, minHeight: 100, background: "white",
        }}>
            <div>

                {isLoadingRender}
                {sequenceRenderList}
            </div>
            <div>
                <Row>
                    <Col span={24} style={{display: "flex", justifyContent: "center"}}>
                        {/*<AutoEmailSequenceWidget id={sequenceId} type={"AUTO_EMAIL"}/>*/}

                        <GeneralTaskSequenceWidget onUpdate={handleUpdate} id={sequenceId} type={"AUTO_EMAIL"} label={(
                            <div style={{padding: 4}}>
                                <div>
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                </div>
                                <div> Auto Email (soon)</div>

                            </div>
                        )}/>

                        <GeneralTaskSequenceWidget onUpdate={handleUpdate} id={sequenceId} type={"MANUAL_EMAIL"} label={(
                            <div style={{padding: 4}}>
                                <div>
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                </div>
                                <div>Manual Email</div>
                            </div>
                        )}/>

                        <GeneralTaskSequenceWidget onUpdate={handleUpdate} id={sequenceId} type={"LINKEDIN"} label={(
                            <div style={{padding: 4}}>
                                <div>
                                    <FontAwesomeIcon icon={faLinkedin}/>
                                </div>
                                <div>LinkedIn Action</div>
                            </div>
                        )}/>

                        <GeneralTaskSequenceWidget onUpdate={handleUpdate} id={sequenceId} type={"CALL"} label={(
                            <div style={{padding: 4}}>
                                <div>
                                    <FontAwesomeIcon icon={faPhone}/>
                                </div>
                                <div>Phone Call</div>
                            </div>
                        )}/>


                        <GeneralTaskSequenceWidget onUpdate={handleUpdate} id={sequenceId} type={"MESSENGER"} label={(
                            <div style={{padding: 4}}>
                                <div>
                                    <FontAwesomeIcon icon={faComment}/>
                                </div>
                                <div>Messenger</div>
                            </div>
                        )}/>

                        <GeneralTaskSequenceWidget onUpdate={handleUpdate} id={sequenceId} type={"ACTION"} label={(
                            <div style={{padding: 4}}>
                                <div>
                                    <FontAwesomeIcon icon={faSquareCheck}/>
                                </div>
                                <div>Action Item</div>
                            </div>
                        )}/>


                    </Col>
                </Row>
            </div>


        </Content>
    );
}

export default EditSequencePage;

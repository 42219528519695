import React, {useEffect, useState} from "react";
import {Button, Card, Col, Empty, Input, Layout, Row, Spin, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ENV_API_HOST} from "./config";
import {useNavigate, useParams} from "react-router-dom";
import {useGlobalState} from "./utils";

const {TextArea} = Input;
const {Header, Content, Sider} = Layout;


function FeatureItem(props) {

    const [itemData, setItemData] = useState({});

    useEffect(() => {
        setItemData(props.data);
    }, [props.indx, props.data])


    const handleDelete = () => {
        console.log("FeatureItem.onDelete: props = ", props)
        props.onDelete(props.indx, props.data);
    }

    const handleChangeDescription = (e) => {
        itemData.description = e.target.value;
        console.log("FeatureItem.handleChangeDescription: e = ", e, "; props = ", props);
        props.onChange(props.indx, itemData);
    }

    const handleChangeName = (e) => {
        itemData.name = e.target.value;
        props.onChange(props.indx, itemData);
    }

    return (<Card style={{marginBottom: "10px"}}>
        <Row>
            <Col span={24}>
                <div style={{marginBottom: "5px"}}>Feature name:</div>
                <Input value={itemData.name} onChange={handleChangeName}/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <div style={{marginBottom: "5px", marginTop: "10px"}}>Feature description:</div>
                <TextArea value={itemData.description} onChange={handleChangeDescription} rows={2} showCount
                          maxLength={350}/>
            </Col>
        </Row>
        <Row style={{marginTop: "7px"}}>
            <Col span={24}>
                <Button danger onClick={handleDelete}>Delete</Button>
            </Col>
        </Row>
    </Card>)
}


function NewProduct(props) {
    const nav = useNavigate();

    const {productId} = useParams();
    const [token] = useGlobalState('token');
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isLoadingButton, setIsLoadingButton] = useState(false)

    const [featureList, setFeatureList] = useState([{id: null, name: null, description: null}]);

    const [productName, setProductName] = useState(null);
    const [productNameLength, setProductNameLength] = useState(0);

    const [productDescription, setProductDescription] = useState(null);
    const [productDescriptionLength, setProductDescriptionLength] = useState(0);

    const getProduct = async () => {
        const requestUrl = `${ENV_API_HOST}/api/getProduct?id=${productId}`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const productData = await metadataResponse.json();
        console.log("NewProduct:getProduct:productData = ", productData)
        return productData;
    }

    async function updateProductData() {
        let productData = await getProduct();
        setProductName(productData.name)
        setProductDescription(productData.description)
        if (productData.features.length > 0) {
            setFeatureList(productData.features);
        }
    }

    useEffect( () => {
        const getProductFromEffect = async () => {
            setIsPageLoading(true);
            const requestUrl = `${ENV_API_HOST}/api/getProduct?id=${productId}`;
            const metadataResponse = await fetch(requestUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            const productData = await metadataResponse.json();
            console.log("NewProduct:getProduct:productData = ", productData)
            setProductName(productData.name)
            setProductDescription(productData.description)
            if (productData.features.length > 0) {
                setFeatureList(productData.features);
            }
            setIsPageLoading(false);
        }

        console.log("NewProduct:useEffect: props =", props, productId);
        if (props.type == "edit") {
            getProductFromEffect();
        }

    }, [productId, props.type])


    const setValueFromProductForm = (target) => {
        console.log("setValueFromProductForm = ", target);
        if (target.name == "productName") {
            setProductNameLength(target.value.length);
            setProductName(target.value);
        } else if (target.name == "productDescription") {
            setProductDescriptionLength(target.value.length);
            setProductDescription(target.value);
        }
    }

    const saveData = () => {
        const postData = {
            productName: productName,
            productDescription: productDescription
        }

    }


    const getRealFeatureList = () => {
        let realFeatureList = [];
        for (let i = 0; i < featureList.length; i++) {
            if (featureList[i].name != null) {
                realFeatureList.push(featureList[i]);
            }
        }
        return realFeatureList;
    }


    const handleEditProduct = async () => {
        setIsLoadingButton(true);
        let requestData = {
            id: parseInt(productId),
            name: productName,
            description: productDescription,
            features: getRealFeatureList()
        }

        console.log("newProduct:handleEditProduct = requestdata = ", requestData);

        const requestUrl = `${ENV_API_HOST}/api/updateProduct`;
        const metadataResponse = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestData)
        });
        const productData = await metadataResponse.json();
        console.log("NewProduct:getProduct:productData = ", productData)

        await updateProductData();
        setIsLoadingButton(false)

    }

    const handleCreateProduct = async () => {
        setIsLoadingButton(true);
        let requestData = {
            name: productName,
            description: productDescription,
            features: getRealFeatureList()
        }

        console.log("newProduct:handleEditProduct = requestdata = ", requestData);

        const requestUrl = `${ENV_API_HOST}/api/createProduct`;
        const metadataResponse = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestData)
        });
        const productData = await metadataResponse.json();
        console.log("NewProduct:createProduct:productData = ", productData)

        setIsLoadingButton(false);
        nav("/product");
        // console.log("handleCreateProduct = ", postData);
    }


    const handleCreateNewFeature = () => {
        console.log("handleCreateNewFeature = ", featureList);
        featureList.push({name: null, description: null});
        setFeatureList(structuredClone(featureList));
    }


    const handleDeleteFeature = (key, selectedItem) => {
        console.log("handleDeleteFeature = ", key, selectedItem);
        featureList.splice(key, 1);
        setFeatureList(structuredClone(featureList));
    }

    const handleUpdateFeature = (key, updatedItem) => {
        console.log("NewProduct:handleUpdateFeature = ", updatedItem);
        featureList[key] = structuredClone(updatedItem);
        setFeatureList(structuredClone(featureList));
    }

    let renderFeatureList = [];
    for (let i = 0; i < featureList.length; i++) {
        renderFeatureList.push(<FeatureItem
            indx={i}
            key={i}
            data={featureList[i]}
            onDelete={handleDeleteFeature}
            onChange={handleUpdateFeature}
        />)
    }


    if (isPageLoading && props.type == "edit") {
        return (<Row>
            <Col span={24}>
                <Content
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                        background: "white",
                    }}
                >
                    <Spin/>
                </Content>
            </Col>
        </Row>)
    }

    return (
        <Row>
            <Col span={17}>
                <Content
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                        background: "white",
                    }}
                >

                    <Row>
                        <Col>
                            {props.type == "edit" ?
                                <Typography.Title level={3}>Product: {productName}</Typography.Title> :
                                <Typography.Title level={3}>Create a new product</Typography.Title>}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div style={{marginBottom: "5px"}}>Product name:</div>
                            <Input name={"productName"} value={productName}
                                   onChange={e => setValueFromProductForm(e.target)}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div style={{marginBottom: "5px", marginTop: "10px"}}>Description:</div>
                            <TextArea rows={4} name={"productDescription"} value={productDescription}
                                      onChange={e => setValueFromProductForm(e.target)} showCount maxLength={500}/>
                        </Col>
                    </Row>

                    <Row style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                        <Col span={16}>
                            <Typography.Title level={4}>Features</Typography.Title>
                        </Col>
                        <Col span={8} style={{textAlign: "right"}}>
                            <Button type={"primary"} onClick={handleCreateNewFeature}
                                    disabled={renderFeatureList.length >= 2 ? true : false}> <FontAwesomeIcon
                                icon="fa-solid fa-plus-large"/> New Feature</Button>
                        </Col>
                    </Row>

                    {renderFeatureList.length > 0 ? <span>{renderFeatureList}</span> : <Card><Empty/></Card>}

                    <Row style={{marginTop: "10px"}}>
                        <Col span={24}>
                            {props.type == "new" ?
                                <Button type={"primary"} size={"large"} block onClick={handleCreateProduct}
                                        loading={isLoadingButton}>Create a
                                    product</Button> :
                                <Button type={"primary"} size={"large"} block onClick={handleEditProduct}
                                        loading={isLoadingButton}>Update the
                                    product</Button>}

                        </Col>
                    </Row>
                </Content>
            </Col>
            <Col span={7}>
                <Content
                    style={{
                        padding: 24,
                        margin: 0,
                        marginLeft: "10px",
                        minHeight: 280,
                        background: "white",
                    }}
                >
                    <div>
                        <Typography.Title level={4}>Product name</Typography.Title>
                        <div>
                            <Typography.Text>Provide a clear and concise name for your product that reflects its
                                purpose, functionality, or main feature. Make sure the name is easy to understand and
                                remember.</Typography.Text>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <Typography.Text type="secondary">Example: TurboClean 3000 Vacuum Cleaner</Typography.Text>
                        </div>
                    </div>

                    <div>
                        <Typography.Title level={4}>Description</Typography.Title>
                        <div>
                            <Typography.Text>Write a brief overview of your product, highlighting its purpose, main
                                features, and benefits. Focus on the pain points it addresses and how it stands out from
                                the competition. Keep the description concise, informative, and
                                engaging.</Typography.Text>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <Typography.Text type="secondary">Example: The TurboClean 3000 Vacuum Cleaner is a powerful,
                                lightweight, and easy-to-use cleaning solution designed for busy households. With its
                                advanced suction technology, it effortlessly removes dirt, dust, and allergens from
                                carpets, hardwood floors, and upholstery, leaving your home spotless in no
                                time.</Typography.Text>
                        </div>
                    </div>


                    <div>
                        <Typography.Title level={4}>Feature Name</Typography.Title>
                        <div>
                            <Typography.Text>Provide a clear and concise name for each feature, highlighting its main
                                function or benefit.</Typography.Text>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <Typography.Text type="secondary">Example: Auto-Adjust Floor Head</Typography.Text>
                        </div>
                    </div>

                    <div>
                        <Typography.Title level={4}>Feature Description</Typography.Title>
                        <div>
                            <Typography.Text>Write a brief explanation of each feature, describing how it works and why
                                it's beneficial for your target audience. Make sure to emphasize how the feature solves
                                a specific problem or enhances the user experience.</Typography.Text>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <Typography.Text type="secondary">Example: Auto-Adjust Floor Head automatically adapts to
                                the surface being cleaned, ensuring optimal suction and cleaning performance on carpets,
                                hardwood floors, tiles, and more. This allows for a seamless transition between
                                different floor types without any manual adjustments.</Typography.Text>
                        </div>
                    </div>


                </Content>
            </Col>
        </Row>

    );
}


export default NewProduct;

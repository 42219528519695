import {ENV_API_HOST} from "../config";
import {useEffect, useState} from "react";
import {useGlobalState} from "../utils";
import {Avatar, Button, Card, Col, Empty, Row, Spin, Switch, Typography} from "antd";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faTrash} from "@fortawesome/free-solid-svg-icons";
import SequenceAssigmentItemWidget from "./sequenceStep/SequenceAssigmentItemWidget";


function SequenceAssignmentWidget(props) {
    const {profileId, updateTicket} = props;
    const [token] = useGlobalState('token');

    const [isLoading, setIsLoading] = useState(true);
    const [assigmentList, setAssigmentList] = useState([]);

    const getSequenceAssignmentList = async () => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getUserSequenceAssigmentList?id=${profileId}`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        setAssigmentList(dataResponse);
        setIsLoading(false);
    }


    useEffect(() => {
        getSequenceAssignmentList();
    }, [profileId, updateTicket])


    const handleListUpdate = () => {
        getSequenceAssignmentList();
    }

    let renderList = [];
    for (let i = 0; i < assigmentList.length; i++) {
        renderList.push(<SequenceAssigmentItemWidget data={assigmentList[i]} onUpdate={handleListUpdate}  />)
    }

    if (isLoading) {
        return(<div style={{textAlign: "center", paddingTop: "100px", paddingBottom: "100px"}}>
            <Spin tip="Loading ..." size="large">
                <div className="content"/>
            </Spin>
        </div>)
    }
    return(<div>{renderList.length > 0 ? renderList : <Empty style={{marginTop: "100px", marginBottom: "100px"}} />}</div>)
}

export default SequenceAssignmentWidget;

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import {Typography} from "antd";

const {Text} = Typography

function DueDateWidget(props) {

    const format = props.data["is_end_time_provided"]
        ? 'DD.MM.YYYY HH:mm'
        : 'DD.MM.YYYY';

    // Add timezone offset
    const dateWithOffset = dayjs(props.data["end_date_in_utc"]);

    // Format the end date
    const endDateFormatted = dateWithOffset.format(format);

    // if props.data[""]

    let textType = null;
    if (props.data["deadline_priority"] == 2) {
        textType = "danger";
    }  else if (props.data["deadline_priority"] == 1) {
        textType = "warning";
    } else if(props.data["is_done"] && props.data["deadline_priority"] != 2) {
        textType = "success";
    }

    return(<Text type={textType}>{endDateFormatted}</Text>);
}

export default DueDateWidget;

import './App.css';
import {BrowserRouter as Router, Routes, Route, Link, Navigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import Home from "./Home";
import Profile from "./Profile";
import PrivateRoute from "./utils/privateRouter";
import {DownOutlined, SmileOutlined} from '@ant-design/icons';

import {LaptopOutlined, NotificationOutlined, UserOutlined} from '@ant-design/icons';
import {Avatar, Dropdown, MenuProps, Space, Spin} from 'antd';
import {Breadcrumb, Layout, Menu, theme} from 'antd';
import ProductList from "./ProductList";
import ContactList from "./ContactList";
import NewProduct from "./NewProduct";
import {useEffect, useState} from "react";
import {useGlobalState} from './utils';
import NewPurpose from "./NewPurpose";
import Billing from "./Billing";
import LogoutButton from "./components/LogoutButton";
import Team from "./Team";
import ClaimInvitePage from "./ClaimInvitePage";
import PersonProfile from "./PersonProfile";
import RuleList from "./RuleList";
import {ENV_API_HOST} from "./config"

// import LogRocket from 'logrocket';
import posthog from 'posthog-js'
import SelectedCompany from "./SelectedCompany";
import EmailPage from "./EmailPage";
import ListPage from "./ListPage";
import SequencePage from "./SequencePage";
import EditSequencePage from "./EditSequencePage";

const {Header, Content, Sider} = Layout;

posthog.init('phc_C16HmAkzPzPknb916kAu3aQo7PPFlZPWbd23zFQHX8l', { api_host: 'https://eu.posthog.com' });

function App() {

    const [token, setToken] = useGlobalState('token');
    const [userAuthData, setUserAuthData] = useGlobalState('userAuthData');
    // const [searchParams, setSearchParams] = useSearchParams();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const inviteToken = urlParams.get("token");

    const [accountData, setAccountData] = useState(null);
    const [isLoadingAccountData, setIsLoadingAccountData] = useState(false)


    const {isLoading, error, user, isAuthenticated, getAccessTokenSilently, loginWithRedirect} = useAuth0();
    // console.log("[App.js] webapp: auth0:", isLoading, error);
    const inviteTokenFromLocalStorage = localStorage.getItem("inviteToken")
    // console.log("[App.js] inviteTokenFromLocalStorage:", inviteTokenFromLocalStorage);
    const accountRequest = async (token) => {
        setIsLoadingAccountData(true);
        const requestUrl = `${ENV_API_HOST}/api/getAccountData`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // console.log("metadataResponse.ok = ", metadataResponse.ok);

        if(metadataResponse.ok) {
            const responseData = await metadataResponse.json();
            setAccountData(responseData);
            setIsLoadingAccountData(false);

            posthog.identify(responseData["email"])
            // LogRocket.identify(responseData["id"], {
            //     name: responseData["name"],
            //     email: responseData["email"],
            //
            //     // Add your own custom user variables here, ie:
            //     // subscriptionType: 'pro'
            // });

        } else {
            const errorData = await metadataResponse.json();
            if (errorData["message"] == "signup_first") {

                if(inviteTokenFromLocalStorage == null) {
                    localStorage.setItem("inviteToken", "standalone");
                }
                window.location.href = `/invite`;
            }
            console.log("[App.js] errorData = ", errorData);
        }
    }


    useEffect(() => {
        console.log("[App.js] call useEffect");
        const getAuthToken = async () => {
            setIsLoadingAccountData(true);
            // console.log("[App.js] call getAccessTokenSilently");
            const accessToken = await getAccessTokenSilently();
            // console.log("AT:", accessToken, "; user = ", user, "isLoading: ", isLoading, "err = ", error);
            setToken(accessToken);
            // console.log("useEffect.getAuthToken: window.location.pathname", window.location.pathname, window.location.pathname.indexOf("invite"))
            if (window.location.pathname.indexOf("invite") == -1) {
                accountRequest(accessToken);
            } else {
                setIsLoadingAccountData(false);
            }


        }
        getAuthToken();
    }, [isLoading])


    console.log("[App.js] error", error, isAuthenticated , isLoading);

    if (error) {
        return <div>Oops... {error.message}</div>;
    }


    console.log("[App.js] localStorage.setItem", inviteToken);
    if (!isLoading && !isAuthenticated && window.location.pathname.indexOf("invite") != -1) {
        if (inviteToken != null) {
            localStorage.setItem("inviteToken", inviteToken);
        } else {
            localStorage.setItem("inviteToken", "standalone");
        }
    }

    if (!isAuthenticated && !isLoading) {
        return <Navigate to={loginWithRedirect()}/>;
    }

    console.log("[App.js] isLoading", isLoading, isLoadingAccountData);

    if (isLoading || isLoadingAccountData) {
        return  <div style={{textAlign: "center", marginTop: "30%"}}><Spin tip="Loading app ..." size="large">
            <div className="content" />
        </Spin></div>;
    } else if (isAuthenticated) {
        console.log("[App.js] user = ", user, "isLoading: ", isLoading);
        setUserAuthData(user);
    }




    let isNeedRedirect = (inviteTokenFromLocalStorage && isAuthenticated && window.location.pathname.indexOf("invite") == -1);

    console.log("[App.js] isNeedRedirect = ", isNeedRedirect);

    if (isNeedRedirect) {
        window.location.href = `/invite?token=${inviteTokenFromLocalStorage}`;
        return (<div>Redirecting to invite page ...</div>);
    }

    return (
        <Router>
            <Layout>
                {isAuthenticated ? <Header className="header">
                    {/*<div className="logo">scope.money</div>*/}
                    <Menu theme="dark" mode="horizontal" style={{display: 'block'}}>
                        <Menu.Item key='connection'><Link to={"/connection"}>Profiles</Link></Menu.Item>
                        <Menu.Item key='emails'><Link to={"/email"}>Emails</Link></Menu.Item>
                        {/*<Menu.Item key='lists'><Link to={"/list"}>Lists</Link></Menu.Item>*/}
                        <Menu.Item key='product'><Link to={"/product"}>Offers</Link></Menu.Item>
                        <Menu.Item key='rule'><Link to={"/rule"}>Communication Rules</Link></Menu.Item>
                        <Menu.Item key='sequences'><Link to={"/sequence"}>Sequences</Link></Menu.Item>

                        {accountData == null || accountData != null && accountData.role == "ADMIN" ?
                            <Menu.SubMenu key={"dropdown-menu"} title={<span><Avatar src={user.picture}/> {user.name}</span>}
                                          style={{float: 'right'}}>
                                <Menu.Item><Link to={"/profile"}>Profile</Link></Menu.Item>
                                <Menu.Item><Link to={"/team"}>Team</Link></Menu.Item>
                                <Menu.Item><Link to={"/billing"}>Billing</Link></Menu.Item>
                                <Menu.Item><a href={"https://chrome.google.com/webstore/detail/nbnlgamlhnjliakkcblimcinbnmichaf"} target={"_blank"}>Chrome plugin</a></Menu.Item>
                                <LogoutButton/> </Menu.SubMenu>
                            :  <Menu.SubMenu key={"dropdown-menu"} title={<span><Avatar src={user.picture}/> {user.name}</span>}
                                             style={{float: 'right'}}>
                                <Menu.Item><Link to={"/profile"}>Profile</Link></Menu.Item>
                                <Menu.Item><a href={"https://chrome.google.com/webstore/detail/nbnlgamlhnjliakkcblimcinbnmichaf"} target={"_blank"}>Chrome plugin</a></Menu.Item>
                                <LogoutButton/>
                            </Menu.SubMenu>}

                        {accountData != null ?  <Menu.Item style={{float: 'right'}}><span><Avatar src={"/coin.png"}/> {accountData["billing"]["token_available"]}</span></Menu.Item> : null }

                        <Menu.Item style={{float: 'right'}}><a href={"https://medium.com/@scopemoney/list/tutorials-scopemoney-38a7beb5bae3"} target={"_blank"}>Tutorials</a></Menu.Item>


                    </Menu>
                </Header> : null}


                <Layout>

                    {/*<Sider width={200} style={{ background: "white" }}>*/}
                    {/*  <Menu*/}
                    {/*      mode="inline"*/}
                    {/*      defaultSelectedKeys={['1']}*/}
                    {/*      defaultOpenKeys={['sub1']}*/}
                    {/*      style={{ height: '100%', borderRight: 0 }}*/}
                    {/*      items=*/}
                    {/*  />*/}
                    {/*</Sider>*/}
                    <Layout style={{padding: '0 24px 24px'}}>
                        {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                        {/*    <Breadcrumb.Item>Home</Breadcrumb.Item>*/}
                        {/*    <Breadcrumb.Item>List</Breadcrumb.Item>*/}
                        {/*    <Breadcrumb.Item>App</Breadcrumb.Item>*/}
                        {/*</Breadcrumb>*/}
                        <div style={{marginTop: "20px"}}></div>

                        <Routes>
                            <Route element={<PrivateRoute/>}>
                                <Route path="/" exact element={<Navigate to="/connection" replace/>}/>
                                <Route path="/product" exact element={<ProductList accountData={accountData} />}/>
                                <Route path="/product/new" exact element={<NewProduct type={"new"} />}/>
                                <Route path="/product/:productId/edit" exact element={<NewProduct type={"edit"} />}/>
                                <Route path="/purpose/new/:productId" exact element={<NewPurpose/>}/>
                                <Route path="/connection" exact element={<ContactList/>}/>
                                <Route path="/company/:companyId" exact element={<SelectedCompany />}/>
                                <Route path="user/:userId/:tabId" exact element={<PersonProfile/>}/>
                                <Route path="/billing" exact element={<Billing/>}/>
                                <Route path="/email" exact element={<EmailPage/>}/>
                                {/*<Route path="/list" exact element={<ListPage />}/>*/}
                                <Route path="/sequence" exact element={<SequencePage />}/>
                                <Route path="/sequence/:sequenceId/edit" exact element={<EditSequencePage />}/>
                                <Route path="/rule" exact element={<RuleList accountData={accountData} />}/>
                                {/*<Route path="/connection" exact element={<ContactList />}/>*/}
                                <Route path="/profile" element={<Profile accountData={accountData} />}/>
                                <Route path="/team" element={<Team accountData={accountData} />}/>
                                <Route path="/invite" element={<ClaimInvitePage user={user}/>}/>
                                {/* Other Routes you want to protect */}
                            </Route>
                        </Routes>


                    </Layout>
                </Layout>
            </Layout>
        </Router>

    )


    // return (
    //   <div className="App">
    //     <header className="App-header">
    //       <img src={logo} className="App-logo" alt="logo" />
    //       <p>
    //         Edit <code>src/App.js</code> and save to reload.
    //       </p>
    //       <a
    //         className="App-link"
    //         href="https://reactjs.org"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         Learn React
    //       </a>
    //     </header>
    //   </div>
    // );
}

export default App;

import {Button, Layout} from "antd";
import FilterContactList from "./FilterContactList";

const {Content} = Layout;
function FilterWidget(props) {
    const {searchData} = props;

    return( <Content
        style={{
            padding: 12,
            margin: 0,
            background: "white",
        }}
    >
        <FilterContactList searchData={searchData} onUpdate={props.onUpdate}/>
    </Content>)
}

export default FilterWidget

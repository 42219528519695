import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {Button, Popconfirm} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {ENV_API_HOST} from "../config";
import {useGlobalState} from "../utils";


function TaskListActionWidget(props) {
    const [token] = useGlobalState('token');


    const archiveTask = async () => {
        const response = await fetch(`${ENV_API_HOST}/api/deleteTask?id=${props.data["id"]}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        const result = await response.json();
        props.onUpdate();
    }

    return (
        <span>
        <Popconfirm
            title="Delete the task"
            placement="left"
            description="Are you sure to delete this task?"
            onConfirm={archiveTask}
            icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
        >
            <Button type="link" danger><FontAwesomeIcon icon={faTrash}/></Button>
        </Popconfirm>
        </span>
    )
}

export default TaskListActionWidget;

import React, {useState} from "react";
import {ENV_API_HOST} from "./config";
import {useGlobalState} from "./utils";


function TurnRule(props) {
    const [token] = useGlobalState('token');
    const [isLoading, setIsLoading] = useState(false);

    const onClickTurn = async () => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/turnCommunicationRule`;

        const resqt = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({id: props.fields["id"]})
        });
        await resqt.json();
        props.onUpdate();
        setIsLoading(false);
    }


    let renderButton  = <a key="list-loadmore-turnoff" onClick={onClickTurn}>turn on</a>;
    if (props.fields["isActive"]) {
        renderButton = <a key="list-loadmore-turnoff" onClick={onClickTurn}>turn off</a>;
    }

    return(<span>
        {!isLoading ? renderButton : <span>Saving...</span>}
    </span>)
}

export default TurnRule;

import {Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClock, faFlag} from "@fortawesome/free-solid-svg-icons";
import {faSquare} from "@fortawesome/free-regular-svg-icons";

function TaskListWidgetForList(props) {
    const {person} = props;

    let expireRender = null;
    let warningRender = null;
    let activeRender = null;
    let doneRender = null;

    if (person["profile"]["tActive"] != null || person["profile"]["tDone"] != null) {
        if (person["profile"]["tActive"] > 0) {
            activeRender = <Tag icon={<FontAwesomeIcon icon={faSquare} />}> {person["profile"]["tActive"]}</Tag>;

            if(person["profile"]["tWarning"] > 0) {
                warningRender = <Tag color="orange" icon={<FontAwesomeIcon icon={faClock} />}> {person["profile"]["tWarning"]}</Tag>
            }

            if(person["profile"]["tExpire"] > 0) {
                expireRender = <Tag color="red" icon={<FontAwesomeIcon icon={faFlag} />}> {person["profile"]["tExpire"]}</Tag>
            }
        } else if (person["profile"]["tDone"] > 0) {
            doneRender = <Tag color="green" icon={<FontAwesomeIcon icon={faCheck} />}> {person["profile"]["tDone"]}</Tag>
        } else {
            expireRender = <span>No data</span>
        }
    } else {
        expireRender = <span>No data</span>
    }


    return(<span>
        {expireRender}{warningRender}{activeRender}{doneRender}
    </span>)
}

export default TaskListWidgetForList;

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {Alert, Button, Modal} from "antd";
import React, {useState} from "react";

function ConnectLinkedin(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    return(<span>
        <Modal
            title="Connect LinkedIn"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Alert
                message="Connect LinkedIn Profile"
                description={<span>To connect your LinkedIn profile, <a href={"https://chrome.google.com/webstore/detail/personalized-linkedin-mes/nbnlgamlhnjliakkcblimcinbnmichaf"} target={"_blank"}>download our Chrome Plugin</a> and start using it on LinkedIn. Using our Chrome Plugin, you can automatically get a complete prospect profile, match it with emails, and track your LinkedIn activities automatically on our dashboards.</span>}
                type="info"
                showIcon
            />

        </Modal>
        <Button style={{marginRight: "5px"}} size={"large"} type={"primary"} onClick={showModal}><FontAwesomeIcon icon={faLinkedinIn} /> &nbsp; Connect LinkedIn</Button>
    </span>)
}

export default ConnectLinkedin;

import {Badge, Tag, Typography} from "antd";
import DEPRICATED_EmailAddAsConnectionWidget from "./DEPRICATED_EmailAddAsConnectionWidget";
import EmailShowSingleEmailWidget from "./EmailShowSingleEmailWidget";

function EmailAddressListForClientWidget(props) {
    const {data} = props;

    let emailList = data["from_list"];
    if (data["category"] === "SENT") {
        emailList = data["to_list"];
    }

    return (
        <div style={{marginBottom: "4px"}}>{
            emailList.map((email, index) => (
                <>
                    <EmailShowSingleEmailWidget emailAddress={email} onUpdate={props.onUpdate} />
                    {index < emailList.length - 1 && ', '}
                </>
            ))
        }
        </div>
    );
}

export default EmailAddressListForClientWidget;

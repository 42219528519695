import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {Link} from "react-router-dom";
import {Menu} from "antd";

const LogoutButton = () => {
    const { logout } = useAuth0();

    return (
        <Menu.Item>
            <Link onClick={() => logout({ returnTo: window.location.origin })}>Logout</Link>
        </Menu.Item>
    );
};

export default LogoutButton;

import {Button, Col, DatePicker, Input, Modal, Radio, Row, Select, TimePicker} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faClock,
    faComment,
    faEnvelope,
    faFlag,
    faListCheck,
    faPhone, faSquareCheck,
    faUserGroup
} from "@fortawesome/free-solid-svg-icons";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {ENV_API_HOST} from "../config";
import ReactQuill from "react-quill";
import {useGlobalState} from "../utils";

const GetIconByTaskType = (taskType) => {
    switch (taskType) {
        case "call":
            return <FontAwesomeIcon icon={faPhone}/>;
        case "email":
            return <FontAwesomeIcon icon={faEnvelope}/>;
        case "manual_email":
            return <FontAwesomeIcon icon={faEnvelope}/>;
        case "auto_email":
            return <FontAwesomeIcon icon={faEnvelope}/>;
        case "linkedin":
            return <FontAwesomeIcon icon={faLinkedinIn}/>;
        case "meeting":
            return <FontAwesomeIcon icon={faUserGroup}/>;
        case "qualification":
            return <FontAwesomeIcon icon={faListCheck}/>;
        case "task":
            return <FontAwesomeIcon icon={faClock}/>;
        case "deadline":
            return <FontAwesomeIcon icon={faClock}/>;
        case "messenger":
            return <FontAwesomeIcon icon={faComment}/>;
        case "action":
            return <FontAwesomeIcon icon={faSquareCheck}/>;
        default:
            return null;
    }
}


export {GetIconByTaskType};



import React, {Fragment, useEffect, useState} from "react";
import {Button, Card, Col, Input, Layout, Modal, Row, Table, Tag} from "antd";
import {useGlobalState} from "./utils";
import TeamListTableUtils from "./TeamListTableUtils";
import {ENV_API_HOST} from "./config";

const {Header, Content, Sider} = Layout;

function TeamList(props) {
    const {accountData} = props;
    const [token] = useGlobalState('token');
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isInviting, setIsInviting] = useState(false);
    const [isEmailCorrect, setIsEmailCorrect] = useState(false);
    const [email, setEmail] = useState('');


    useEffect(() => {
        if(props.data != undefined) {
            console.log("TeamList:useEffect = ", props);
            setData(props.data);
        }
    }, [props?.data])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (record) => <span>{record == "ADMIN" ? <Tag color="orange">Admin</Tag>: record == "INVITING"?  <Tag color="blue">Invitation sent</Tag>: record == "MEMBER" ?  <Tag >Member</Tag> : record}</span>,
        },
        // {
        //     title: 'License',
        //     render: (record) => <span> {record.product.name} </span>,
        // },
        {
            title: 'Actions',
            width: '15%',
            render: (record) => <TeamListTableUtils accountData={accountData} record={record} onUpdate={props.onInvitedUpdate} />,
        }
    ];


    const postInvite = async () => {

        const postData = {
            "email": email
        }
        const requestUrl = `${ENV_API_HOST}/api/postNewTeamMember`;

        const responseStream = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });

        if(props.onInvitedUpdate != undefined) {
            props.onInvitedUpdate();
        }
    }
    
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const sendInvite = async () => {
        setIsInviting(true);
        await postInvite();
        setIsInviting(false);
    }

    const handleEmailValue = (e) => {
        const currentValue = e.target.value;
        setEmail(currentValue);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsEmailCorrect(emailRegex.test(currentValue));
    }

    return (
        <div>
            <Row style={{marginBottom: "5px"}}>
                <Col span={24} style={{textAlign: "right"}}>
                    <Button type={"primary"} onClick={showModal}>Invite member</Button>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Table columns={columns} dataSource={data}/>
                </Col>
            </Row>

            <Modal title="Invite team member" open={isModalOpen} footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={isInviting} onClick={sendInvite} disabled={email.length > 2 && isEmailCorrect ? null : true}>
                    Invite
                </Button>
            ]}>
                <div>
                    <Row>
                        <Col span={24}>Team member email:</Col>
                    </Row>
                    <Row style={{marginTop: "5px"}}>
                        <Col span={24}><Input status={email.length > 2 ? isEmailCorrect ? null : "error" : null} onChange={(e) => handleEmailValue(e)}/></Col>
                    </Row>

                </div>
            </Modal>

        </div>
    );
}

export default TeamList;

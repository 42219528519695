import {Button, Col, Form, Input, Row, Segmented, Typography, Radio, Space, InputNumber, Select, Modal} from "antd";
import ReactQuill, { Quill } from 'react-quill';
import "../../App.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {ENV_API_HOST} from "../../config";
import {useGlobalState} from "../../utils";


const delayOptions = [
    {
        value: "minutes",
        label: "Minutes"
    },
    {
        value: "hours",
        label: "Hours"
    },
    {
        value: "days",
        label: "Days"
    }
];



const subTypeForLinkedin = [
    {
        value: "ADD_TO_FRIEND",
        label: "Send Connection Request"
    },
    {
        value: "SEND_MESSAGE",
        label: "Send Message"
    },
    {
        value: "VIEW_PROFILE",
        label: "View Profile"
    },
    {
        value: "LIKE_POST",
        label: "Like Post"
    },
    {
        value: "COMMENT_POST",
        label: "Write Comment to Post"
    },
    {
        value: "INTERACT_WITH_POST",
        label: "Interact with Post"
    },
    {
        value: "INTERACT_WITH_PERSON",
        label: "Interact with Person"
    }
];


function GeneralTaskSequenceWidget(props) {
    const [token] = useGlobalState('token');
    const {type, label, id, isEdit, stepData} = props;
    const [form] = Form.useForm();
    const [radioValue, setRadioValue] = useState("NOW");
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const handleRadioChange = (e) => {
        setRadioValue(e.target.value);
    };

    const requestEdit = async (values) => {
        setIsLoading(true);
        values["id"] = parseInt(id);
        values["step"] = type

        const requestUrl = `${ENV_API_HOST}/api/postNewSequenceStep`;

        const resqt = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values)
        });
        await resqt.json();
        setIsLoading(false);

        props.onUpdate();
        onClose();
        form.resetFields();
    };


    const requestCreate = async (values) => {
        setIsLoading(true);
        values["sequenceId"] = parseInt(id);
        values["step"] = type

        const requestUrl = `${ENV_API_HOST}/api/postNewSequenceStep`;

        const resqt = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values)
        });
        await resqt.json();
        setIsLoading(false);

        props.onUpdate();
        onClose();
        form.resetFields();
        setRadioValue("NOW")
    }

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                form.validateFields().then((values) => {
                    console.log("handleSubmit : values = ", values);

                    if(isEdit) {
                        requestEdit(values);
                    } else {
                        requestCreate(values);
                    }
                });
                // form.resetFields();
                // onCreate(values);
            })
    }

    const handleOpen = () => {
        setIsOpen(true);
    }

    const onClose = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        console.log("useEffect no arguments", radioValue);
        if (!isEdit) {
            form.setFieldsValue({
                "ruleRunType": radioValue
            })
        }
    }, [])

    useEffect(() => {
        if (radioValue === "NOW") {
            form.resetFields(['delay', 'delayPeriod']);
        }
    }, [radioValue, form]);


    useEffect(() => {
        if (stepData != undefined) {
            console.log("card: step data = ", stepData["id"], stepData);
            setRadioValue(stepData["ruleRunType"]);
        }
    }, [id]);

    function handleDynamicVariable(type) {
        let insertText = `{{${type}}}`
        let currentValue = form.getFieldValue('task');
        form.setFieldsValue({'task': currentValue + ' ' + insertText});
    }


    let subTypeRender = null;
    if (type == "LINKEDIN") {
        subTypeRender = <Row>
            <Col span={24}>
                <div style={{marginBottom: "5px"}}>
                    <Typography.Text strong>Action type:</Typography.Text>
                </div>

                <Form.Item
                    name="stepSubType"
                    initialValue={"ADD_TO_FRIEND"}
                >
                    <Select options={subTypeForLinkedin}/>
                </Form.Item>
            </Col>
        </Row>;
    }


    let footerButtons = [
        <Button key="back" onClick={onClose}>
            Close
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
            Submit
        </Button>
    ];

    if (type == "AUTO_EMAIL") {
        footerButtons = [
            <Button key="back" onClick={onClose}>
                Return
            </Button>,
            <Button key="back" disabled>
                Send test email to me
            </Button>,
            <Button key="submit" type="primary" loading={false} onClick={handleSubmit}>
                Submit
            </Button>
        ]
    }

    return (<span>
        <Modal
            open={isOpen}
            title={!isEdit ? "Create a new step sequence" : "Edit a step sequence" }
            okText={isEdit ? "Submit" : "Create"}
            cancelText="Cancel"
            onCancel={onClose}
            onOk={handleSubmit}
            width={700}
            footer={footerButtons}
        >

            <Form
                form={form}
                initialValues={stepData}
            >
                <Row>
                    <Col span={24}>
                        <Typography.Text strong>When to start this step:</Typography.Text>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="ruleRunType"
                            rules={[{required: true, message: 'Please select a start step'}]}
                        >

                            <Radio.Group onChange={handleRadioChange} >
                                <Space direction="vertical">
                                    <Radio value={"NOW"}>Immediately after the previous step</Radio>
                                    <Radio value={"DELAY"}>

                                        <Form.Item name="ruleRunDelay" rules={[{required: (radioValue !== "NOW")}]}
                                                   noStyle>
                                            <InputNumber min={0} size={"small"} disabled={radioValue === "NOW"}/>
                                        </Form.Item>&nbsp;
                                        <span onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                        }}>
                                            <Form.Item name="ruleRunDelayType" rules={[{required: (radioValue !== "NOW")}]}
                                                       noStyle>
                                                    <Select size={"small"} options={delayOptions} style={{width: "100px"}}
                                                        disabled={radioValue === "NOW"}/>
                                            </Form.Item> after the previous step
                                        </span>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                {subTypeRender}


                <div>
                    <Row style={{marginTop: (type == "LINKEDIN" ? "0px" : "10px")}}>
                        <Col span={24}>
                            <div style={{marginBottom: "5px"}}>
                                <Typography.Text strong>Assign task priority:</Typography.Text>
                            </div>
                            <Form.Item
                                name={"taskPriority"}
                                initialValue={!isEdit ? "MEDIUM" : null}
                            >
                                <Segmented size="large" options={
                                    [{
                                        label: 'High priority',
                                        value: "HIGH"
                                    }, {
                                        label: 'Medium priority',
                                        value: "MEDIUM"
                                    }, {
                                        label: 'Low priority',
                                        value: "LOW"
                                    }]
                                }/>

                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div style={{marginBottom: "5px"}}>
                                <Typography.Text strong>Note:</Typography.Text>
                            </div>
                            <Form.Item name={"task"}>
                               <Input.TextArea  autoSize={{ minRows: 2 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>


                {/*{type != "AUTO_EMAIL" ? <div>*/}
                {/*    <Row style={{marginTop: (type == "LINKEDIN" ? "0px" : "10px")}}>*/}
                {/*        <Col span={24}>*/}
                {/*            <div style={{marginBottom: "5px"}}>*/}
                {/*                <Typography.Text strong>Assign task priority:</Typography.Text>*/}
                {/*            </div>*/}
                {/*            <Form.Item*/}
                {/*                name={"taskPriority"}*/}
                {/*                // initialValue={"MEDIUM"}*/}
                {/*            >*/}
                {/*                <Segmented size="large" options={*/}
                {/*                    [{*/}
                {/*                        label: 'High priority',*/}
                {/*                        value: "HIGH"*/}
                {/*                    }, {*/}
                {/*                        label: 'Medium priority',*/}
                {/*                        value: "MEDIUM"*/}
                {/*                    }, {*/}
                {/*                        label: 'Low priority',*/}
                {/*                        value: "LOW"*/}
                {/*                    }]*/}
                {/*                }/>*/}

                {/*            </Form.Item>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*    <Row>*/}
                {/*        <Col span={24}>*/}
                {/*            <div style={{marginBottom: "5px"}}>*/}
                {/*                <Typography.Text strong>Note:</Typography.Text>*/}
                {/*            </div>*/}
                {/*            <Form.Item name={"task"}>*/}
                {/*                <ReactQuill theme="snow" modules={richTextModules} formats={richTextFormats}/>*/}
                {/*            </Form.Item>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</div> : <div>*/}
                {/*    <Row style={{marginTop: "10px"}}>*/}
                {/*        <Col span={24}>*/}
                {/*            <Form.Item name={"taskName"}>*/}
                {/*                <Input size={"large"} addonBefore="Subject:"/>*/}
                {/*            </Form.Item>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*    <Row style={{marginTop: "0px", marginBottom: "10px"}}>*/}
                {/*        <Col span={24}>*/}
                {/*            Dynamic variables:*/}
                {/*            <div>*/}
                {/*                <Button value="default" onClick={() => handleDynamicVariable("first_name")}>First name</Button>&nbsp;*/}
                {/*                <Button value="default" onClick={() => handleDynamicVariable("last_name")}>Last name</Button>&nbsp;*/}
                {/*                <Button value="default" onClick={() => handleDynamicVariable("company_name")}>Company name</Button>&nbsp;*/}
                {/*                <Button value="default"  onClick={() => handleDynamicVariable("job_title")}>Job title</Button>*/}
                {/*            </div>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*    <Row>*/}
                {/*        <Col span={24}>*/}
                {/*            <Form.Item name={"task"}*/}
                {/*                       rules={[{required: true, message: 'Please fill you email message'}]}>*/}
                {/*                <ReactQuill theme="snow" modules={richTextModules} formats={richTextFormats}/>*/}
                {/*            </Form.Item>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</div>}*/}


            </Form>
        </Modal>


        {isEdit ? <Button onClick={handleOpen} size={"small"}>Edit</Button> : <Segmented
            style={{marginRight: "10px"}}
            onChange={handleOpen}
            size="large"
            value={null}
            options={[
                {
                    disabled: (type == "AUTO_EMAIL" ? true : false),
                    label: label
                }]}/>}
    </span>)
}

export default GeneralTaskSequenceWidget;

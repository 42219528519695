import React, {useState} from "react";
import {Button, Popconfirm} from "antd";
import {ENV_API_HOST} from "./config";
import {useGlobalState} from "./utils";

function RemoveTeamMember(props) {
    const [token] = useGlobalState('token');
    const {record} = props;
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };

    const handleOk = async () => {
        setConfirmLoading(true);

        console.log("RemoveTeamMember: ", record);

        const postData = {
            "accountId": record.id
        }
        const requestUrl = `${ENV_API_HOST}/api/deleteTeamMember`;

        const responseStream = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });

        await responseStream.json()
        props.onUpdate();
        setOpen(false);
        setConfirmLoading(false);
        // setTimeout(() => {
        // }, 2000);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };


    return( <Popconfirm
        title="Are you sure?"
        open={open}
        onConfirm={handleOk}
        okButtonProps={{ loading: confirmLoading }}
        onCancel={handleCancel}
    >
        <Button onClick={showPopconfirm}>Remove</Button>
    </Popconfirm>)
}

export default RemoveTeamMember;

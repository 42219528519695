import {Avatar, Tag} from "antd";


function AssigneeListWidget(props) {
    let assigneeList = props.data["assignees"];

    let assigneeRenderList = [];
    for (let i = 0; i < assigneeList.length; i++) {
        assigneeRenderList.push(<Tag style={{marginBottom: "2px"}}><Avatar size={16} src={assigneeList[i]["photo"]} />&nbsp;{assigneeList[i]["name"]}</Tag>)
    }

    return(<span>{assigneeRenderList}</span>)
}

export default AssigneeListWidget;

import {Divider, Tag, Typography} from "antd";

function AffilateBillingWidget(props) {
    return(<div style={{marginTop: "6%"}}>
        <Typography.Title style={{marginBottom: "0px"}} level={2}>Affiliate Program</Typography.Title>
        <Divider style={{marginTop: "10px"}} />
        <div>
            <Tag>Coming soon, we will notify you by email.</Tag>
        </div>
    </div>)
}

export default AffilateBillingWidget;

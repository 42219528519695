import {Button, Form, Input, Modal, Select} from "antd";
import React, {useEffect, useState} from "react";
import {ENV_API_HOST} from "../../config";
import {useGlobalState} from "../../utils";

function AssignToSequence(props) {
    const {profileId} = props;
    const [token] = useGlobalState('token');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [sequenceOptionList, setSequenceOptionList] = useState([]);
    const [form] = Form.useForm();
    const showModal = () => {
        setIsModalOpen(true);
    };


    const postSequenceAssigment = async (values) => {
        const postData = {
            "sequenceId": values["sequence"],
            "profileId": profileId
        };

        const requestUrl = `${ENV_API_HOST}/api/postNewSequenceAssigment`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });

        await response.json();
        props.onUpdate();
        form.resetFields();
        handleCancel();
    }

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            postSequenceAssigment(values);
        })
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        getActiveSequenceList()
    }, [])

    const getActiveSequenceList = async () => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getActiveSequenceList`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const sequenceList = await metadataResponse.json();
        let optionList = [];
        for (let i = 0; i < sequenceList.length; i++) {
            optionList.push({
                "value": sequenceList[i]["id"],
                "label": sequenceList[i]["name"]
            })
        }

        setSequenceOptionList(optionList);
        setIsLoading(false);
    }

    return (<span>
            <Modal title="Assignee to a sequence" open={isModalOpen}  onCancel={handleCancel} footer={[
                <Button key="back" onClick={handleCancel}>
                    Return
                </Button>,
                <Button key="submit" type="primary" loading={isSubmitLoading} onClick={handleSubmit}>
                    Submit
                </Button>
            ]}>

                <div>
                    <Form form={form} layout="vertical">
                        <Form.Item
                            label="Sequence:"
                            name="sequence"
                            rules={[{required: true}]}
                        >
                            <Select options={sequenceOptionList} loading={isLoading}/>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
         <Button type={"primary"} onClick={showModal}>Assign to a sequence</Button>
    </span>)
}

export default AssignToSequence;

import dayjs from "dayjs";
import {Tooltip} from "antd";


function CreatedAtWidget(props) {

    const fullFormat = 'DD.MM.YYYY HH:mm'
    const shortFormat = 'DD.MM.YYYY (ddd)';

    // Add timezone offset
    const dateWithOffset = dayjs(props.data["created_at"]).add(
        props.data["date_timezone"],
        'hour'
    );

    // Format the end date
    const endDateFormattedShort = dateWithOffset.format(shortFormat);
    const endDateFormattedFull = dateWithOffset.format(fullFormat);


    return(<span><Tooltip title={endDateFormattedFull}>{endDateFormattedShort}</Tooltip></span>);

}
export default CreatedAtWidget;

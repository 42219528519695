import {useGlobalState} from "./utils";
import React, {useEffect, useState} from "react";
import {ENV_API_HOST} from "./config";
import dayjs from "dayjs";
import {Button, Col, DatePicker, Input, Modal, Radio, Row, Select, Spin, TimePicker} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faEnvelope, faFlag, faListCheck, faPhone, faUserGroup} from "@fortawesome/free-solid-svg-icons";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {GetIconByTaskType} from "./widgets/TaskWidgets";
import {Link} from "react-router-dom";


function TaskEdit(props) {
    const [token] = useGlobalState('token');
    const {taskId, currentTaskType, currentTaskName} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [isTeamLoading, setIsTeamLoading] = useState(true);
    const [teamData, setTeamData] = useState(null);
    const [titlePlaceholder, setTitlePlaceholder] = useState("Call");


    const [taskName, setTaskName] = useState(null);
    const [taskState, setTaskState] = useState(null);
    const [location, setLocation] = useState(null);
    const [taskType, setTaskType] = useState("call");

    const [startDate, setStartDate] = useState(null);
    const [startTime, setStartTime] = useState(null);


    const [endDate, setEndDate] = useState(null);
    const [endDateState, setEndDateState] = useState(null);

    const [endTime, setEndTime] = useState(null);

    const [noteValue, setNoteValue] = useState(null);
    const [assigneeList, setAssigneeList] = useState([]);
    const [assigneeState, setAssigneeState] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);


    const getTaskData = async (taskId) => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getTask?id=${taskId}`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        const task = dataResponse;
        setTaskName(task["name"]);
        setTaskType(task["task_type"]);
        setLocation(task["location"]);
        setNoteValue(task["notes"]);

        let localAssigneeList = [];
        if (task["assignees"].length > 0) {
            for (let i = 0; i < task["assignees"].length; i++) {
                localAssigneeList.push(task["assignees"][i]["account_id"]);
            }
        }

        console.log("localAssigneeList = ", localAssigneeList);
        setAssigneeList(localAssigneeList);

        if (task["start_date_in_utc"] != null) {
            const startFormatDate = formatDateAndTime(task["start_date_in_utc"], task["is_start_time_provided"], task["date_timezone"]);
            setStartDate(startFormatDate["dateFormatted"]);
            setStartTime(startFormatDate["timeDate"]);
        } else {
            setStartDate(null);
            setStartTime(null);
        }


        const endFormatDate = formatDateAndTime(task["end_date_in_utc"], task["is_end_time_provided"], task["date_timezone"]);
        setEndDate(endFormatDate["dateFormatted"]);
        setEndTime(endFormatDate["timeDate"]);

        setIsLoading(false);
    }

    useEffect(() => {
        getTeamData();
        // console.log("render endFormatDate = ", endFormatDate);
    }, [taskId]);


    const formatDateAndTime = (date, is_time_exist, timezone) => {

        // const dateWithOffset = dayjs(props.data["end_date_in_utc"]).add(
        //         props.data["date_timezone"],
        //         'hour'
        //     );

        console.log("formatDateAndTime = ", date, is_time_exist, timezone)

        let dateFormatted = dayjs(date);
        let timeDate = null;
        if (is_time_exist) {
            timeDate = dateFormatted;
        } else {
            timeDate = null;
        }
        return {dateFormatted, timeDate}
    };


    const showModal = () => {
        setIsModalOpen(true);
        getTaskData(taskId);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const getTeamData = async () => {
        setIsTeamLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getTeamData`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();

        let assigneeList = [];
        for (let i = 0; i < dataResponse["memberList"].length; i++) {
            assigneeList.push({
                "value": dataResponse["memberList"][i]["id"],
                "label": `${dataResponse["memberList"][i]["name"]} (${dataResponse["memberList"][i]["email"]})`
            });
        }

        setTeamData(assigneeList)
        setIsTeamLoading(false);
    }

    const handleDateChange = (e, type) => {
        if (type == "startDate") {
            setStartDate(e);
        } else if (type == "endDate") {
            setEndDate(e);

            if (e != null) {
                setEndDateState(null);
            }
        } else if (type == "startTime") {
            setStartTime(e);
        } else if (type == "endTime") {
            setEndTime(e);
        }
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        if (startDate != null) {
            return current < startDate;
        } else {
            return null;
        }
    };

    const handleTitleChange = (e) => {
        setTaskName(e.target.value);

        if (e.target.value.length >= 1) {
            setTaskState(null);
        }
    }

    const handleLocationData = (e) => {
        setLocation(e.target.value);
    }

    const handleAssigneeList = (e) => {
        setAssigneeList(e);

        if (e.length > 0) {
            setAssigneeState(null);
        }
    }

    const handleNotesValue = (e) => {
        setNoteValue(e.target.value);
    }

    const handleChangeType = (e) => {
        if (e.target.value == "call") {
            setTitlePlaceholder("Call");
        } else if (e.target.value == "email") {
            setTitlePlaceholder("Email");
        } else if (e.target.value == "linkedin") {
            setTitlePlaceholder("LinkedIn Activity");
        } else if (e.target.value == "qualification") {
            setTitlePlaceholder("Qualification");
        } else if (e.target.value == "task") {
            setTitlePlaceholder("Task");
        } else if (e.target.value == "deadline") {
            setTitlePlaceholder("Deadline");
        } else if (e.target.value == "meeting") {
            setTitlePlaceholder("Meeting");
        }
        setTaskType(e.target.value);
    }


    const handleCreate = () => {
        let isErrorExist = false;
        if (taskName == null || taskName != null && taskName.length < 1) {
            setTaskState("error");
            isErrorExist = true;
        } else {
            setTaskState(null);
        }

        if (endDate == null) {
            isErrorExist = true;
            setEndDateState("error");
        } else {
            setEndDateState(null);
        }

        if (!isErrorExist) {
            sendTaskData();
        }

    }


    const getCurrentTimezoneOffset = () => {
        const offsetInMinutes = new Date().getTimezoneOffset();
        const hours = -offsetInMinutes / 60;
        return hours
    }

    const sendTaskData = async () => {
        if (taskState || assigneeState || endDateState) {
            return;
        }

        const timezoneOffset = getCurrentTimezoneOffset();
        console.log("const tZone = dayjs.tz().guess(); = ", timezoneOffset, startDate, startTime);

        let startDateTime = null;
        let startDateTimeOnlyDate = false;
        if (startDate && startTime) {
            startDateTime = dayjs(`${startDate.format('YYYY-MM-DD')} ${startTime.format('HH:mm')}`);
            startDateTime = startDateTime.toISOString();
        } else if (startDate) {
            startDateTime = startDate.toISOString();
            startDateTimeOnlyDate = true;
        }

        let endDateTime = null;
        let endDateTimeOnlyDate = false;
        if (endDate && endTime) {
            endDateTime = dayjs(`${endDate.format('YYYY-MM-DD')} ${endTime.format('HH:mm')}`);
            endDateTime = endDateTime.toISOString();
        } else if (endDate) {
            endDateTime = endDate.toISOString();
            endDateTimeOnlyDate = true;
        }


        const requestData = {
            "id": taskId,
            taskName,
            taskType,
            startDateTime,
            startDateTimeOnlyDate,
            endDateTime,
            endDateTimeOnlyDate,
            timezoneOffset,
            location,
            noteValue,
            assigneeList,
        };

        const response = await fetch(`${ENV_API_HOST}/api/updateTask`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(requestData),
        });

        const result = await response.json();

        handleOk();
        props.onUpdate();
        // if (result.success) {
        //     handleOk();
        // } else {
        //     // toast.error('Error creating task');
        // }
    };



    let renderData = null;
    if (isLoading) {
        renderData = <div style={{textAlign: "center", paddingTop: "100px", paddingBottom: "100px"}}>
            <Spin tip="Loading" size="large">
                <div className="content"/>
            </Spin>
        </div>;
    } else {
        renderData = <div>
            <Row>

                <Col span={24}>
                    <div>
                        <Input status={taskState} placeholder={titlePlaceholder} value={taskName}
                               onChange={handleTitleChange}/>
                    </div>
                    <div style={{marginTop: "10px"}}>
                        <Radio.Group defaultValue="call" value={taskType} onChange={handleChangeType}>
                            <Radio.Button value="call">
                                {/*<Tooltip title={"Phone call"}>*/}
                                <FontAwesomeIcon icon={faPhone}/>
                                {/*</Tooltip>*/}
                            </Radio.Button>
                            <Radio.Button value="email">
                                <FontAwesomeIcon icon={faEnvelope}/>
                            </Radio.Button>
                            <Radio.Button value={"linkedin"}>
                                <FontAwesomeIcon icon={faLinkedinIn}/>
                            </Radio.Button>
                            <Radio.Button value="meeting">
                                <FontAwesomeIcon icon={faUserGroup}/>
                            </Radio.Button>
                            <Radio.Button value="qualification">
                                <FontAwesomeIcon icon={faListCheck}/>
                            </Radio.Button>
                            <Radio.Button value="task">
                                <FontAwesomeIcon icon={faClock}/>
                            </Radio.Button>
                            <Radio.Button value="deadline">
                                <FontAwesomeIcon icon={faFlag}/>
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                </Col>
            </Row>

            <Row style={{marginTop: "20px"}}>
                <Col span={24}>
                    <div style={{marginBottom: "5px"}}>Dates:</div>

                    <DatePicker value={startDate}
                                onChange={(e) => handleDateChange(e, "startDate")}
                    /> &nbsp;

                    <TimePicker minuteStep={5} value={startTime} onChange={(e) => handleDateChange(e, "startTime")}
                                format={'HH:mm'}
                                defaultOpenValue={dayjs('00:00', 'HH:mm')}/>&nbsp;

                    <span style={{paddingRight: "5px", paddingLeft: "5px"}}>—</span>&nbsp;

                    <DatePicker status={endDateState} value={endDate}
                                onChange={(e) => handleDateChange(e, "endDate")}
                                disabledDate={disabledDate}/>&nbsp;

                    <TimePicker minuteStep={5} value={endTime} onChange={(e) => handleDateChange(e, "endTime")}
                                format={'HH:mm'}
                                defaultOpenValue={dayjs('00:00', 'HH:mm')}/>

                </Col>
            </Row>


            <Row style={{marginTop: "20px"}}>
                <Col span={24}>
                    <div style={{marginBottom: "5px"}}>Location:</div>
                    <Input value={location} onChange={handleLocationData}/>
                </Col>
            </Row>


            <Row style={{marginTop: "20px"}}>

                <Col span={24}>
                    <div style={{marginBottom: "5px"}}>Notes:</div>
                    <Input.TextArea  autoSize={{ minRows: 2 }} value={noteValue} onChange={handleNotesValue} />
                </Col>
            </Row>

            <Row style={{marginTop: "20px"}}>

                <Col span={24}>
                    <div style={{marginBottom: "5px"}}>Assignees:</div>
                    <Select
                        mode="multiple"
                        placeholder="Choose assignees"
                        loading={isTeamLoading}
                        options={teamData}
                        status={assigneeState}
                        value={assigneeList}
                        onChange={handleAssigneeList}
                        style={{width: '100%'}}
                    />
                </Col>
            </Row>
        </div>
    }

    return (
        <span>
            <Modal width={700} title="Edit a task" open={isModalOpen} onOk={handleCreate} onCancel={handleCancel}>
                {renderData}
            </Modal>
            <Link onClick={showModal}><span>{GetIconByTaskType(currentTaskType)} {currentTaskName}</span></Link>
        </span>
    )
}

export default TaskEdit;

import React from "react";
import {Button, Popconfirm} from "antd";
import {ENV_API_HOST} from "./config";
import {useGlobalState} from "./utils";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";


function ArhiveWidget(props) {
    const [token] = useGlobalState('token');

    const confirm = async () => {
        const profileId = props.person["profile"]["id"];

        const requestData = {
            "profileId": profileId
        }

        const requestUrl = `${ENV_API_HOST}/api/archiveProfile`;
        const metadataResponse = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestData)
        });
        await metadataResponse.json();
        props.onUpdate();

    }

    return(<span>
        <Popconfirm
            title="Archive"
            placement="left"
            description="Are you sure you want to archive this profile?"
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
        >
            <Button type={"link"} danger><FontAwesomeIcon icon={faTrash} /></Button>
        </Popconfirm>
    </span>)
}

export default ArhiveWidget

import {Tag} from "antd";
import React from "react";
import {CheckCircleOutlined, ClockCircleOutlined, MinusCircleOutlined} from "@ant-design/icons";
import {faLinkedinIn, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";


function ConnectionStatusWidget(props) {

    const {person} = props;

    let linkedinStateRender =  <Tag icon={<ClockCircleOutlined />} color="red">
        <FontAwesomeIcon icon={faLinkedinIn}/>
    </Tag>;

    if (person["profile"]["linkedin_connection_status"] == "CONNECTED") {
        linkedinStateRender = <Tag icon={<CheckCircleOutlined/>} color="success">
            <FontAwesomeIcon icon={faLinkedinIn}/>
        </Tag>;
    } else if(person["profile"]["linkedin_connection_status"] == "NOT_ESTABLISHED") {
        linkedinStateRender = <Tag icon={<MinusCircleOutlined/>} color="default">
            <FontAwesomeIcon icon={faLinkedinIn}/>
        </Tag>;
    }


    let emailStateRender = <Tag icon={<MinusCircleOutlined/>} color="default">
        <FontAwesomeIcon icon={faEnvelope}/>
    </Tag>;

    if (person["profile"]["email_connection_state"] == "CONNECTED") {
        emailStateRender = <Tag icon={<CheckCircleOutlined/>} color="success">
            <FontAwesomeIcon icon={faEnvelope}/>
        </Tag>;
    } else if (person["profile"]["email_connection_state"] == "NOT_CONNECTED") {
        emailStateRender = <Tag icon={<ClockCircleOutlined />} color="red">
            <FontAwesomeIcon icon={faEnvelope}/>
        </Tag>;
    }


    return (<span>{linkedinStateRender}{emailStateRender}</span>)
}

export default ConnectionStatusWidget;

import {Col, Divider, Empty, Row, Spin, Typography} from "antd";
import "./App.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {ENV_API_HOST} from "./config";
import React, {useEffect, useState} from "react";
import {useGlobalState} from "./utils";
import ActivityLogWidget from "./widgets/ActivityLogWidget";
function ActivityList(props) {
    const {userId, personProfile, updateTicket} = props;
    const [token] = useGlobalState('token');
    const [isLoadingEvent, setIsLoadingEvent] = useState(true);
    const [isLoadingTeamData, setIsLoadingTeamData] = useState(true);
    const [teamDataMap, setTeamData] = useState({});
    const [eventMap, setEventMap] = useState(null);

    async function getTeamData() {
        setIsLoadingTeamData(true);
        const requestUrl = `${ENV_API_HOST}/api/getTeamData`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const teamDataResponse = await metadataResponse.json();
        let teamDataMap = {};
        for (let i = 0; i < teamDataResponse["memberList"].length; i++) {
            teamDataMap[teamDataResponse["memberList"][i]["id"]] = teamDataResponse["memberList"][i];
        }
        setTeamData(teamDataMap);
        setIsLoadingTeamData(false);
    }

    const getEventList = async () => {
        setIsLoadingEvent(true);
        const requestUrl = `${ENV_API_HOST}/api/getEventListById?id=${userId}`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();

        // let eventMap = {}
        // for (let i = 0; i < dataResponse.length; i++) {
        //     eventMap[dataResponse[i]["key"]] = dataResponse[i]["value"];
        // }

        setEventMap(dataResponse);
        setIsLoadingEvent(false);
    }


    useEffect(() => {
        getEventList();
        getTeamData();
    }, [userId])


    useEffect(() => {
        getEventList();
    }, [updateTicket]);

        const handleUpdateFromWidget = () => {
      getEventList();
    }

    if (isLoadingEvent || isLoadingTeamData) {
        return(<div style={{textAlign: "center", paddingTop: "100px", paddingBottom: "100px"}}>
            <Spin tip="Loading activities ..." size="large">
                <div className="content"/>
            </Spin>
        </div>);
    }


    let eventKeyList = Object.keys(eventMap).map(Number).sort((a, b) => b - a);
    let eventRenderList = [];

    for (let i = 0; i < eventKeyList.length; i++) {

        let eventForDateList = eventMap[eventKeyList[i]];
        let currentDate = null;
        let renderEventForDateList = [];
        for (let j = 0; j < eventForDateList.length; j++) {
            let eventData =  eventForDateList[j];
            currentDate = eventData["eventDateDay"]
            renderEventForDateList.push(<div>
                <ActivityLogWidget
                    userId={userId}
                    eventData={eventData}
                    teamMap={teamDataMap}
                    personProfile={personProfile}
                    onUpdate={handleUpdateFromWidget}
                />
            </div>)
        }


        eventRenderList.push(<div>
            <Typography.Title level={3} style={{marginBottom: "2px"}}>{currentDate}</Typography.Title>
            <Divider style={{marginTop: "5px"}} />

            {renderEventForDateList}


        </div>)

    }

    return(<span>{eventRenderList.length > 0 ? eventRenderList : <Empty style={{marginTop: "100px", marginBottom: "100px"}} />}</span>)
}

export default ActivityList;


import React, {Fragment, useEffect, useState} from "react";
import LogoutButton from "./components/LogoutButton";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";




function Home() {
    const { user, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
    const [userMetadata, setUserMetadata] = useState(null);

    const nav = useNavigate();

    useEffect(() => {
        nav(`/connection`);
    }, [])

    // useEffect(() => {
    //     const getUserMetadata = async () => {
    //         console.log("getUserMetadata run");
    //         const domain = "https://scopemoneyapp.eu.auth0.com/";
    //
    //         try {
    //             const accessToken = await getAccessTokenSilently();
    //             console.log("AT:", accessToken);
    //             const userDetailsByIdUrl = `http://localhost:3010/api/private`;
    //
    //             const metadataResponse = await fetch(userDetailsByIdUrl, {
    //                 headers: {
    //                     Authorization: `Bearer ${accessToken}`,
    //                 },
    //             });
    //
    //             const { user_metadata } = await metadataResponse.json();
    //
    //             setUserMetadata(user_metadata);
    //         } catch (e) {
    //             console.log(e.message);
    //         }
    //     };
    //
    //     getUserMetadata();
    // }, [getAccessTokenSilently, user?.sub]);


    return (
        <div>
            <h2>Home page</h2>
            {!isLoading ? <div>
                <img src={user.picture} alt={user.name} />
                <h2>{user.name}</h2>
                <p>{user.email}</p>

                <p>token:</p>

                <h3>User Metadata</h3>
                {userMetadata ? (
                    <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
                ) : (
                    "No user metadata defined"
                )}

            </div> : null }


        </div>
    );
}


export default Home;

import {Button, Col, Layout, List, Row, Spin, Typography} from "antd";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useGlobalState} from "./utils";
import React, {useEffect, useState} from "react";
import NewPurposeItem from "./NewPurposeItem";
import EditPurposeItem from "./EditPurposeItem";
import {ENV_API_HOST} from "./config";

const {Title} = Typography;
const {Content} = Layout;

function PurposeProductList(props) {
    const [token] = useGlobalState('token');
    const [purposeList, setPurposeList] = useState([]);
    const [isPurposeListLoading, setIsPurposeListLoading] = useState(false);

    const [archiveList, setArchiveList] = useState([]);
    const [isArchiveListLoading, setIsArchiveListLoading] = useState(false);


    const nav = useNavigate();


    useEffect(() => {
        getPurposeList();
        getPurposeArchiveList();
    }, [props.product?.id])


    const getPurposeArchiveList = async () => {
        setIsArchiveListLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getCompanyArchivedPurposeListForProduct?id=${props.product.id}`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const purposeList = await metadataResponse.json();
        console.log("purposeList = ", purposeList);
        setArchiveList(purposeList);
        setIsArchiveListLoading(false);

    }

    const getPurposeList = async () => {
        setIsPurposeListLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getPurposeListForProduct?id=${props.product.id}`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const purposeList = await metadataResponse.json();
        console.log("purposeList = ", purposeList);
        setPurposeList(purposeList);
        setIsPurposeListLoading(false);

    }


    const handleUpdateList = () => {
      getPurposeList();
      getPurposeArchiveList();
    }

    const handleNewPurpose = () => {
        nav(`/purpose/new/${props.product.id}`)
    }


    return (<div style={{paddingLeft: "10px"}}>
        <Row style={{display: "flex", alignItems: "center"}}>
            <Col span={12}>
                <Title level={3}>Purposes</Title>
            </Col>
            <Col span={12} style={{textAlign: "right"}}>
                <Button onClick={handleNewPurpose}>New purposes</Button>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Content style={{padding: 15, background: "white"}}>
                    <div>
                        {isPurposeListLoading || isArchiveListLoading ?
                            <div style={{marginTop: "40%", marginBottom: "40%", textAlign: "center"}}><Spin
                                tip="Loading" size="large"/></div> : <span>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={purposeList}
                                        renderItem={(item) => (
                                            <EditPurposeItem item={item} onUpdate={handleUpdateList}/>
                                        )}
                                    />

                                {archiveList.length > 0 ? <span>
                                    <Title level={4}>Archived</Title>

                                    <List
                                        itemLayout="horizontal"
                                        dataSource={archiveList}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <span>{item.purpose}</span>
                                            </List.Item>
                                        )}
                                    />
                                </span> : null}

                                </span>}
                    </div>
                </Content>
            </Col>
        </Row>
    </div>)
}

export default PurposeProductList;

import {Button, Col, Modal, Row, Tag} from "antd";
import React, {useState} from "react";
import EmailMatchResultWidget from "./matching/EmailMatchResultWidget";
import {SyncOutlined} from "@ant-design/icons";
import {ENV_API_HOST} from "../config";
import {useGlobalState} from "../utils";

function DEPRICATED_EmailAddAsConnectionWidget(props) {
    const {data} = props;
    const [token] = useGlobalState('token');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updatedValues, setUpdatedValues] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const handleSubmit = async () => {
        let keyList = Object.keys(updatedValues);

        let returnList = [];
        for (let i = 0; i < keyList.length; i++) {
            returnList.push({"emailAddressId": keyList[i], "profileId": updatedValues[keyList[i]]})
        }

        const requestUrl = `${ENV_API_HOST}/api/postEmailConnectionWithProfile`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(returnList)
        });
        const dataResponse = await response.json();

    }

    const handleUpdateMatch = (emailAddressId, profileId) => {
        var newValues = structuredClone(updatedValues);
        newValues[emailAddressId] = profileId;
        setUpdatedValues(newValues);
    }

    let emailList = data["from_list"];
    if (data["category"] === "SENT") {
        emailList = data["to_list"];
    }


    let renderList = [];

    for (let i = 0; i < emailList.length; i++) {
        let emailAddress = emailList[i];

        var emailTitle = emailAddress["email"];
        if (emailAddress["email_name"] != null && emailAddress["email_name"].length > 1) {
            emailTitle = <span>{emailAddress["email_name"]} [{emailAddress["email"]}] </span>
        }

        var matchingState = emailAddress["matching_state"];

        if(matchingState == "SUCCESS" || matchingState == "NEAR_MATCH" || matchingState == "NO_LINKEDIN") {
            matchingState = <EmailMatchResultWidget emailAddress={emailAddress} onUpdate={handleUpdateMatch} />
        } else {
            matchingState =<div style={{textAlign: "right"}}> <Tag icon={<SyncOutlined spin />} color="processing">
                Matching in process
            </Tag></div>
        }

        renderList.push(<Row>
            <Col span={12}>{emailTitle}</Col>
            <Col span={12}>{matchingState}</Col>
        </Row>)


    }


    return (<div style={{textAlign: "right"}}>
        <Modal title="Email attachment to profile" open={isModalOpen}
               footer={[
                   <Button key="back" onClick={handleCancel}>
                       Return
                   </Button>,
                   <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
                       Submit
                   </Button>
               ]}
        >
            {renderList}
        </Modal>
        <Button type={"primary"} onClick={showModal}>Attach email to profile</Button>
    </div>)
}

export default DEPRICATED_EmailAddAsConnectionWidget;

import {Avatar, Button} from "antd";
import React from "react";
import {Link} from "react-router-dom";

function PersonDataWidget(props) {
    return (<Link type={"link"}
                    to={`/user/${props.person["profile"]["id"]}/activity`}><Avatar
        src={props.person.photo}/>&nbsp; {props.person.name}</Link>)
}

export default PersonDataWidget;

import {useGlobalState} from "./utils";
import {Button} from "antd";
import React, {Fragment, useEffect, useState} from "react";
import Billing from "./Billing";
import {ENV_API_HOST} from "./config";

function BillingPortal(props) {

    const [userAuthData] = useGlobalState('userAuthData');
    const [token] = useGlobalState("token");
    const [isLoading, setIsLoading] = useState(false);

    const createPortalLink = async () => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/createCustomerPortalUrl`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const redirectUrl = await metadataResponse.json();
        console.log("[Billing.js:ProductDisplay] responseData = ", redirectUrl);
        window.location.replace(redirectUrl);
        setIsLoading(false);
    }

    console.log("[Billing.js:BillingPortal] userAuthData = ", userAuthData)

    return (<section>
        <Button onClick={() => createPortalLink()} loading={isLoading}>Open Your Billing Portal</Button>
    </section>)
}

export default BillingPortal;

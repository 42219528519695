import React, {useEffect, useState} from "react";
import {ENV_API_HOST} from "./config";
import {useGlobalState} from "./utils";
import {Spin, Table} from "antd";
import ConnectionStatusWidget from "./widgets/ConnectionStatusWidget";
import DueDateWidget from "./widgets/DueDateWidget";
import AssigneeListWidget from "./widgets/AssigneeListWidget";
import LocationRendererWidget from "./widgets/LocationRenderWidget";
import LocationRenderWidget from "./widgets/LocationRenderWidget";
import CreatedAtWidget from "./widgets/CreatedAtWidget";
import {GetIconByTaskType} from "./widgets/TaskWidgets";
import TaskEdit from "./TaskEdit";
import TaskListActionWidget from "./widgets/TaskListActionWidget";
import TaskDone from "./widgets/TaskDone";


function TaskList(props) {
    const [token] = useGlobalState('token');
    const [isLoading, setIsLoading] = useState(true);
    const [taskData, setTaskData] = useState([]);


    const getTaskList = async () => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getTaskListForLeadPerson?id=${props.profileId}`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        setTaskData(dataResponse);
        setIsLoading(false);
    }

    useEffect(() => {
        getTaskList();
    }, [props?.profileId, props?.updateTicket])


    const handleUpdateList = () => {
        getTaskList();
    }

    const taskColumns = [
        {
            title: 'Done',
            key: 'done',
            render: (task) => <TaskDone
                taskId={task["id"]}
                currentState={task["is_done"]}
                onUpdate={() => handleUpdateList()}
            />,
        },{
            title: 'Subject',
            key: 'subject',
            render: (task) => <TaskEdit
                taskId={task["id"]}
                currentTaskType={task["task_type"]}
                currentTaskName={task["name"]}
                onUpdate={() => handleUpdateList()}
            />,
        },
        {
            title: 'Due Date',
            key: 'dueDate',
            width: '17%',
            render: (task) => <span><DueDateWidget data={task} /></span>,
        },
        {
            title: 'Assignees',
            key: 'assignees',
            width: '20%',
            render: (task) => <span><AssigneeListWidget data={task} /></span>,
        },
        {
            title: 'Location',
            key: 'location',
            width: '17%',
            render: (task) => <span><LocationRenderWidget data={task["location"]}/></span>,
        },
        {
            title: 'Created Date',
            key: 'created_date',
            width: '17%',
            render: (task) => <span><CreatedAtWidget data={task} /></span>,
        },
        {
            title: '',
            key: 'actions',
            width: '3%',
            render: (task) => <span><TaskListActionWidget data={task} onUpdate={handleUpdateList} /></span>,
        }
    ]

    if(isLoading) {
        return(<Spin tip="Loading tasks ..." size="large">
            <div className="content"/>
        </Spin>)
    }

    return(<div>
        <Table columns={taskColumns} dataSource={taskData} />
    </div>)
}

export default TaskList;

import React, {Fragment, useEffect, useState} from "react";
import {
    Button,
    Col, Input,
    Layout, Row, Radio,
    List, Form, Select, Switch, DatePicker, Typography
} from 'antd';
import {useSearchParams} from "react-router-dom";
import {useGlobalState} from "../../utils";
import dayjs from 'dayjs';
import {ENV_API_HOST} from "../../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faListCheck} from "@fortawesome/free-solid-svg-icons";


const {Content} = Layout;
const {RangePicker} = DatePicker;
const { Title } = Typography;


function FilterContactList(props) {
    const [token] = useGlobalState('token');
    const [fields, setFields] = useState([]);

    const [labelList, setLabelList] = useState([]);
    const [isLoadingLabelList, setIsLoadingLabelList] = useState(false);

    const [productList, setProductList] = useState([]);
    const [isProductListLoading, setIsProductListLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [purposeList, setPurposeList] = useState([]);
    const [isPurposeListLoading, setIsPurposeListLoading] = useState(false);


    useEffect(() => {
        getPurposeList();
        getProductList();
        getLabelList();

        let fieldData = [];

        console.log("[FilterContactList.js] searchDataKeys = ", props.searchData);
        let searchDataKeys = Object.keys(props.searchData);
        let lastActionData = [];
        let createdAtData = [];
        if (searchDataKeys.length > 0) {
            for (let i = 0; i < searchDataKeys.length; i++) {
                if (["last-action_start", "last-action_end", "created-at_start", "created-at_end"].indexOf(searchDataKeys[i]) != -1) {
                    let dateValue = props.searchData[searchDataKeys[i]];
                    let formattedValue = dayjs(dateValue, 'YYYY-MM-DD');
                    if (searchDataKeys[i] == "last-action_start") {
                        lastActionData.push(formattedValue);
                    } else if (searchDataKeys[i] == "last-action_end") {
                        lastActionData.push(formattedValue);
                    } else if (searchDataKeys[i] == "created-at_start") {
                        createdAtData.push(formattedValue);
                    } else if (searchDataKeys[i] == "created-at_end") {
                        createdAtData.push(formattedValue);
                    }
                } else if (searchDataKeys[i] == "labels") {
                    let rawData = props.searchData[searchDataKeys[i]];
                    let splitedData = rawData.split(",");
                    let finalLabelData = [];
                    for (let j = 0; j < splitedData.length; j++) {
                        finalLabelData.push(parseInt(splitedData[j]));
                    }
                    fieldData.push({
                        name: searchDataKeys[i],
                        value: finalLabelData,
                    });
                } else if (["is-recipient-answers-exist", "is_company_present_for_lead_person"].indexOf(searchDataKeys[i]) != -1) {
                    fieldData.push({
                        name: searchDataKeys[i],
                        value: props.searchData[searchDataKeys[i]] == "true",
                    });
                } else {
                    fieldData.push({
                        name: searchDataKeys[i],
                        value: props.searchData[searchDataKeys[i]],
                    })
                }
            }
        }
        fieldData.push({
            name: "last-action",
            value: lastActionData,
        });
        fieldData.push({
            name: "created-at",
            value: createdAtData,
        });
        console.log("[FilterContactList.js] fieldData = ", fieldData);
        setFields(fieldData);
    }, [])


    const getPurposeList = async () => {
        setIsPurposeListLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getCompanyPurposeList`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const dataResponse = await metadataResponse.json();
        console.log("productList = ", dataResponse);
        let selectList = [];
        for (let i = 0; i < dataResponse.length; i++) {
            selectList.push({value: dataResponse[i]["id"], label: dataResponse[i]["purpose"]})
        }
        setPurposeList(selectList);
        setIsPurposeListLoading(false);
    }

    const getProductList = async () => {
        setIsProductListLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getCompanyProductList`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const dataResponse = await metadataResponse.json();
        console.log("productList = ", dataResponse);
        let selectList = [];
        for (let i = 0; i < dataResponse.length; i++) {
            selectList.push({value: dataResponse[i]["id"], label: dataResponse[i]["name"]})
        }
        setProductList(selectList);
        setIsProductListLoading(false);
    };

    const getLabelList = async () => {
        setIsLoadingLabelList(true);
        const requestUrl = `${ENV_API_HOST}/api/getCompanyLabelList`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        console.log("getLabelList = ", dataResponse);

        let selectList = [];
        for (let i = 0; i < dataResponse.length; i++) {
            selectList.push({value: dataResponse[i]["id"], label: dataResponse[i]["name"]})
        }
        setLabelList(selectList);
        setIsLoadingLabelList(false);
    }

    let finalFilter = {};

    const handleFinishEvent = (filterValues) => {
        console.log("handleFinishEvent: init values = ", filterValues);
        let keyList = Object.keys(filterValues);
        for (let i = 0; i < keyList.length; i++) {
            if (filterValues[keyList[i]] != undefined) {
                if (["last-action", "created-at"].indexOf(keyList[i]) != -1) {

                    if (filterValues[keyList[i]].length > 0) {
                        let startValue = filterValues[keyList[i]][0]
                        // startValue = startValue.minute(0);
                        // startValue = startValue.hour(0);
                        // startValue = startValue.second(0);
                        startValue = startValue.format('YYYY-MM-DD');

                        let endValue = filterValues[keyList[i]][1];
                        // endValue = endValue.minute(0);
                        // endValue = endValue.hour(0);
                        // endValue = endValue.second(0);
                        endValue = endValue.format('YYYY-MM-DD');

                        finalFilter[`${keyList[i]}_start`] = startValue;
                        finalFilter[`${keyList[i]}_end`] = endValue;
                    }
                } else if(["is_company_present_for_lead_person", "is-recipient-answers-exist"].indexOf(keyList[i]) != -1) {
                    if (filterValues[keyList[i]]) {
                        finalFilter[keyList[i]] = filterValues[keyList[i]];
                    }
                } else {
                    if (filterValues[keyList[i]].length > 0) {
                        finalFilter[keyList[i]] = filterValues[keyList[i]];
                    }
                }
            }
        }
        console.log("handleFinishEvent: values = ", filterValues, finalFilter);
        props.onUpdate(finalFilter);
    }


    const handleProductChange = (value) => {
        console.log("handleProductChange=", value);
        if (value == undefined) {
            setSelectedProduct(null);
        } else {
            setSelectedProduct(value);
        }
    }

    return (
        <Form layout="vertical" onFinish={handleFinishEvent} fields={fields}>
            <Row>
                <Col span={24}>
                    <Content
                        style={{
                            padding: 12,
                            margin: 0,
                            background: "white",
                        }}
                    >

                        <Title level={4} style={{marginTop: "0px"}}>Filter</Title>

                        <Row>
                            <Col span={6}>
                                <Form.Item
                                    style={{marginBottom: "10px"}}
                                    label="Name:"
                                    name="lead_name">
                                    <Input/>
                                </Form.Item>



                                {/*<Form.Item name="is_company_present_for_lead_person"*/}
                                {/*           label="Is present company for filtered people:"*/}
                                {/*           valuePropName="checked">*/}
                                {/*    <Switch style={{marginTop: "-10px"}}/>*/}
                                {/*</Form.Item>*/}
                            </Col>
                            <Col span={5} style={{marginLeft: "20px"}}>
                                <Form.Item
                                    style={{marginBottom: "10px"}}
                                    label="Labels:"
                                    name="labels">
                                    <Select mode='multiple' maxTagCount='responsive' options={labelList} allowClear
                                            loading={isLoadingLabelList}></Select>
                                </Form.Item>


                                {/*<Form.Item*/}
                                {/*    style={{marginBottom: "10px"}}*/}
                                {/*    label="Company:"*/}
                                {/*    name="lead_company">*/}
                                {/*    <Select disabled placeholder={"coming soon ..."}></Select>*/}
                                {/*</Form.Item>*/}

                                {/*<Form.Item*/}
                                {/*    style={{marginBottom: "10px"}}*/}
                                {/*    label="Product:"*/}
                                {/*    name="product">*/}
                                {/*    <Select disabled={true} placeholder={"coming soon ..."} loading={isProductListLoading} options={productList} allowClear*/}
                                {/*            onChange={handleProductChange}/>*/}
                                {/*</Form.Item>*/}

                                {/*<Form.Item*/}
                                {/*    style={{marginBottom: "10px"}}*/}
                                {/*    label="Proposal:"*/}
                                {/*    name="proposal">*/}
                                {/*    <Select*/}
                                {/*        showSearch*/}
                                {/*        allowClear*/}
                                {/*        options={purposeList}*/}
                                {/*        placeholder={"coming soon ..."}*/}
                                {/*        disabled={true}*/}
                                {/*        filterOption={(input, option) =>*/}
                                {/*            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())*/}
                                {/*        }*/}
                                {/*        loading={isPurposeListLoading}*/}
                                {/*    ></Select>*/}
                                {/*</Form.Item>*/}

                                {/*<Form.Item*/}
                                {/*    label="Chat have answers from recipient:"*/}
                                {/*    name="is-recipient-answers-exist"*/}
                                {/*    valuePropName="checked"*/}
                                {/*>*/}
                                {/*    <Switch style={{marginTop: "-10px"}}/>*/}
                                {/*</Form.Item>*/}

                            </Col>


                            <Col span={6} style={{marginLeft: "20px"}}>
                                <Form.Item name="created-at" label="Created date:">
                                    <RangePicker style={{width: "100%"}}/>
                                </Form.Item>



                                {/*<Form.Item label="Funnel state:" style={{marginBottom: "10px"}}>*/}
                                {/*    <Input.Group compact>*/}
                                {/*        <Form.Item*/}
                                {/*            name={'funnel_condition'}*/}
                                {/*            noStyle*/}
                                {/*        >*/}
                                {/*            <Select style={{width: "50%"}} name={"funnel_condition"} allowClear*/}
                                {/*                    placeholder="Select a condition"*/}
                                {/*                    options={[*/}
                                {/*                        {value: 'equal', label: 'Equal'},*/}
                                {/*                        {value: 'less', label: 'Less than'},*/}
                                {/*                        {value: 'less-equal', label: 'Less than or equal'},*/}
                                {/*                        {value: 'disabled', label: 'Disabled', disabled: true},*/}
                                {/*                    ]}></Select>*/}
                                {/*        </Form.Item>*/}
                                {/*        <Form.Item*/}
                                {/*            name={'funnel_state'}*/}
                                {/*            noStyle*/}
                                {/*        >*/}
                                {/*            <Select name={"funnel_state"} style={{width: "50%"}} allowClear*/}
                                {/*                    placeholder="Funnel state" options={[*/}
                                {/*                {value: 'view-page', label: 'View page'},*/}
                                {/*                {value: 'connected', label: 'Connected'},*/}
                                {/*                {value: 'replied', label: 'Replied'},*/}
                                {/*                {value: 'reply2_noanswer', label: 'Have 2 replies / No last answer'},*/}
                                {/*                {value: 'reply2_answer', label: 'Have 2 replies / Have last asnwer'},*/}
                                {/*            ]}></Select>*/}
                                {/*        </Form.Item>*/}
                                {/*    </Input.Group>*/}
                                {/*</Form.Item>*/}

                                {/*<Form.Item name="last-action" label="Last action:" style={{marginBottom: "10px"}}>*/}
                                {/*    <RangePicker style={{width: "100%"}}/>*/}
                                {/*</Form.Item>*/}

                                {/*<Form.Item name="amount_of_unanswered_message"*/}
                                {/*           label="Current amount of unanswered sequence message:">*/}
                                {/*    <Input addonBefore="Greater than or equal: "  />*/}
                                {/*</Form.Item>*/}


                                {/*<div style={{textAlign: "right", marginTop: "24px"}}>*/}
                                {/*    <Button htmlType="submit" >*/}
                                {/*        <FontAwesomeIcon icon={faListCheck} />&nbsp; Save as a list*/}
                                {/*    </Button>*/}

                                {/*</div>*/}

                            </Col>

                            <Col span={5} style={{marginLeft: "20px"}}>



                                <Form.Item style={{marginTop: "30px"}}>
                                    <Button type="primary" htmlType="submit" block>
                                        Filter
                                    </Button>
                                </Form.Item>


                            </Col>
                        </Row>

                    </Content>
                </Col>
            </Row>
        </Form>

    );
}

export default FilterContactList;

import React, {Fragment, useEffect, useState} from "react";
import {Avatar, Button, Col, Form, Input, Layout, Row, Select, Typography} from "antd";
import {Divider} from "antd/lib";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import ConnectEmail from "./widgets/ConnectEmail";
import ProfileConnectedAccountWidget from "./widgets/ProfileConnectedAccountWidget";
import {useGlobalState} from "./utils";
import {ENV_API_HOST} from "./config";

const {Header, Content, Sider} = Layout;

function Profile(props) {
    const {accountData} = props;
    const [form] = Form.useForm();
    const [token] = useGlobalState('token');
    const [isLoadingTZ, setIsLoadingTZ] = useState(true);
    const [timezoneList, setTimezoneList] = useState([]);
    const [isSavingPersonData, setIsSavingPersonData] = useState(false);

    const getTimezoneList = async () => {
        setIsLoadingTZ(true);
        const requestUrl = `${ENV_API_HOST}/api/getTimezoneList`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        let locTimezoneList = [];
        for (let i = 0; i < dataResponse.length; i++) {
            locTimezoneList.push({
                value: dataResponse[i],
                label: dataResponse[i]
            })
        }

        setTimezoneList(locTimezoneList);
        setIsLoadingTZ(false);
    }

    useEffect(() => {
        getTimezoneList();
    }, [])


    const handleSavePersonData = async (values) => {
        console.log("handleSavePersonData = ", values);
        setIsSavingPersonData(true);
        const requestUrl = `${ENV_API_HOST}/api/updateAccountProfile`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values)
        });
        await response.json();
        setIsSavingPersonData(false);
    }

    return (
        <Content style={{
                padding: 48, margin: 0, minHeight: 100, background: "white",
            }}>

            <Form
                layout="vertical"
                form={form}
                initialValues={accountData}
                onFinish={handleSavePersonData}
            >

            <Row>
                <Col xs={{ span: 7}} md={{span: 5}} lg={{ span: 3}}>
                    <Avatar size={128} src={accountData["photo"]} />
                </Col>
                <Col xs={{ span: 17}} md={{span: 19}} lg={{ span: 21}}>
                    <Row>
                        <Col span={7}>
                            <Form.Item name={"name"} label={"Name:"} rules={[{required: true}]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={7} offset={1}>
                            <Form.Item name={"email"} label={"Email:"}>
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={7}>
                            <Form.Item name={"timezone"} label={"Timezone:"} rules={[{required: true}]}>
                                <Select
                                    showSearch
                                    loading={isLoadingTZ}
                                    options={timezoneList}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item>
                            <Button htmlType="submit" type={"primary"} loading={isSavingPersonData}>Save changes</Button>
                        </Form.Item>
                    </Row>

                </Col>
            </Row>


            </Form>

            <ProfileConnectedAccountWidget />


        </Content>
        );
}

export default Profile;

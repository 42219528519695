import {Button, Card, Col, Empty, Row, Switch, Tag, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import ConnectEmail from "./ConnectEmail";
import {Divider} from "antd/lib";
import React, {useEffect, useState} from "react";
import {ENV_API_HOST} from "../config";
import {useGlobalState} from "../utils";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import ConnectedEmailWidget from "./ConnectedEmailWidget";
import ConnectLinkedin from "./ConnectLinkedin";


function ProfileConnectedAccountWidget(props) {
    const [token] = useGlobalState('token');

    const [isEmailLoading, setIsEmailLoading] = useState(false);
    const [emailRenderList, setEmailRenderList] = useState([]);

    const handleUpdateTurnState = () => {
      getEmailList();
    }

    useEffect(() => {
        getEmailList();
    }, []);

    const getEmailList = async () => {
        setIsEmailLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getEmailConnectionList`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        console.log("getLabelList = ", dataResponse);

        let renderList = [];
        for (let i = 0; i < dataResponse.length; i++) {
            renderList.push(<div style={{marginBottom: "10px"}}><ConnectedEmailWidget data={dataResponse[i]} onUpdate={handleUpdateTurnState} /></div>)
        }

        setEmailRenderList(renderList);
        setIsEmailLoading(false);
    }


    return(<div>
        <Row>
            <Col span={14}>
                <Typography.Title style={{marginBottom: "7px"}} level={2}>Linked accounts</Typography.Title>
            </Col>
            <Col span={10} style={{textAlign: "right", display: "flex", alignItems: "end", marginBottom: "7px", justifyContent: "right"}}>
                <ConnectLinkedin />
                <ConnectEmail />
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Divider style={{marginTop: "2px"}} />
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                {emailRenderList.length > 0 ? emailRenderList : <Empty />}
            </Col>
        </Row>
    </div>)
}

export default ProfileConnectedAccountWidget;

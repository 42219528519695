import {Button, Card, Col, Row, Switch, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {ENV_API_HOST} from "../config";
import {useGlobalState} from "../utils";

function ConnectedEmailWidget(props) {
    const [token] = useGlobalState('token');
    const {data, onUpdate} = props;

    const [isDisableLoading, setIsDisableLoading] = useState(false);

    const postDisable = async (newState) => {
        setIsDisableLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/disableEmailConnection`;

        const postData = {
            "id": data["id"],
            "state": newState
        }

        const metadataResponse = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });

        const responseData = await metadataResponse.json();
        // console.log("responseData = ", responseData);
        setIsDisableLoading(false);
        onUpdate();
    }

    const handleChangeEnable = (state) => {
      postDisable(state);
    }


    return( <Card>
        <Row>
            <Col span={20}>
                <FontAwesomeIcon icon={faEnvelope}/> {data["email"]}
            </Col>
            <Col span={2}>
                {data["isConnected"] && data["isEnabled"] ? <Tag color="green">Connected</Tag> : <Tag color="red">Disconnected</Tag>}
            </Col>
            <Col span={2} style={{textAlign: "right"}}>
                <Switch checked={data["isEnabled"]} loading={isDisableLoading} onChange={handleChangeEnable} />
            </Col>
        </Row>
    </Card>)
}

export default ConnectedEmailWidget;

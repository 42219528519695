import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {Alert, Button, Divider, Form, Input, Modal, Select, Typography} from "antd";
import React, {useState} from "react";
import {ENV_API_HOST} from "../config";
import {useGlobalState} from "../utils";

function ConnectEmail() {
    const [form] = Form.useForm();
    const [token] = useGlobalState('token');

    const [fields, setFields] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [responseMessage, setResponseMessage] = useState(null);
    const [responseType, setResponseType] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const checkEmailConnection = async (values) => {

        let localData = structuredClone(values);
        localData["imap_port"] = parseInt(values["imap_port"]);
        localData["smtp_port"] = parseInt(values["smtp_port"]);

        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/checkAndCreateEmailConnection`;
        const metadataResponse = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(localData)
        });
        const dataResponse = await metadataResponse.json();
        console.log("checkEmailConnection = ", dataResponse);
        setIsLoading(false);

        if (metadataResponse.ok) {
            setResponseType("success")
            handleOk();
        } else {
            setResponseType("error");
            setResponseMessage(dataResponse["error"]);
        }
    }

    const handleSend = () => {
        setResponseMessage(null);
        setResponseType(null);

        form.validateFields().then((values) => {
            console.log("handleSend values = ", values);

            checkEmailConnection(values);

        });
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    let responseRender = null;
    if(responseType == "error") {
        responseRender = <div style={{marginBottom: "15px"}}><Alert
            message="Connection Error"
            description={responseMessage}
            type="error"
        /></div>
    }

    return (<span>
        <Modal
            title="Connect Email"
            open={isModalOpen}
            onOk={handleSend}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Return
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={handleSend}>
                    Submit
                </Button>,
            ]}
        >

            {responseRender}

            <Form
                layout="vertical"
                fields={fields}
                form={form}
                initialValues={{
                    smtp_connection_type: 'TLS',
                }}
            >
                 <Form.Item
                     // style={{marginBottom: "0px"}}
                     label="Email:"
                     name="email"
                     rules={[{required: true, message: 'Please input your email'}]}
                 >
                     <Input/>
                 </Form.Item>

                 <Form.Item
                     // style={{marginBottom: "0px"}}
                     label="Password:"
                     name={"password"}
                     rules={[{required: true, message: 'Please input your password'}]}
                 >
                     <Input.Password/>
                 </Form.Item>


                 <div>
                     <Typography.Title style={{marginTop: "2px", marginBottom: "2px"}}
                                       level={4}>SMTP Settings</Typography.Title>
                     <Divider style={{marginTop: "2px", marginBottom: "16px"}}/>
                 </div>

                  <Form.Item
                      // style={{marginBottom: "0px"}}
                      label="SMTP Server :"
                      name="smtp_server"
                      rules={[{required: true, message: 'Please input your SMTP Server address'}]}
                  >
                     <Input/>
                 </Form.Item>

                <Form.Item
                    // style={{marginBottom: "0px"}}
                    label="SMTP Port :"
                    name="smtp_port"
                    rules={[{required: true, message: 'Please input your SMTP Port'}]}
                >
                     <Input/>
                 </Form.Item>


                 <Form.Item
                     // style={{marginBottom: "0px"}}
                     label="Connection type :"
                     name="smtp_connection_type"
                     rules={[{ required: true }]}
                 >
                     <Select
                         style={{ width: "100%" }}
                         options={[
                             { value: 'TLS', label: 'TLS' },
                             { value: 'SSL', label: 'SSL' },
                         ]}
                     />
                 </Form.Item>


                 <div>
                     <Typography.Title style={{marginTop: "2px", marginBottom: "2px"}}
                                       level={4}>IMAP Settings</Typography.Title>
                     <Divider style={{marginTop: "2px", marginBottom: "16px"}}/>
                 </div>

                  <Form.Item
                      // style={{marginBottom: "0px"}}
                      label="IMAP Server :"
                      name="imap_server"
                      rules={[{required: true, message: 'Please input your IMAP Server address'}]}
                  >
                     <Input/>
                 </Form.Item>

                <Form.Item
                    // style={{marginBottom: "0px"}}
                    label="IMAP Port :"
                    name="imap_port"
                    rules={[{required: true, message: 'Please input your IMAP Port'}]}
                >
                     <Input/>
                 </Form.Item>
             </Form>
      </Modal>

        <Button size={"large"} onClick={showModal} type={"primary"}><FontAwesomeIcon icon={faEnvelope}/> &nbsp; Connect Email</Button>
    </span>)
}

export default ConnectEmail;

import {Avatar, Col, Row, Tag, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import React from "react";
import {
    faCheck,
    faEnvelope,
    faEye, faGear, faGears, faPen, faPlus,
    faSquareCheck, faSquarePen, faSquarePlus,
    faTriangleExclamation,
    faUserCheck,
    faUserPlus, faWandMagicSparkles
} from "@fortawesome/free-solid-svg-icons";
import {json, Link, useNavigate} from "react-router-dom";
import EmailCardForListWidget from "./EmailCardForListWidget";
import TaskEdit from "../TaskEdit";
import EmailCardForActivityLog from "./EmailCardForActivityLog";


const {Text} = Typography

const contentStyle = {
    minHeight: "32px",
    paddingTop: "4px"
};

const getTextMessageBlock = (messageText) => {
    console.log("messageText = ", messageText);

    const splitMessageText = messageText.split('\n');

    return (
        <div style={{marginTop: "5px", marginBottom: "20px", marginLeft: "24px"}}>
            <Typography.Paragraph>
                <blockquote>
                    {
                        splitMessageText.map((text, index) =>
                            <React.Fragment key={index}>
                                {text}
                                {index !== splitMessageText.length - 1 && <br/>}
                            </React.Fragment>
                        )
                    }
                </blockquote>
            </Typography.Paragraph>
        </div>
    );
}

function ActivityLogWidget(props) {
    const {userId, eventData, teamMap, personProfile} = props;
    const eventType = eventData["eventType"];

    let cardEvent = null;
    let icon = null;

    const handleUpdateEvent = () => {
        props.onUpdate();
    }

    let teamMemberRender = null;
    if (eventData["createdBy"] != null) {
        let teamMember = teamMap[eventData["createdBy"]];
        teamMemberRender = <span><Avatar size={20} src={teamMember["photo"]}/> <Typography.Text
            strong>{teamMember["name"]}</Typography.Text></span>
    }

    let personRender = <span><Avatar size={20} src={personProfile["photo"]}/> <Typography.Text
        strong>{personProfile["name"]}</Typography.Text></span>
    if (eventType == "LINKEDIN_PERSON_VISIT_PROFILE") {
        cardEvent = <div>{teamMemberRender} visit LinkedIn profile.</div>
        icon = faEye
    } else if (eventType == "LINKEDIN_PERSON_SEND_INVITE") {

        cardEvent = <div>{teamMemberRender} sent connection invitation.</div>
        if (eventData["newState"] != null && eventData["newState"].length >= 1) {
            cardEvent = <div>
                <div>{teamMemberRender} sent connection invitation with notes:</div>
                {getTextMessageBlock(eventData["newState"])}
            </div>
        }
        icon = faUserPlus
    } else if (eventType == "TASK_CREATED") {
        let taskDataState = JSON.parse(eventData["newState"]);

        let notesDetails = <span>.</span>
        if (taskDataState["notes"] != null && taskDataState["notes"].length > 0) {
            notesDetails = <span> with notes: {getTextMessageBlock(taskDataState["notes"])}</span>
        }

        // console.log("TASK_CREATED : state = ", taskDataState)
        cardEvent = <span>{teamMemberRender} created a task <TaskEdit
                                                                taskId={taskDataState["id"]}
                                                                currentTaskType={taskDataState["task_type"]}
                                                                currentTaskName={taskDataState["name"]}
                                                                onUpdate={handleUpdateEvent} /> {notesDetails}</span>
        icon = faPlus;
    } else if (eventType == "LINKEDIN_PERSON_CONNECTED") {
        icon = faUserCheck;
        cardEvent = <div>{personRender} has accepted invitation.</div>
    } else if (eventType == "LINKEDIN_PERSON_SEND_CHAT_MESSAGE" || eventType == "LINKEDIN_PERSON_RECEIVE_CHAT_MESSAGE") {
        let eventStarter = <span>{personRender} sent a LinkedIn message</span>;
        if (eventType == "LINKEDIN_PERSON_SEND_CHAT_MESSAGE") {
            eventStarter = <span>{teamMemberRender} sent a LinkedIn message</span>;
        }
        if (eventData["newState"] != null && eventData["newState"].length > 0) {
            let messageData = JSON.parse(eventData["newState"]);
            cardEvent = <div>
                <div>{eventStarter}:</div>
                {getTextMessageBlock(messageData["message"])}
            </div>
        } else {
            cardEvent = <div>
                {eventStarter}.
            </div>
        }
        icon = faLinkedinIn;
    } else if (eventType == "EMAIL_RECEIVED") {
        console.log("email event : ", eventData);
        let messageData = JSON.parse(eventData["newState"]);

        let eventStarter = <span>{personRender} sent an email:</span>;
        cardEvent = <div>
            <div>{eventStarter}</div>
            <div style={{marginTop: "5px", marginBottom: "20px", marginLeft: "24px"}}>
                <Typography.Paragraph>
                    <blockquote>
                        <EmailCardForActivityLog emailId={eventData["targetEmailId"]} />

                    </blockquote>
                </Typography.Paragraph>
            </div>
        </div>;
        icon = faEnvelope;
    } else if(eventType == "SEQUENCE_TASK_CREATED") {
        let taskDataState = JSON.parse(eventData["newState"]);
        let sequenceState = JSON.parse(eventData["previousState"]);

        let notesDetails = <span>.</span>;
        if (taskDataState["notes"] != null && taskDataState["notes"].length > 0) {
            notesDetails = <span> with notes: {getTextMessageBlock(taskDataState["notes"])}</span>
        }

        let sequenceLink = <Link  to={`/user/${userId}/sequences`} target={"_blank"}>{sequenceState["name"]}</Link>
        console.log("SEQUENCE_TASK_CREATED : state = ", eventData)
        cardEvent = <span> <TaskEdit
            taskId={taskDataState["id"]}
            currentTaskType={taskDataState["task_type"]}
            currentTaskName={taskDataState["name"]}
            onUpdate={handleUpdateEvent} /> task has been created by the {sequenceLink} sequence {notesDetails}</span>
        icon = faPlus;
    } else if(eventType == "SEQUENCE_ASSIGNEE_PROFILE") {
        // const nav = useNavigate();
        console.log(" personProfile = ", personProfile);
        let sequenceLink = <Link to={`/user/${userId}/sequences`} target={"_blank"}>{eventData["sequence"]["name"]}</Link>
        cardEvent = <div>{teamMemberRender} assigned the profile to the {sequenceLink} sequence.</div>;
        icon = faGear
    } else if(eventType == "TASK_CHANGE_NAME") {
        const newStateTask = JSON.parse(eventData["newState"]);
        const prevStateTask = JSON.parse(eventData["previousState"]);


        let prevTask = <TaskEdit
            taskId={eventData["targetTaskId"]}
            currentTaskType={prevStateTask["task_type"]}
            currentTaskName={prevStateTask["name"]}
            onUpdate={handleUpdateEvent} />

        let nextTask = <TaskEdit
            taskId={eventData["targetTaskId"]}
            currentTaskType={newStateTask["task_type"]}
            currentTaskName={newStateTask["name"]}
            onUpdate={handleUpdateEvent} />


        cardEvent = <div>{teamMemberRender} changed the task name from <Text delete>{prevTask}</Text> to {nextTask}.</div>;
        icon = faPen
    }  else if(eventType == "TASK_DONE_STATE") {
        const prevStateTask = JSON.parse(eventData["previousState"]);

        let prevTask = <TaskEdit
            taskId={eventData["targetTaskId"]}
            currentTaskType={prevStateTask["task_type"]}
            currentTaskName={prevStateTask["name"]}
            onUpdate={handleUpdateEvent} />

        cardEvent = <div>{teamMemberRender} marked the task {prevTask} as done.</div>;
        icon = faCheck
    } else if(eventType == "GENERATE_MESSAGE") {
        const state = JSON.parse(eventData["newState"]);

        cardEvent = <div>{teamMemberRender} has generated the {state["message_type"]} message and spent <Tag>{state["token_usage"]} {state["token_usage"] == 1 ? "token": "tokens"}</Tag>:
            {getTextMessageBlock(state["message"])}
        </div>;

        icon = faWandMagicSparkles;
    } else {
        cardEvent = <div>Unknown event: {eventData["eventType"]}</div>
        icon = faTriangleExclamation;
    }


    return (<Row>
        <Col span={2}>
            <div className={"containerForIconEventType"} style={{paddingBottom: "30px"}}>
                <div className={"iconForEventType"}>
                    <span style={{color: "#376fe8"}}><FontAwesomeIcon size={32} icon={icon}/></span>
                </div>
            </div>
        </Col>
        <Col span={19} style={contentStyle}>
            {cardEvent}
            {/*{eventData != undefined ? eventData["eventType"] : "UNDEFINED!!!"}*/}
        </Col>
        <Col span={3} style={{textAlign: "right"}}>
            {eventData["eventDateDay"]}<Typography.Text type="secondary">, {eventData["eventDateTime"]}</Typography.Text>
        </Col>
    </Row>)
}

export default ActivityLogWidget;

import moment from "moment";
import {Alert, Button, Card, Col, Divider, List, Row, Tag, Typography} from "antd";
import BillingPortal from "../../BillingPortal";
import {useGlobalState} from "../../utils";
import {useState} from "react";
import {ENV_API_HOST} from "../../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const freeProductId = 6;
const firstPaidProductId = 7;
const secondPaidProductId = 8;

function ProductPlatformList(props) {
    const [token] = useGlobalState("token");
    const [isLoading, setIsLoading] = useState(false);
    const [userAuthData] = useGlobalState('userAuthData');

    console.log("[Billing.js:ProductDisplay]userAuthData = ", userAuthData, props);

    const createBillingSession = async (priceId, quantity) => {
        setIsLoading(true);
        const postData = {
            priceId: priceId,
        }

        const requestUrl = `${ENV_API_HOST}/api/createCheckoutSession`;

        const metadataResponse = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });

        const responseData = await metadataResponse.json();
        console.log("[Billing.js:ProductDisplay] responseData = ", responseData);

        if(responseData["action"] == "redirect") {
            window.location.replace(responseData["params"]["url"]);
        } else {

            setTimeout(() => {
                props.onUpdate();
            }, 2000);
        }

        setIsLoading(false);

    }


    return(<div>
        <Row style={{marginTop: "25px"}}>
            <Col span={24}>
                <Typography.Title
                    level={4}
                    style={{
                        margin: 0,
                    }}
                >Change your subscription</Typography.Title>
            </Col>
        </Row>
        <Row style={{marginTop: "10px"}}>
            <Col span={5}>
                <Card title={"Essential"} bordered={true}>
                    <List >
                        <List.Item>
                            <FontAwesomeIcon icon={faCheck} /> 1 email address per account
                        </List.Item>
                        <List.Item>
                            <FontAwesomeIcon icon={faCheck} /> 10 sequences
                        </List.Item>
                    </List>
                    <Typography.Title level={3}>Free  </Typography.Title>
                    <Button onClick={() => createBillingSession(freeProductId)} loading={isLoading} disabled={props.currentProduct["id"] == freeProductId} block>Checkout</Button>
                </Card>
            </Col>

            <Col span={5} offset={1}>
                <Card title="Growth" bordered={true}>
                    <List>
                        <List.Item>
                            <FontAwesomeIcon icon={faCheck} /> 2 email accounts per user
                        </List.Item>
                        <List.Item>
                            {/*<Tooltip title={"Personalization Action refers to any action where an AI personalizes a message for your contact. This can include a welcome message, chat message, response to a chat message, and more."}>*/}
                            <FontAwesomeIcon icon={faCheck} /> 100 sequences
                            {/*</Tooltip>*/}
                        </List.Item>
                    </List>
                    <Typography.Title level={3} >€9.95 <Typography.Text type="secondary">month/user</Typography.Text></Typography.Title>
                    <Button type={"primary"} onClick={() => createBillingSession(firstPaidProductId)} loading={isLoading} disabled={props.currentProduct["id"] == firstPaidProductId} block>Checkout</Button>
                </Card>
            </Col>



            <Col span={5} offset={1}>
                <Card title="Scaling" bordered={true}>
                    <List >
                        <List.Item>
                            <FontAwesomeIcon icon={faCheck} /> 5 email accounts per user
                        </List.Item>
                        <List.Item>
                            {/*<Tooltip title={"Personalization Action refers to any action where an AI personalizes a message for your contact. This can include a welcome message, chat message, response to a chat message, and more."}>*/}
                            <FontAwesomeIcon icon={faCheck} /> 500 sequences
                            {/*</Tooltip>*/}
                        </List.Item>
                    </List>
                    <Typography.Title level={3} >€59.99 <Typography.Text type="secondary">month/user</Typography.Text></Typography.Title>
                    <Button type={"primary"} onClick={() => createBillingSession(secondPaidProductId)} loading={isLoading} disabled={props.currentProduct["id"] == secondPaidProductId} block>Checkout</Button>
                </Card>
            </Col>


            <Col span={5} offset={1}>
                <Card title="Enterprise" bordered={true}>
                    <List>
                        <List.Item>
                            <FontAwesomeIcon icon={faCheck} /> Unlimited email accounts per user
                        </List.Item>
                        <List.Item>
                            {/*<Tooltip title={"Personalization Action refers to any action where an AI personalizes a message for your contact. This can include a welcome message, chat message, response to a chat message, and more."}>*/}
                            <FontAwesomeIcon icon={faCheck} /> Unlimited sequences
                            {/*</Tooltip>*/}
                        </List.Item>
                    </List>
                    <div style={{marginTop: "40px"}}>
                        <Button href={"https://calendly.com/scopemoney/30min"} target={"_blank"} type={"primary"}  block>Contact Us</Button>

                    </div>
                </Card>
            </Col>
        </Row>

    </div>)
}


function PlatformBillingWidget(props) {
    const {billingData} = props;

    console.log("[Billing.js] billingData = ", billingData);

    const updateBillingData = () => {
      props.onUpdate();
    }

    let billingRender = null;
    if (!billingData["product"]["is_base"]) {

        let paymentCycle = "Yearly";
        if (billingData["payment_interval"] == "month") {
            paymentCycle = "Monthly";
        }

        let nextPaymentDate = moment.unix(billingData["period_end"]).format("DD MMMM YYYY");

        billingRender = <div>


            <Row>
                <Col span={24}><Typography.Title
                    level={2}
                    style={{
                        margin: 0,
                    }}
                >Pricing</Typography.Title></Col>
            </Row>

            {billingData["cancel_at_period_end"] ? <Row style={{marginTop: "10px", marginBottom: "5px"}}>
                <Col span={12}>
                    <Alert message={`This plan has been cancelled and will be effective until ${moment.unix(billingData["cancel_at"]).format("DD MMMM YYYY")}.`} type="warning" showIcon />
                </Col>
            </Row>: null }

            <Row style={{marginTop: "10px"}}>
                <Col span={2}>
                    <Row><Col span={24}> <Typography.Title
                        level={5}
                        type={"secondary"}
                        style={{
                            margin: 0,
                        }}
                    >Cycle</Typography.Title></Col></Row>
                    <Row><Col span={24}><Typography.Title
                        level={4}
                        style={{
                            margin: 0,
                        }}
                    >{paymentCycle}</Typography.Title></Col></Row>
                </Col>
                <Col span={3}>
                    <Row><Col span={24}> <Typography.Title
                        level={5}
                        type={"secondary"}
                        style={{
                            margin: 0,
                        }}
                    >Next Billing Period</Typography.Title></Col></Row>
                    <Row><Col span={24}><Typography.Title
                        level={4}
                        style={{
                            margin: 0,
                        }}
                    >{nextPaymentDate}</Typography.Title></Col></Row>
                </Col>
                <Col span={2}>
                    <Row><Col span={24}> <Typography.Title
                        level={5}
                        type={"secondary"}
                        style={{
                            margin: 0,
                        }}
                    >Seats</Typography.Title></Col></Row>
                    <Row><Col span={24}><Typography.Title
                        level={4}
                        style={{
                            margin: 0,
                        }}
                    >{billingData["quantity"]}</Typography.Title></Col></Row>
                </Col>

                <Col span={4}>
                    <Row><Col span={24}> <Typography.Title
                        level={5}
                        type={"secondary"}
                        style={{
                            margin: 0,
                        }}
                    >Manage your subscription</Typography.Title></Col></Row>
                    <Row><Col span={24}><BillingPortal /></Col></Row>
                </Col>
            </Row></div>
    } else {
        billingRender = <div>
            <Row style={{marginTop: "10px"}}>
                <Col span={4}>
                    <Row><Col span={24}> <Typography.Title
                        level={5}
                        type={"secondary"}
                        style={{
                            margin: 0,
                        }}
                    >Subscription history</Typography.Title></Col></Row>
                    <Row><Col span={24}><BillingPortal/></Col></Row>
                </Col>
            </Row>
        </div>
    }


    return(<section>
        <div style={{marginBottom: "10%"}}>
            <Typography.Title style={{marginBottom: "0px"}} level={2}>Sales Engagement Platform</Typography.Title>
            <Divider style={{marginTop: "10px"}} />
            <div className="product">
                {billingRender}
                <ProductPlatformList currentProduct={billingData["product"]} onUpdate={updateBillingData} />
            </div>
        </div>
    </section>)

}

export default PlatformBillingWidget

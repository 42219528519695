import {Col, Layout, Row, Table, Typography} from "antd";
import NewRule from "./NewRule";
import React, {useEffect, useState} from "react";
import NewList from "./widgets/NewList";
import {ENV_API_HOST} from "./config";
import {useGlobalState} from "./utils";

const {Content} = Layout;

function ListPage() {
    const [token] = useGlobalState('token');
    const [isLoading, setIsLoading] = useState(true);
    const [listData, setListData] = useState([]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Members',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'Assigned sequences',
            dataIndex: 'address',
            key: 'address',
        },
    ];


    const getLists = async () => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getList`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        setListData(dataResponse);
        setIsLoading(false);
    }

    useEffect(() => {
        getLists();
    }, [])

    return (<Content style={{padding: '24px 24px', background: "white"}}>
        <Row>
            <Col span={14}>
                <Typography.Title level={3} style={{margin: 0}}>Lists</Typography.Title>
            </Col>
            <Col span={10} style={{textAlign: "right"}}>
                <NewList />

            </Col>
        </Row>

        <Row style={{marginTop: "20px"}}>
            <Col span={24}>
                <Table
                    columns={columns}
                    dataSource={listData}
                    loading={isLoading}
                />
            </Col>
        </Row>

    </Content>)
}

export default ListPage;

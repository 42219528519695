import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";
import {ENV_AUTH_DOMAIN, ENV_AUTH_CLIENT_ID, ENV_AUTH_AUDIENCE} from "./config";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById('root'));

// const onRedirectCallback = (appState) => {
//     console.log("onRedirectCallback: ", appState)
//     history.push(
//         appState && appState.returnTo
//             ? appState.returnTo
//             : window.location.pathname
//     );
// };


ReactGA.initialize("G-V1DBMPN00X");

root.render(
    <Auth0Provider
        domain={ENV_AUTH_DOMAIN}
        redirectUri={window.location.origin}
        // onRedirectCallback={onRedirectCallback}
        clientId={ENV_AUTH_CLIENT_ID}
        audience={ENV_AUTH_AUDIENCE}
        scope="openid profile email"
        useRefreshTokens={true}
        cacheLocation="localstorage"
    >
        <App />
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {Button, Card, Col, Row, Spin, Tag, Typography} from "antd";
import EmailAddressListForClientWidget from "./EmailAddressListForClientWidget";
import {Link} from "react-router-dom";
import {useState} from "react";
import {ENV_API_HOST} from "../config";
import {useGlobalState} from "../utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretUp} from "@fortawesome/free-solid-svg-icons";


function EmailCardForListWidget(props) {
    const [token] = useGlobalState('token');
    const [isOpenEmail, setIsOpenEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [htmlData, setHtmlData] = useState(null);

    const handleOpenEmail = () => {
      setIsOpenEmail(true);
      getHtmlData();
    }

    const handleCloseEmail = () => {
        setIsOpenEmail(false);
    }

    const getHtmlData = async () => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getEmailHtmlData?id=${emailData["id"]}`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        setHtmlData(dataResponse["html"]);
        setIsLoading(false);
    }

    const {emailData} = props;
    return(<Card size={"small"} style={{marginBottom: "10px"}}>
        <div>
            <Row>
                <Col span={20}>
                    <EmailAddressListForClientWidget data={emailData} onUpdate={props.onUpdate} />
                </Col>
                <Col span={4} style={{display: "flex", justifyContent: "flex-end"}}>
                    <Typography.Title level={5} style={{marginTop: "0px", marginBottom: "0px"}}>{emailData["email_date_format"]}, <Typography.Text type="secondary">{emailData["email_time_format"]}</Typography.Text></Typography.Title>
                    {/*<EmailAddAsConnectionWidget data={emailListData[i]} />*/}
                </Col>
            </Row>
        </div>
        {!isOpenEmail? <Link onClick={handleOpenEmail}>
            <div>
                <Typography.Paragraph ellipsis={true} style={{marginBottom: "3px"}}>
                    <Typography.Text strong>{emailData["subject"]}</Typography.Text>
                </Typography.Paragraph>
            </div>
            <Typography.Paragraph ellipsis={{rows: 2, expandable: false}}>
                <Typography.Text>{emailData["email_text"]}</Typography.Text>
            </Typography.Paragraph>
        </Link> : isLoading ? <div style={{textAlign: "center", marginTop: "20px",  marginBottom: "40px"}}><Spin tip="Loading app ..." size="large">
            <div className="content" />
        </Spin></div> : <div>
            <div>
                <Row>
                    <Col span={20}>
                        <Typography.Paragraph ellipsis={true} style={{marginBottom: "15px"}}>
                            <Typography.Text strong>{emailData["subject"]}</Typography.Text>
                        </Typography.Paragraph>
                    </Col>
                    <Col span={4} style={{textAlign: "right"}}>
                        <Button onClick={handleCloseEmail}> <FontAwesomeIcon icon={faCaretUp} /> &nbsp; Close email</Button>
                    </Col>
                </Row>

            </div>
            <div dangerouslySetInnerHTML={{ __html: htmlData }} />
        </div> }

        <div>
            {emailData["label"] == "REGULAR" ? <Tag color="blue">Regular</Tag> : <Tag>Promo</Tag>}
            {emailData["category"] == "INBOX" ? <Tag color="cyan">Inbox</Tag> : <Tag  color="cyan">Sent</Tag>}

            {/**/}
        </div>
    </Card>)
}

export default EmailCardForListWidget;

import {Avatar, Button, Col, Layout, Popover, Row, Space, Spin, Table, Typography} from "antd";
import {useGlobalState} from "./utils";
import React, {useEffect, useState} from "react";
import {ENV_API_HOST} from "./config";
import {useParams} from "react-router-dom";
import {faCircleArrowDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PersonDataWidget from "./widgets/PersonDataWidget";
import ConnectionStatusWidget from "./widgets/ConnectionStatusWidget";
import CompanyListWidget from "./widgets/CompanyListWidget";
import moment from "moment";
import ContactListTagManagement from "./ContactListTagManagement";
import OwnerWidgetForList from "./OwnerWidgetForList";
import ArhiveWidget from "./ArhiveWidget";
import LinkedinChatWidget from "./widgets/LinkedinChatWidget";
import {InfoCircleOutlined} from "@ant-design/icons";
import TaskListWidgetForList from "./widgets/TaskListWidgetForList";

const {Header, Content, Sider} = Layout;
const {Title} = Typography;

function SelectedCompany(props) {
    const [token] = useGlobalState('token');
    const [isLoading, setIsLoading] = useState(true);
    const [isContactLoading, setIsContactLoading] = useState(true);
    const [companyData, setCompanyData] = useState(null);
    const [leadPersonData, setLeadPersonData] = useState([]);
    const {companyId} = useParams();
    const [labelMap, setLabelMap] = useState({});


    useEffect(() => {
        getCompanyDetails();
        getLeadData();
        getLabelList();
    }, [companyId]);

    const getLabelList = async () => {
        const requestUrl = `${ENV_API_HOST}/api/getCompanyLabelList`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        console.log("getLabelList = ", dataResponse);
        let labelMap = {}
        for (let i = 0; i < dataResponse.length; i++) {
            labelMap[dataResponse[i].id] = dataResponse[i];
        }
        setLabelMap(labelMap);
    }

    const getCompanyDetails = async () => {
        const requestUrl = `${ENV_API_HOST}/api/getCompanyDetails?id=${companyId}`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const responseData = await metadataResponse.json();
        setCompanyData(responseData);
    }

    const getLeadData = async () => {
        setIsContactLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getPersonListByConnection?limit=300&offset=0&company_id=${companyId}`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const responseData = await metadataResponse.json();
        // console.log("responseData = ", responseData)
        setLeadPersonData(responseData['contactList']);
        setIsContactLoading(false);

    }

    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (person) => <PersonDataWidget person={person} />,
            // width: "13%"
        },
        {
            title: 'Channels',
            key: 'conn_state',
            render: (person) => <ConnectionStatusWidget person={person} />,
        },
        {
            title: 'Present companies',
            render: (person, _, index) => <CompanyListWidget key={index} person={person} />,
            width: "10%"
        },
        {
            title: <span>Activities <Popover title="Info about activity column"><InfoCircleOutlined /></Popover></span>,
            render: (person, _, index) => <LinkedinChatWidget key={index} person={person} />,

        },
        {
            title: 'Tasks',
            render: (person, _, index) => <TaskListWidgetForList key={index} person={person} />,
            // width: "13%"
        },
        {
            title: 'Labels',
            render: (person, _, index) => <span>
                <ContactListTagManagement key={index} person={person["profile"]} mapData={labelMap}/>
            </span>,
        },
        {
            title: 'Assignees',
            render: (person, _, index) => <div>
                <OwnerWidgetForList key={index} person={person} />
            </div>,
        },
        {
            title: 'Creation date',
            render: (person) => <div>
                {moment.unix(person["connection_date"]).format("DD MMM, YY (ddd)")}
            </div>,
        },
        {
            // title: 'Actions',
            render: (_, record, index) => (
                <Space size="middle">
                    <ArhiveWidget key={index} person={record} />
                </Space>
            ),
            width: "2%"
        },
    ]


    return (<div>
        {companyData != null ? <div><Content
            style={{
                padding: "15px 20px",
                margin: 0,
                minHeight: 0,
                background: "white",
            }}
        >

            <Row>
                <Col  xs={{ span: 5}} md={{span: 3}} lg={{ span: 1}}>
                    <div>
                        <Avatar shape="square" size={85} src={companyData["image"]}/>
                    </div>



                </Col>
                <Col xs={{ span: 17}} md={{span: 19}} lg={{ span: 21,  offset: 1}}>
                    <Row>
                        <Col span={24}><Title style={{marginTop: "0px", marginBottom: "5px"}}
                                              level={3}>{companyData["name"]}</Title></Col>
                    </Row>

                    <Row style={{marginBottom: "10px"}}>
                        <Col span={24}>
                            <Button href={`https://linkedin.com/company/${companyData["linkedinId"]}`} target={"_blank"} size={"small"}>LinkedIn Page</Button>
                            &nbsp;
                            {companyData["website"] != null ? <Button href={companyData["website"]} target={"_blank"} size={"small"}>Website</Button> : null }
                        </Col>
                    </Row>

                    {companyData["about"] != null ?
                        <Row>
                            <Col span={14} style={{lineHeight: 1.7, whiteSpace: "pre-wrap", marginRight: "15px"}}>{companyData["about"]}</Col>
                            <Col span={3}>
                                {companyData["industry"] != null ?
                                    <div>
                                        <div >
                                            <Typography.Text strong>Industry:</Typography.Text>
                                        </div>
                                        <div style={{marginTop: "3px"}}>
                                            {companyData["industry"]}
                                        </div>
                                    </div> : null }

                            </Col>
                            <Col span={5}>
                                {companyData["location"] != null ?
                                    <div>
                                        <div >
                                            <Typography.Text strong>Headquarter:</Typography.Text>
                                        </div>
                                        <div style={{marginTop: "3px"}}>
                                            {companyData["location"]}
                                        </div>
                                    </div> : null }
                            </Col>
                        </Row> : null}



                </Col>
            </Row>

        </Content>

            <Content
                style={{
                    padding: "12px",
                    marginTop: "20px",
                    minHeight: 0,
                    background: "white",
                }}
            >
                <Table
                    columns={columns}
                    dataSource={leadPersonData}
                    loading={isContactLoading}
                />
            </Content>

        </div> : <div style={{textAlign: "center"}}><Spin tip="Loading company ..." size="large">
            <div className="content"/>
        </Spin></div>}

    </div>)
}

export default SelectedCompany;

import React, {Fragment, useEffect, useState} from "react";
import {Input, List, Spin} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
import {useGlobalState} from "./utils";
import {ENV_API_HOST} from "./config";

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);

function EditPurposeItem(props) {
    const [token] = useGlobalState('token');
    const [itemData, setItemData] = useState(true);
    const [editableValue, setEditableValue] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    useEffect(() => {
        console.log("EditPurposeItem: props = ", props)
        setItemData(props.item);
        setEditableValue(props.item.purpose)
    }, [props?.itemData?.id]);

    const changeEditing = () => {
        if (isEditing) {
            setItemData(props.item);
            setEditableValue(props.item.purpose)
        }
        setIsEditing(!isEditing);
    }

    const onArchive = async () => {
        setIsEditing(false);
        setIsSaving(true);

        const requestUrl = `${ENV_API_HOST}/api/postArchivePurpose`;

        const postData = {
            id: props.item.id
        };

        await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });

        setIsSaving(false);
        setIsSaved(true);
        props.onUpdate();

    }

    const onSaveUpdate = async () => {
        setIsEditing(false);
        setIsSaving(true);

        const requestUrl = `${ENV_API_HOST}/api/updatePurpose`;

        console.log("onSaveUpdate: itemData = ",itemData)

        const postData = {
            id: itemData.id,
            purpose: editableValue
        };

        await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });

        setIsSaving(false);
        setIsSaved(true);
        props.onUpdate();
    }

    const changeValue = (e) => {
        setEditableValue(e.target.value);
    }

    let saveButton = <a key="list-loadmore-more" onClick={onArchive}>archive</a>;

    let actionsDom = [];
    if (!isEditing) {
        actionsDom = [<a key="list-loadmore-edit" onClick={changeEditing}>edit</a>, saveButton];
    } else {
        saveButton = <a key="list-loadmore-more" onClick={onSaveUpdate}>update</a>;
        actionsDom = [<a key="list-loadmore-edit" onClick={changeEditing}>undo</a>, saveButton];

    }

    if (isSaving) {
        actionsDom = [<span><Spin indicator={antIcon}/> Archiving ...</span>];
    }

    if (isSaved) {
        actionsDom = [<span>Archived</span>];
    }

    console.log("itemData = ", itemData);

    return (<List.Item actions={actionsDom}>
        {!isEditing ? <span>{editableValue}</span> : <Input onChange={changeValue} value={editableValue}/>}
    </List.Item>)
}

export default EditPurposeItem;

import {useEffect, useState} from "react";
import {ENV_API_HOST} from "../../config";
import {useGlobalState} from "../../utils";
import {Avatar, Select} from "antd";


function EmailMatchResultWidget(props) {
    const {emailAddress} = props;
    const [token] = useGlobalState('token');
    const [isLoading, setIsLoading] = useState(true);
    const [connectionList, setConnectionList] = useState([]);
    const [value, setValue] = useState(null);
    const [isLoadingProfile, setIsLoadingProfile] = useState(false);
    const [personProfile, setPersonProfile] = useState(null);

    const getConnectionList = async (nameFilter) => {
        setIsLoading(true);

        var nameQuery = "";
        if (nameFilter != null && nameFilter.length >= 1) {
            nameQuery = `lead_name=${nameFilter}`;
        }

        const personListUrl = `${ENV_API_HOST}/api/getPersonListByConnection?limit=20&offset=0&${nameQuery}`;
        const personListResponse = await fetch(personListUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const connectionListData = await personListResponse.json();
        let localConnectionList = [];
        for (let i = 0; i < connectionListData["contactList"].length; i++) {
            var conn = connectionListData["contactList"][i];
            localConnectionList.push({
                label: <span><Avatar size={24} src={conn["photo"]}/> {conn["name"]} </span>,
                value: conn["profile"]["id"],
            })
        }

        console.log("localConnectionList  = ", localConnectionList);
        setConnectionList(localConnectionList);
        setIsLoading(false);
    }

    const getProfileByLeadId = (leadId) => {

    }


    const getPersonData = async () => {
        setIsLoadingProfile(true);
        const requestUrl = `${ENV_API_HOST}/api/getPersonDataShortByEmailAddressId?id=${emailAddress["id"]}}`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        setPersonProfile(dataResponse["person"]);
        setIsLoadingProfile(false);
    }


    useEffect(() => {

        if(emailAddress["matching_state"] == "SUCCESS") {
            getPersonData().then(() => {
                getConnectionList();
            });
        } else {
            getConnectionList();
        }


    }, [emailAddress?.id]);


    const handleChange = (value) => {
        setValue(value);
        props.onUpdate(emailAddress["id"], value);
        getConnectionList(null);
    }

    const handleSearch = (newValue) => {
        // console.log("handleSearch = ", newValue);
        getConnectionList(newValue);
    }

    return (<div>
        <Select
            showSearch
            placeholder={props.placeholder}
            style={{width: "100%"}}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
            options={connectionList}
            loading={isLoading}
        />

    </div>)
}

export default EmailMatchResultWidget;

import React, {Fragment, useEffect, useState} from "react";
import {Button, Col, Input, Layout, Row} from "antd";
import TeamList from "./TeamList";
import {useGlobalState} from "./utils";
import checkIsNeedInviteActionsFirst from "./utils/inviteChecker";
import {ENV_API_HOST} from "./config";

const {Header, Content, Sider} = Layout;

function Team(props) {
    const {accountData} = props;
    const [token] = useGlobalState('token');
    const [teamName, setTeamName] = useState(null);
    const [teamList, setTeamList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        console.log("token = ", token);
        const getTeamData = async () => {
            setIsLoading(true);
            const teamDataJson = await getTeamDataRequest();
            setTeamName(teamDataJson["team"]["name"]);
            setTeamList(teamDataJson["memberList"]);
            setIsLoading(false);
        };

        getTeamData();
    }, []);

    let isInviteAction = checkIsNeedInviteActionsFirst();
    if (isInviteAction != null) {
        return isInviteAction
    }

    async function getTeamDataRequest() {
        const requestUrl = `${ENV_API_HOST}/api/getTeamData`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const teamDataJson = await metadataResponse.json();
        console.log("teamDataJson = ", teamDataJson);
        return teamDataJson;
    }
    const onInvitedUpdate = async () => {
        setIsLoading(true);
        const teamDataJson = await getTeamDataRequest();
        setTeamName(teamDataJson["team"]["name"]);
        setTeamList(teamDataJson["memberList"]);
        setIsLoading(false);
    }


    return (
        <Content style={{padding: '5px 24px', background: "white"}}>
            {isLoading ? <span>Loading ...</span> : <div>
                <div>
                    <h2>Team</h2>
                    <Row>
                        <Col span={24}>Team name:</Col>
                    </Row>
                    <Row style={{marginTop: "5px"}}>
                        <Col span={8}><Input value={teamName} /></Col>
                    </Row>
                    <Row style={{marginTop: "5px"}}>
                        <Col span={24}><Button>Update</Button></Col>
                    </Row>
                </div>
                <TeamList accountData={accountData} data={teamList} onInvitedUpdate={onInvitedUpdate} />
            </div>}
        </Content>
    );
}

export default Team;

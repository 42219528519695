import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarsStaggered, faCircleArrowDown, faCircleArrowUp} from "@fortawesome/free-solid-svg-icons";
import React from "react";

function LinkedinChatWidget(props) {
    const {person} = props;
    console.log("LinkedinChatWidget: props", person);

    let receiveRender = <span><FontAwesomeIcon
        icon={faCircleArrowDown}/> {person.profile["linkedin_receive_message_count"]}</span>

    let isZero = true;

    if (person.profile["linkedin_receive_message_count"] > 0) {
        isZero = false;
    }

    if (person.profile["linkedin_send_message_count"] > 0) {
        isZero = false;
    }


    if (isZero) {
        return (<span>No data</span>)
    }

    let renderLastSequence = null;
    if (person.profile["linkedin_last_our_message_sequence_count"] > 0) {
        renderLastSequence = <span><FontAwesomeIcon
            icon={faBarsStaggered}/> {person.profile["linkedin_last_our_message_sequence_count"]}</span>;
    }

    let sendRender = <span><FontAwesomeIcon
        icon={faCircleArrowUp}/> {person.profile["linkedin_send_message_count"] == null ? 0 : person.profile["linkedin_send_message_count"]} {renderLastSequence}</span>

    return (
        <div>{receiveRender} / {sendRender}</div>);

}

export default LinkedinChatWidget;

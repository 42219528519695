import {CheckCircleOutlined, ClockCircleOutlined, SyncOutlined} from "@ant-design/icons";
import {Avatar, Button, Col, Form, Input, Modal, Popconfirm, Row, Tag, Tooltip, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useGlobalState} from "../utils";
import {ENV_API_HOST} from "../config";
import {Link} from "react-router-dom";
import EmailMatchResultWidget from "./matching/EmailMatchResultWidget";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard} from "@fortawesome/free-solid-svg-icons";

function EmailShowSingleEmailWidget(props) {
    const [form] = Form.useForm();
    const [token] = useGlobalState('token');
    const {emailAddress} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [isExist, setIsExist] = useState(null);
    const [matchProfile, setMatchProfile] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [profileId, setProfileId] = useState(null);
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [isCreateLoading, setIsCreateLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const checkIsProfileEmailLinkExist = async () => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getConnectionByEmailAddressId?id=${emailAddress["id"]}`;


        const metadataResponse = await fetch(requestUrl, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        const responseData = await metadataResponse.json();
        // console.log("responseData = ", responseData);
        setIsExist(responseData["isExist"]);
        setMatchProfile(responseData["match"])
        setIsLoading(false);
    };


    const deleteConnection = async () => {
        setIsDeleteLoading(true);

        const postData = {
            emailAddressId: emailAddress["id"]
        }

        const requestUrl = `${ENV_API_HOST}/api/deattachProfileFromEmail`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });
        const dataResponse = await response.json();
        console.log("props.onUpdate = ", props.onUpdate);
        if (props.onUpdate != undefined) {
            props.onUpdate();
        }
        setIsDeleteLoading(false);
        handleCancel();
    }

    const createNewProfile = async (values) => {
        setIsCreateLoading(true);

        values["emailAddressId"] = emailAddress["id"];

        const requestUrl = `${ENV_API_HOST}/api/createProfileFromEmail`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values)
        });
        const dataResponse = await response.json();
        console.log("props.onUpdate = ", props.onUpdate);
        if (props.onUpdate != undefined) {
            props.onUpdate();
        }
        setIsCreateLoading(false);
        handleCancel();
    }


    useEffect(() => {
        checkIsProfileEmailLinkExist();
    }, [emailAddress?.id])

    const tagStyle = {
        fontSize: '14px', // equivalant to h5 tag fontSize
        padding: '4px 8px',
        fontWeight: 'bold'
    };

    let profileButtonRender = null;
    let tagIcon = <ClockCircleOutlined/>;
    let tagColor = "default";
    if (isLoading) {
        tagIcon = <SyncOutlined spin/>;
    }

    if (!isLoading) {
        if (isExist) {
            tagIcon = <CheckCircleOutlined/>;
            tagColor = "success";
            profileButtonRender = <Tooltip title={"Open the profle"}><Button target={"_blank"}
                                                                             href={`/user/${matchProfile["profileId"]}/activity`}><FontAwesomeIcon
                icon={faAddressCard}/></Button></Tooltip>
        }
    }


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        form.resetFields();
        setIsCreateMode(false);
        setIsModalOpen(false);
    };


    const handleSubmit = async () => {

        const postData = {
            'emailAddressId': emailAddress["id"],
            'profileId': profileId,
        };

        const requestUrl = `${ENV_API_HOST}/api/postEmailConnectionWithProfile`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });
        console.log("props.onUpdate = ", props.onUpdate);
        if (props.onUpdate != undefined) {
            props.onUpdate();
        }
        const dataResponse = await response.json();
        handleCancel();

    }

    const handleUpdateMatch = (emailAddressId, profileId) => {
        setProfileId(profileId);
    }

    const handleCreateProfile = () => {
        form.validateFields().then((values) => {
            // console.log("handleCreateProfile: Fields: ", values);
            createNewProfile(values);
        })
    }

    const handleCreateProfileSwitch = () => {
        setIsCreateMode(!isCreateMode);
    }

    let renderModal = null;

    let emailName = null;
    let emailTitle = emailAddress["email"];
    if (emailAddress["email_name"] != null && emailAddress["email_name"].length > 1) {
        emailTitle = <span>{emailAddress["email_name"]} [{emailAddress["email"]}] </span>;
        emailName = emailAddress["email_name"];
    }

    var matchingState = null;
    var matchingRender = null;
    var footer = [
        <Button key="back" onClick={handleCancel}>
            Return
        </Button>
    ];

    if (isExist) {
        matchingRender = <div>
            This email attached to <Tag><Avatar size={24}
                                                src={matchProfile["profile"]["person"]["photo"]}/> {matchProfile["profile"]["person"]["name"]}
        </Tag>
        </div>;
        footer = [

            <Button target={"_blank"} href={`/user/${matchProfile["profileId"]}/activity`}>
                Open profile
            </Button>,
            <Button type={"primary"}>Change connection</Button>,
            <Popconfirm
                title="Are you sure?"
                onConfirm={deleteConnection}
                okButtonProps={{ loading: isDeleteLoading }}
            ><Button danger>Remove connection</Button></Popconfirm>,
            <Button key="back" onClick={handleCancel}>
                Return
            </Button>
        ];

    } else {
        matchingRender = <div>
            <EmailMatchResultWidget emailAddress={emailAddress} onUpdate={handleUpdateMatch}/>
        </div>
        footer = [
            <Button key="back" onClick={handleCancel}>
                Return
            </Button>,
            <Button key="submit" type="primary" onClick={handleCreateProfileSwitch}>
                Create a new profile
            </Button>,
            <span> - or - </span>,
            <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
                Connect to profile
            </Button>
        ];
    }

    let renderActionMatch = null;


    if (isCreateMode) {
        footer = [
            <Button key="back" onClick={handleCancel}>
                Return
            </Button>,
            <Button key="submit" type="primary" onClick={handleCreateProfile}>
                Create a new profile
            </Button>
        ];

        renderActionMatch = <div>
            <Row style={{marginTop: "20px", marginBottom: "5px"}}>
                <Col span={24}><Typography.Text strong>Create a new profile:</Typography.Text></Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form
                        form={form}
                        layout={"vertical"}
                        initialValues={{name: emailName}}
                    >
                        <Form.Item
                            name={"name"}
                            label={"Name: "}
                            required={true}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value.length > 1) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Input value should be greater than 1 character.'));
                                    }
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Form>

                </Col>
            </Row>
        </div>
    } else {
        renderActionMatch = <div>
            <Row style={{marginTop: "20px", marginBottom: "5px"}}>
                <Col span={24}><Typography.Text strong>Matching with profile:</Typography.Text></Col>
            </Row>
            <Row>
                <Col span={24}>{matchingRender}</Col>
            </Row>
        </div>
    }


    renderModal = <div>
        <Row>
            <Col span={24}><Typography.Text strong>Selected email:</Typography.Text></Col>
        </Row>
        <Row>
            <Col span={24}>{emailTitle}</Col>
        </Row>
        {renderActionMatch}
    </div>;


    return (<span>
          <Modal width={600} title="Email attachment to profile" open={isModalOpen} onCancel={handleCancel}
                 footer={footer}
          >
            {renderModal}
        </Modal>



        <Link onClick={showModal}><Tag style={tagStyle} icon={tagIcon} color={tagColor}>
        {emailAddress["email_name"] != null && emailAddress["email_name"].length > 1 ? emailAddress["email_name"] : emailAddress["email"]}
    </Tag></Link>{profileButtonRender}</span>);
}

export default EmailShowSingleEmailWidget;

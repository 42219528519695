import {Navigate} from "react-router-dom";


const checkIsNeedInviteActionsFirst = () => {
    const inviteTokenFromLocalStorage = localStorage.getItem("inviteToken")

    let isNeedRedirect = (inviteTokenFromLocalStorage != undefined || inviteTokenFromLocalStorage != null) && window.location.pathname.indexOf("invite") == -1;
    //
    // console.log("Sign token", token);
    console.log("[InviteChecker.js] checkIsNeedInviteActionsFirst:", isNeedRedirect, inviteTokenFromLocalStorage);

    if (isNeedRedirect) {
        return <Navigate to={`/invite?token=${inviteTokenFromLocalStorage}`} />
    } else {
        return null;
    }

}

export default checkIsNeedInviteActionsFirst;

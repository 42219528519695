import {
    Layout,
    Row,
    Col,
    Table,
    Avatar,
    Button,
    Drawer,
    Radio,
    Space,
    Divider,
    Statistic,
    Spin,
    Tag,
    Typography, Popover, Tooltip
} from "antd";
import {AntDesignOutlined, UserOutlined} from "@ant-design/icons";

function OwnerWidgetForList(props) {
    // person={person} memberMap={memberMap}
    const {person, memberMap } = props;

    // let renderOwner = null;
    //
    // if(props.person["sender_name"] != null) {
    //     renderOwner = <span>{props.person["sender_name"]}</span>
    // } else {
    //     renderOwner = <span>{props.person["account_name"]}</span>
    // }

    const assigneeList = person["profile"]["assignee_id_list"];

    let avatarRenderList = [];
    for (let i = 0; i < assigneeList.length; i++) {
        const assignee = memberMap[assigneeList[i]]

        avatarRenderList.push(<Tooltip title={<span>{assignee['name']} ({assignee['email']})</span>} placement="top">
                <Avatar src={assignee["photo"]} />
            </Tooltip>
        )
    }

    return(  <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
        {avatarRenderList}
    </Avatar.Group>)
}
export default OwnerWidgetForList;

import React, {Fragment, useEffect, useState} from "react";
import {
    Layout,
    Row,
    Col,
    Table,
    Avatar,
    Button,
    Drawer,
    Radio,
    Space,
    Divider,
    Statistic,
    Spin,
    Tag,
    Typography, Popover
} from "antd";
import {useGlobalState} from './utils';
import {Link} from "react-router-dom";
import PersonProfile from "./PersonProfile";
import moment from "moment/moment";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCoffee,
    faCircleArrowUp,
    faCircleArrowDown,
    faBarsStaggered,
    faHourglass,
    faComments,
    faComment, faEnvelope, faClock, faFlag, faCheck
} from '@fortawesome/free-solid-svg-icons'
import {useNavigate, useSearchParams} from 'react-router-dom';
import ContactListTagManagement from "./ContactListTagManagement";
import FilterContactList from "./widgets/contactList/FilterContactList";
import checkIsNeedInviteActionsFirst from "./utils/inviteChecker";
import {ENV_API_HOST} from "./config";
import CompanyListWidget from "./widgets/CompanyListWidget";
import OwnerWidgetForList from "./OwnerWidgetForList";
import ArhiveWidget from "./ArhiveWidget";
import ConnectionStatusWidget from "./widgets/ConnectionStatusWidget";
import PersonDataWidget from "./widgets/PersonDataWidget";
import LinkedinChatWidget from "./widgets/LinkedinChatWidget";
import {CheckCircleOutlined, ClockCircleOutlined, InfoCircleOutlined, MinusCircleOutlined} from "@ant-design/icons";
import FilterWidget from "./widgets/contactList/FilterWidget";
import TaskListWidgetForList from "./widgets/TaskListWidgetForList";
import NewScratchProfile from "./widgets/NewScratchProfile";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {faSquare} from "@fortawesome/free-regular-svg-icons";


const {Header, Content, Sider} = Layout;
const { Title , Text } = Typography;




function ContactList() {
    const [token] = useGlobalState('token');
    const [isGlobalLoading, setIsGlobalLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [tableLoading, setTableLoading] = useState(false);
    const [personList, setPersonList] = useState([]);
    const [funnelData, setFunnelData] = useState(null);
    // const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    // const [selectedPerson, setSelectedPerson] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [labelMap, setLabelMap] = useState({})
    const [personListRequestParams, setPersonListRequestParams] = useState({});
    const [needUpdate, setNeedUpdate] = useState(null);
    const [isLoadingMemberMap, setIsLoadingMemberMap] = useState(false);
    const [memberMap, setMemberMap] = useState({});
    const [isFilterExpand, setIsFilterExpand] = useState(false);

    const nav = useNavigate();




    // const showDrawer = () => {
    //     setIsOpenDrawer(true);
    // };
    // const onClose = () => {
    //     setIsOpenDrawer(false);
    // };

    // const selectPerson = (isOpen, person) => {
    //     console.log("ContactList.selectPerson=", isOpen, person);
    //     setIsOpenDrawer(isOpen);
    //     setSelectedPerson(person);
    // }
    //
    // const onCloseProfile = () => {
    //     console.log("handle onCloseProfile")
    //     setSelectedPerson(null);
    //     setIsOpenDrawer(false);
    // }

    const getLabelList = async () => {
        const requestUrl = `${ENV_API_HOST}/api/getCompanyLabelList`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        console.log("getLabelList = ", dataResponse);
        let labelMap = {}
        for (let i = 0; i < dataResponse.length; i++) {
            labelMap[dataResponse[i].id] = dataResponse[i];
        }
        setLabelMap(labelMap);
    }

    async function getTeamData() {
        setIsLoadingMemberMap(true);
        const requestUrl = `${ENV_API_HOST}/api/getTeamData`;
        const metadataResponse = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const teamDataJson = await metadataResponse.json();
        let locMemberMap = {};
        for (let i = 0; i < teamDataJson["memberList"].length; i++) {
            locMemberMap[teamDataJson["memberList"][i]["id"]] = teamDataJson["memberList"][i];
        }
        console.log("memberMap = ", locMemberMap);
        setMemberMap(locMemberMap);
        setIsLoadingMemberMap(false);
    }

    const getPersonList = async (limit, offset, requestParams) => {
        console.log("[ContactList.js] getPersonList: limit = ", limit, "; offset = ", offset,"; requestParams = ", requestParams);
        let queryParams = new URLSearchParams(requestParams).toString()

        console.log("[ContactList.js] queryParams = ", queryParams);
        let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?limit=${limit}&offset=${offset}&${queryParams}`
        window.history.pushState({path: newUrl}, '', newUrl);

        setTableLoading(true);
        const personListUrl = `${ENV_API_HOST}/api/getPersonListByConnection?limit=${limit}&offset=${offset}&${queryParams}`;
        const personListResponse = await fetch(personListUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const connectionListData = await personListResponse.json();
        console.log("personList = ", connectionListData);

        setPersonList(connectionListData["contactList"]);
        setTotal(connectionListData["sum"]);

        if (offset != 0) {
            setCurrentPage((offset / limit)+1);
        } else {
            setCurrentPage(1);
        }

        setTableLoading(false);
    };


    const filterVisiblityChange = () => {
        setIsFilterExpand(!isFilterExpand)
    }

    const getFunnel = async (requestParams) => {
        // let queryParams = new URLSearchParams(requestParams).toString()
        // const funnelUrl = `${ENV_API_HOST}/api/getFunnelForConnections?${queryParams}`;
        // const funnelResponse = await fetch(funnelUrl, {
        //     headers: {
        //         Authorization: `Bearer ${token}`,
        //     }
        // });
        // const funnelData = await funnelResponse.json();
        // console.log("funnelData = ", funnelData);
        //
        // setFunnelData(funnelData);
    }

    useEffect(() => {
        setIsGlobalLoading(true);
        let params = {};
        for(let [key, value] of searchParams.entries()) {
            params[key] = value;
        }
        console.log("[ContactList.js] useEffect; searchParams = ", searchParams, params);

        let localLimit = limit;
        if (searchParams.has('limit')) {
            localLimit = parseInt(searchParams.get("limit"));
            setLimit(localLimit);
            delete params["limit"]
        }

        let localOffset = offset;
        if (searchParams.has('offset')) {
            localOffset = parseInt(searchParams.get("offset"));
            setOffset(localOffset);
            delete params["offset"]
        }


        setPersonListRequestParams(params);

        if (Object.keys(params).length > 0) {
            setIsFilterExpand(true);
        }

        getTeamData();
        getLabelList().then(() => {
            getFunnel(params);
            getPersonList(localLimit, localOffset, params);
            setIsGlobalLoading(false);
        });
    }, [needUpdate]);

    let isInviteAction = checkIsNeedInviteActionsFirst();
    if (isInviteAction != null) {
        return isInviteAction
    }

    const updateContactList = () => {
        setNeedUpdate(Math.random());
    }

    const contentActivityInfo = (
        <div>
            <div>The value in this cell could be similar to: </div>
            <div><FontAwesomeIcon icon={faCircleArrowDown} /> 5 / <FontAwesomeIcon icon={faCircleArrowUp} /> 10  <FontAwesomeIcon icon={faBarsStaggered}/> 3</div>

            <div style={{marginTop: "10px", marginBottom: "5px"}}>
                Here's what it means:
            </div>
            <div>
                <FontAwesomeIcon icon={faCircleArrowDown} /> - the total amount of messages received (including Emails, Linkedin messages, Phone calls, etc.).
            </div>

            <div style={{marginTop : "5px"}}>
                <FontAwesomeIcon icon={faCircleArrowUp} /> - the total amount of messages sent (including Emails, Linkedin messages, Phone calls, etc.).
            </div>
            <div style={{marginTop : "5px"}}>
                <FontAwesomeIcon icon={faBarsStaggered} /> - the amount of unanswered messages. <br /> For instance, in this example, it indicates that we sent 3 messages but haven't received any response yet.
            </div>

        </div>
    );

    const contentChannelsInfo = (<div>
        <div>The value in this cell could be similar to: </div>
        <div style={{marginTop: "5px"}}><Tag icon={<CheckCircleOutlined/>} color="success">
            <FontAwesomeIcon icon={faLinkedinIn}/>
        </Tag><Tag icon={<CheckCircleOutlined/>} color="success">
            <FontAwesomeIcon icon={faEnvelope}/>
        </Tag></div>

        <div style={{marginTop: "10px"}}>
            This represents your connection status with prospects across various channels.
        </div>

        <div style={{marginTop: "5px"}}>
            It could mean:
        </div>

        <div style={{marginTop: "5px"}}>
            <Tag icon={<MinusCircleOutlined/>} color="default">
                <FontAwesomeIcon icon={faLinkedinIn}/>
            </Tag> - Not established, which means that you haven't communicated through this channel yet.
        </div>

        <div style={{marginTop: "5px"}}>
            <Tag icon={<ClockCircleOutlined/>} color="red">
                <FontAwesomeIcon icon={faLinkedinIn}/>
            </Tag> - Communicated, but no response from the prospect yet.
        </div>

        <div style={{marginTop: "5px"}}>
            <Tag icon={<CheckCircleOutlined/>} color="success">
                <FontAwesomeIcon icon={faLinkedinIn}/>
            </Tag> -  Communication through this channel has been established.
        </div>
    </div>);


    const contentTasksInfo = (<div>
        <div>The value in this cell could be similar to: </div>
        <div style={{marginTop: "5px"}}><Tag color="red" icon={<FontAwesomeIcon icon={faFlag} />}> 1</Tag><Tag color="orange" icon={<FontAwesomeIcon icon={faClock} />}> 2</Tag><Tag icon={<FontAwesomeIcon icon={faSquare} />}> 4</Tag></div>
        <div style={{marginTop: "15px"}}>
            Here's what it means:
        </div>
        <div style={{marginTop: "5px"}}>
            <Tag color="red" icon={<FontAwesomeIcon icon={faFlag} />}> 1</Tag> - the number of tasks with missed deadlines.
        </div>
        <div style={{marginTop: "5px"}}>
            <Tag color="orange" icon={<FontAwesomeIcon icon={faClock} />}> 2</Tag> - the number of tasks with deadlines expiring soon (less than 24 hours).
        </div>
        <div style={{marginTop: "5px"}}>
            <Tag icon={<FontAwesomeIcon icon={faSquare} />}> 4</Tag> - the number of all active tasks.
        </div>

        <div style={{marginTop: "15px"}}>
            When you close all tasks, we show:
        </div>

        <div style={{marginTop: "5px"}}>
            <Tag color="green" icon={<FontAwesomeIcon icon={faCheck} />}> 4</Tag> - the number of all completed tasks.
        </div>

    </div>)


    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (person) => <PersonDataWidget person={person} />,
            // width: "13%"
        },
        {
            title: <span><Popover content={contentChannelsInfo} title="About Channels">Channels <InfoCircleOutlined /></Popover></span>,
            key: 'conn_state',
            render: (person) => <ConnectionStatusWidget person={person} />,
        },
        {
            title: 'Present companies',
            render: (person, _, index) => <CompanyListWidget key={index} person={person} />,
            width: "10%"
        },
        {
            title: <span><Popover content={contentActivityInfo} title="About Activities">Activities <InfoCircleOutlined /></Popover></span>,
            render: (person, _, index) => <LinkedinChatWidget key={index} person={person} />,

        },
        {
            title: <Popover content={contentTasksInfo} title="About Tasks">Tasks <InfoCircleOutlined /></Popover>,
            render: (person, _, index) => <TaskListWidgetForList key={index} person={person} />,
            // width: "13%"
        },
        {
            title: 'Labels',
            render: (person, _, index) => <span>
                <ContactListTagManagement key={index} person={person["profile"]} mapData={labelMap}/>
            </span>,
        },
        {
            title: 'Assignees',
            render: (person, _, index) => <div>
                {/*{JSON.stringify(person["profile"]["assignee_id_list"])}*/}
              <OwnerWidgetForList key={index} person={person} memberMap={memberMap} />
            </div>,
        },
        {
            title: 'Creation date',
            render: (person) => <div>
                {moment.unix(person["connection_date"]).format("DD MMM, YY (ddd)")}
            </div>,
        },
        {
            // title: 'Actions',
            render: (_, record, index) => (
                <Space size="middle">
                    <ArhiveWidget key={index} person={record} onUpdate={updateContactList} />
                </Space>
            ),
            width: "2%"
        },
    ]

    const handleTableChange = (pagination, filters, sorter) => {
        setLimit(pagination.pageSize);

        let currentPage = pagination.current - 1;
        console.log("pagination =", pagination, "; filters = ", filters, "; currentPage= ", currentPage);

        setOffset(currentPage * pagination.pageSize);
        setCurrentPage(pagination.current);
        getPersonList(pagination.pageSize, (currentPage * pagination.pageSize), personListRequestParams);
    }

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    const handleFilterUpdate = (filterObj) => {
        setPersonListRequestParams(filterObj);
        setOffset(0);
        getFunnel(filterObj);
        getPersonList(limit, 0, filterObj);
    }


    if (isGlobalLoading) {
        return (<div>Loading ...</div>);
    }

    const handleCreateNewProfile = () => {
        getPersonList(limit, 0, personListRequestParams);
    }


    return (
        <div style={{marginBottom: "50px"}}>
            {isFilterExpand ? <FilterWidget searchData={personListRequestParams} onUpdate={handleFilterUpdate}  /> : null}
            <Row style={{marginTop: "10px"}} >
                <Col span={24}>
                    <Content
                        style={{
                            padding: 12,
                            margin: 0,
                            minHeight: 100,
                            background: "white",
                        }}
                    >
                        <Row style={{marginBottom: "10px"}}>
                            <Col span={14}>
                                <Button onClick={filterVisiblityChange}>{!isFilterExpand ? "Expand Filter Options" : "Collapse Filter Options" }</Button>
                            </Col>
                            <Col span={10} style={{textAlign: "right"}}>
                                <NewScratchProfile onUpdate={handleCreateNewProfile} />
                            </Col>
                        </Row>
                        <Table
                            columns={columns}
                            dataSource={personList}
                            pagination={{pageSize: limit, total: total, current: currentPage}}
                            loading={isLoadingMemberMap || tableLoading}
                            onChange={handleTableChange}
                        />
                    </Content>
                </Col>
            </Row>

        </div>
    );
}


export default ContactList;

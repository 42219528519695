import {useGlobalState} from "./utils";
import {Button, Form, Input, Modal, Select} from "antd";
import React, {useEffect, useState} from "react";
import {ENV_API_HOST, RULE_LIST} from "./config";


function EditRule(props) {
    const [token] = useGlobalState('token');
    const [form] = Form.useForm();
    const [formFields, setFormFields] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let localFieldList = [];
        let keyList = Object.keys(props.fields);

        for (let i = 0; i < keyList.length; i++) {
            localFieldList.push({
                name: keyList[i],
                value: props.fields[keyList[i]]
            });
        }

        setFormFields(localFieldList);
    }, [props?.fields]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const editRow = async (values) => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/putCommunicationRule`;

        let formValue = values
        formValue.id = props.fields["id"];

        const resqt = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formValue)
        });
        await resqt.json();
        form.resetFields();
        setIsLoading(false);
        handleCancel();
        props.onUpdate();
    }

    const handleOk = async () => {
        // form.submit();

        form.validateFields()
            .then((values) => {
                console.log("value = ", values);
                editRow(values);
            })
            .catch((errorInfo) => {
                console.log("errorInfo = ", errorInfo);
            });

        // setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (<div>
        <a  key="list-loadmore-edit" onClick={showModal}>edit</a>

        <Modal
            title="Edit a communication rule"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Return
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
                    Submit
                </Button>
            ]}
        >
            <Form
                form={form}
                fields={formFields}
                name="basic"
                layout={"vertical"}
                labelCol={{span: 8}}
                wrapperCol={{span: 24}}
                style={{width: "100%"}}
                initialValues={{remember: true}}
                autoComplete="off"
            >
                <Form.Item
                    label="Rule:"
                    name="rule"
                    rules={[{required: true, message: 'Please choose rule'}]}
                >
                    <Select
                        style={{width: "100%"}}
                        options={RULE_LIST}
                    />
                </Form.Item>

                <Form.Item
                    label="Phrase:"
                    name="phrase"
                    rules={[{required: true, message: 'Please write phase that apply rule'}]}
                >
                    <Input/>
                </Form.Item>

            </Form>
        </Modal>
    </div>)
}

export default EditRule;

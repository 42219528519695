import {
    Avatar,
    Badge,
    Button,
    Card,
    Col, Empty,
    Input,
    Layout, Pagination,
    Row,
    Segmented,
    Select,
    Space,
    Spin,
    Tag,
    Typography
} from "antd";
import ProfileConnectedAccountWidget from "./widgets/ProfileConnectedAccountWidget";
import {ENV_API_HOST} from "./config";
import {useGlobalState} from "./utils";
import {useEffect, useState} from "react";
import EmailAddressListForClientWidget from "./widgets/EmailAddressListForClientWidget";
import {Link} from "react-router-dom";
import DEPRICATED_EmailAddAsConnectionWidget from "./widgets/DEPRICATED_EmailAddAsConnectionWidget";
import EmailListFilterWidget from "./widgets/EmailListFilterWidget";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import EmailCardForListWidget from "./widgets/EmailCardForListWidget";


const {Header, Content, Sider} = Layout;
const {Paragraph} = Typography;

const EmailTypeMap = {
    "regular": "Regular",
    "promo": "Updates",
    "all": "All mail"
}

const EmailByStateMap = {
    "unrecognized": 'Unrecognized emails',
    "in_process": "In process recognizing",
    "done": "Recognized emails",
    "all": "All mail"
}

const EmailCategoryMap = {
    "INBOX": "Inbox",
    "SENT": "Sent"
}


function EmailPage() {
    const [token] = useGlobalState('token');
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0)
    const [isLoading, setIsLoading] = useState(true);
    const [emailListData, setEmailListData] = useState([]);
    const [nameFilter, setNameFilter] = useState(null);
    const [selectedMailType, setMailType] = useState('regular');
    const [selectedMailCategory, setMailCategory] = useState('INBOX');
    const [selectedMailStatus, setMailStatus] = useState(null);
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [isLoadFull, setIsLoadFull] = useState(false);
    const [isZeroInbox, setIsZeroInbox] = useState(false);

    // const [nameFilter, setNameFilter] = useState(null);


    const handleOpen = () => {
        setIsOpenFilter(!isOpenFilter);
    }

    const getEmailList = async (mailType, custNameFilter, mailCategory, custLimit, custOffset, isFilterBased) => {
        setIsZeroInbox(false);
        setIsLoading(true);
        if(isFilterBased) {
            setEmailListData([])
        }

        var paramsString = "";

        if (mailType != null) {
            paramsString = paramsString + `&mail_type=${mailType}`;
        } else {
            if (selectedMailType != undefined && selectedMailType != null) {
                paramsString = paramsString + `&mail_type=${selectedMailType}`;
            }
        }

        if (mailCategory != null) {
            paramsString = paramsString + `&mail_category=${mailCategory}`;
        } else {
            if (selectedMailCategory != undefined && selectedMailCategory != null) {
                paramsString = paramsString + `&mail_category=${selectedMailCategory}`;
            }
        }

        if (custNameFilter != null) {
            paramsString = paramsString + `&name=${custNameFilter}`;
        } else {
            if (nameFilter != undefined && nameFilter != null) {
                paramsString = paramsString + `&name=${nameFilter}`;
            }
        }

        let localLimit = limit;
        if (custLimit != null) {
            localLimit = custLimit;
        }

        let localOffset = offset;
        if (custOffset != null) {
            localOffset = custOffset;
        }


        const requestUrl = `${ENV_API_HOST}/api/getEmailList?limit=${localLimit}&offset=${localOffset}${paramsString}`;


        const metadataResponse = await fetch(requestUrl, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        const responseData = await metadataResponse.json();
        // console.log("responseData = ", responseData);
        if (Array.isArray(responseData)) {

            if (isFilterBased) {
                setEmailListData(structuredClone(responseData));
            } else {
                let mergedArray = [...emailListData, ...responseData];
                setEmailListData(mergedArray);
            }

            if (responseData.length == 0) {
                setIsLoadFull(true);
            } else {
                setIsLoadFull(false);
            }

            if(isFilterBased == false && responseData.length == 0 && localOffset == 0) {
                setIsZeroInbox(true);
            }

        } else {
            setIsLoadFull(true);
        }
        setIsLoadingMore(false);
        setIsLoading(false);
    }
    //
    // const emptyList = async () => {
    //     console.log("emptyList");
    //     await setEmailListData([]);
    // }

    const handleFilterUpdate = async (newValues) => {
        setMailCategory(newValues["mail_category"]);
        setMailType(newValues["mail_type"]);
        setMailStatus(newValues["mail_by_state"]);
        setNameFilter(newValues["nameFilter"]);
        setIsOpenFilter(false);
        setIsLoadFull(false);
        // await emptyList();
        console.log("handleFilterUpdate: new values = ", newValues)
        getEmailList(newValues["mail_type"], newValues["nameFilter"], newValues["mail_category"], null, null,true);
    }

    const handleUpdateFromEmail = () => {
        console.log("handleUpdateFromEmail");
        getEmailList(selectedMailType, nameFilter, selectedMailCategory, null, offset,true);
    }


    useEffect(() => {
        getEmailList(null, null, null,null, null, false);
    }, [])


    const handleLoadingMore = () => {
        let newOffset = offset + limit;
        setIsLoadingMore(true);
        getEmailList(null, null, null,null, newOffset, false);
        setOffset(newOffset);
    }



    let renderEmailList = [];
    for (let i = 0; i < emailListData.length; i++) {

        renderEmailList.push(<EmailCardForListWidget emailData={emailListData[i]} onUpdate={handleUpdateFromEmail} />)
    }

    const tagState = {
        padding: "3px 7px"
    }


    let filterCurrentValues = {
        "mail_category": selectedMailCategory,
        "mail_type": selectedMailType,
        "mail_by_state": selectedMailStatus,
        "nameFilter": nameFilter
    };

    return (<Content style={{padding: 48, margin: 0, minHeight: 100, background: "white"}}>


        <>
            <Row style={{marginBottom: "20px"}}>
                <Col span={24}>
                    <Space>
                        <Badge showZero>
                            <Button type="primary" onClick={handleOpen}>
                                Filter Options
                            </Button>
                        </Badge>
                        {selectedMailType && <Tag style={tagState}>Email type: {EmailTypeMap[selectedMailType]}</Tag>}
                        {selectedMailCategory && <Tag style={tagState}>Email folder: {EmailCategoryMap[selectedMailCategory]}</Tag>}
                        {selectedMailStatus &&
                            <Tag style={tagState}>Email address status: {EmailByStateMap[selectedMailStatus]}</Tag>}
                        {nameFilter && <Tag style={tagState}>Name or email contains: {nameFilter}</Tag>}
                    </Space>
                </Col>
            </Row>

            {isOpenFilter ? <div>
                <EmailListFilterWidget currentValues={filterCurrentValues} onUpdate={handleFilterUpdate}/>
            </div> : null}

            <Row>
                <Col span={24}>
                    {renderEmailList}
                </Col>
            </Row>

            {isLoading ? <Row>
                <Col span={24}>
                    <Content style={{
                        padding: 48, margin: 0, minHeight: 100, background: "white",
                    }}>
                        <div style={{textAlign: "center", marginTop: "30%"}}><Spin tip="Loading emails ..."
                                                                                   size="large">
                            <div className="content"/>
                        </Spin></div>
                    </Content>
                </Col>
            </Row> : null}


            {isZeroInbox ? <div><Empty description={<span>No data available. <br /><br /> <Link to={`/profile`}>Please sync your emails</Link> to create profiles based on email addresses and attach relevant emails to the profiles.</span>} /></div> : null}


            <Row style={{marginTop: "20px", display: "flex", justifyContent: "flex-end"}}>
                {/*<Pagination defaultCurrent={6} total={500} />*/}
                <Button disabled={isLoadFull} loading={isLoadingMore} block size={"large"}
                        onClick={handleLoadingMore}><FontAwesomeIcon icon={faUpload}/> &nbsp; Load more</Button>
            </Row>
        </>


    </Content>);

}

export default EmailPage;

import {Avatar, Button, Card, Col, Empty, Layout, Row, Spin, Switch, Typography} from "antd";
import ProfileConnectedAccountWidget from "./widgets/ProfileConnectedAccountWidget";
import React, {useEffect, useState} from "react";
import {ENV_API_HOST} from "./config";
import {useGlobalState} from "./utils";
import "./App.css";
import {Link} from "react-router-dom";
import NewSequence from "./widgets/newSequence";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const {Header, Content, Sider} = Layout;

//display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
const middleAlignStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
}

function SequencePage() {
    const [token] = useGlobalState('token');
    const [isLoading, setIsLoading] = useState(true);
    const [sequenceList, setSequenceList] = useState([]);
    const getSequenceList = async () => {
        setIsLoading(true);
        const requestUrl = `${ENV_API_HOST}/api/getSequenceList`;
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        setSequenceList(dataResponse);
        setIsLoading(false);
    }

    useEffect(() => {
        getSequenceList();
    }, [])


    let loadingRender = null;
    if (isLoading) {
        loadingRender = (<div style={{textAlign: "center", paddingTop: "100px", paddingBottom: "100px"}}>
            <Spin tip="Loading  ..." size="large">
                <div className="content"/>
            </Spin>
        </div>);
    }

    let sequenceListRender = [];
    for (let i = 0; i < sequenceList.length; i++) {
        sequenceListRender.push(<Card size={"small"} style={{marginBottom: "10px"}}>
            <Row style={{padding: "0px 5px"}}>
                <Col span={21}>
                    <div>
                       <Typography.Title style={{marginTop: "0px", marginBottom: "3px"}} level={5}> <Link to={`/sequence/${sequenceList[i]["id"]}/edit`}>{sequenceList[i]["name"]}</Link></Typography.Title>
                    </div>
                    <div>
                        <span><Avatar size={16} src={sequenceList[i]["createdBy"]["photo"]} /> {sequenceList[i]["createdBy"]["name"]}</span>
                        <span className={"sequenceStepAmount"}>{sequenceList[i]["stepsCount"]} steps</span>
                    </div>
                </Col>
                <Col span={2} style={middleAlignStyle}>
                    <Switch checked={sequenceList[i]["isActive"]} />
                </Col>
                <Col span={1} style={middleAlignStyle}>
                    <Button type="link" danger><FontAwesomeIcon icon={faTrash} /></Button>
                </Col>

            </Row>
        </Card>)
    }

    const handleNewSequence = () => {
      getSequenceList();
    }


    return (
        <Content style={{
            padding: "20px 40px", margin: 0, minHeight: 100, background: "white",
        }}>
            <Row>
                <Col span={20}></Col>
                <Col span={4} style={{textAlign: "right"}}><NewSequence onUpdate={handleNewSequence} /></Col>
            </Row>

            {loadingRender}

            <div style={{marginTop: "20px"}}>
                {sequenceListRender}

                {sequenceListRender.length == 0 ? <Empty /> : null }
            </div>
        </Content>
    );
}

export default SequencePage;

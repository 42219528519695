import React, {Fragment, useEffect, useState} from "react";
import {
    Button,
    Col, Input,
    Layout, Row, Radio,
    List, Spin, Result
} from 'antd';
import {useSearchParams } from "react-router-dom";
import {useGlobalState} from "./utils";
import {ENV_API_HOST} from "./config";



const {Content} = Layout;


function ClaimInvitePage(props) {
    const [token] = useGlobalState('token');
    const inviteTokenFromLocalStorage = localStorage.getItem("inviteToken")
    const [searchParams, setSearchParams] = useSearchParams();
    const inviteToken = searchParams.get("token");


    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        claimInviteRequest()
    }, [])


    const claimInviteRequest = async () => {
        setIsError(false);
        setIsLoading(true);

        const postData = {
            user: props.user,
            token: inviteTokenFromLocalStorage
        }

        console.log("claimInviteRequest = ", claimInviteRequest);

        const requestUrl = `${ENV_API_HOST}/api/postSignupByInvite`;
        const metadataResponse = await fetch(requestUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData)
        });

        console.log("[ClaimInvitePage.js] metadataResponse = ", metadataResponse, metadataResponse.status);

        if (metadataResponse.status == 200) {
            localStorage.removeItem("inviteToken");
            window.location.assign("/");
        } else {
            setIsError(true);
            setIsLoading(false);
        }
    }


    console.log("GetInvitePage = ", inviteToken);
    return (
        <div>
            <Content style={{padding: '24px 24px', background: "white"}}>

                <Row>
                    <Col span={24}>
                        {isLoading ? <div style={{paddingTop: "180px", paddingBottom: "180px"}}>
                            <Spin tip="Please wait while the signup process completing" size="large">
                                <div className="content" />
                            </Spin>
                        </div>: isError ? <Result
                            status="warning"
                            title={`There are some problems with your operation. Refresh the page.`}
                        /> : null}
                    </Col>
                </Row>


            </Content>

        </div>
    );
}

export default ClaimInvitePage;
